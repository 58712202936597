import { combineReducers } from 'redux';
import {ALL_BLOG, CLEAR_ALL} from './blogTypes';

const allBlogReducer= (state= [], action)=>{
    switch (action.type) {
        case ALL_BLOG: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}

const courseReducer= combineReducers({
    allBlog: allBlogReducer
});

export default courseReducer;