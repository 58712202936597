import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import Typed from 'typed.js';
import {Helmet} from "react-helmet";

import './AboutUs.css';
let about1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/about1.svg'
let about2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/about2.svg'
let about3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/about3.svg'
let about4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/about4.svg'
let about5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/about5.svg'
let leftStepIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/leftStep.svg'
let rightStepIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/about/rightStep.svg'

const specializedInContent= {
  "Financial Modeling": ["Illuminating the Path to Financial Modeling Excellence!", "Finocontrol is a trusted provider of specialized finance modeling services, renowned for our technical excellence and attention to detail. Our team of highly skilled experts possesses deep quantitative expertise and extensive industry knowledge, allowing us to develop sophisticated financial models that accurately capture complex financial relationships and provide valuable insights. Utilizing advanced software and data analysis techniques, we construct robust models that project future scenarios, assess risk, and support critical decision-making processes. Our commitment to precision, adherence to industry best practices, and utilization of comprehensive sensitivity analysis and scenario testing ensure the reliability and flexibility of our models."],
  "Investment Banking": ["Navigating Your Path to Success, One Investment at a Time!", "Finocontrol is a leading provider of investment banking services, specializing in delivering tailored strategies and innovative solutions to meet the unique financial needs of its clients. Our unique selling proposition (USP) lies in our deep industry expertise, extensive networks, and unshakable commitment to delivering exceptional value. We differentiate ourselves by offering comprehensive services, including capital raising, M&A, corporate restructuring, valuation, advisory, debt, and equity financing, and industry research. With a client-centric approach and a track record of success, Finocontrol is the trusted partner for businesses seeking expert guidance and customized solutions to drive growth and maximize returns in the dynamic world of investment banking."],
  "Business Valuation": ["Unveiling the true worth of businesses!", "Finocontrol is a trusted provider of specialized business valuation services, employing rigorous methodologies and industry-leading techniques. Our team of valuation experts utilizes approaches such as the income approach, market approach, and asset-based approach to assess the fair market value of your business. We conduct thorough financial analysis, including discounted cash flow (DCF) analysis, comparable company analysis, and consideration of intangible assets, to provide a comprehensive understanding of your company's intrinsic worth. Additionally, we consider qualitative factors such as industry trends, competitive positioning, and management expertise to ensure a holistic assessment. Our adherence to relevant valuation standards and regulatory guidelines, such as the International Valuation Standards (IVS) and the Uniform Standards of Professional Appraisal Practice (USPAP), ensures the accuracy, objectivity, and reliability of our valuation reports."],
  "Training & Career Development" : ["Shifting the training gears to developing your career!", "We offer comprehensive and industry-leading financial training and development Programmes. Our expert trainers bring real-world experience to cover a wide range of topics, including financial analysis, investment strategies, risk management, and financial modeling. Through practical learning approaches, including case studies and simulations, participants gain valuable skills applicable to their roles. In addition to our comprehensive financial training Programmes, we offer personalized development initiatives that focus on enhancing critical skills such as leadership, communication, problem-solving, and decision-making. Through targeted coaching, mentorship opportunities, and career guidance, we help individuals unlock their full potential and advance in their careers."],
  "Stock Market": ["Helping you to trade wisely and staying cool in the stock pool!", "We provide unparalleled expertise and insights into the dynamic world of stock trading. With years of experience and a deep understanding of market trends and economic conditions, our team of experts is dedicated to helping individuals and organizations make informed and profitable investment decisions. Whether you are a seasoned trader or just starting out, we offer personalized guidance and comprehensive analysis to help you navigate the complexities of the stock market. With Finocontrol on your side, you can trade with confidence and achieve your financial goals."]
}

function AboutUs() {
  const el= useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Big', 'Future', 'Finocontrol'],
      typeSpeed: 150,
      backSpeed: 150,
      backDelay: 1000,
      showCursor: false,
      loop: true
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  const [specializedState, setSpecializedState] =useState("Financial Modeling")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <link rel="canonical" href="https://finocontrol.com/about" />
        <meta name="description" content="Know About Us" />
      </Helmet>
      <div className='about_us_bg'>
        <div className='tw-mt-16 tw-py-20 tw-w-full tw-h-full tw-text-white'>
          <div className="tw-px-8 tw-flex tw-justify-center tw-items-center tw-flex-col">
            <p className='tw-text-2xl md:tw-text-4xl lg:tw-text-6xl tw-mb-3 tw-font-semibold tw-text-[#fef63c]' >Think <span ref={el}></span></p>
            <p className='tw-text-sm md:tw-text-base lg:tw-text-lg tw-mb-3'>Allow us to create a brand in your career.</p>
            <p className='tw-text-sm md:tw-text-base lg:tw-text-lg tw-mb-3'>Allow us to create an impact in your career.</p>
            <p className='tw-text-xs lg:tw-text-sm tw-mb-5' style={{textAlign:'center'}}>(Registered & Recognised Under Ministry of Corporate Affairs, India)</p>
            <Link to="/signup" ><button className='tw-text-sm md:tw-text-base lg:tw-text-lg tw-btn tw-px-24 tw-btn-lg tw-rounded-full tw-border-[#C12CB4] hover:tw-bg-[white] hover:tw-text-black tw-transition-colors tw-bg-[#C12CB4]  tw-capitalize'>Join Us</button></Link>
          </div>
        </div>
      </div>

      <div className='container tw-my-16'>
          <div className='tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' >
            A Little About Us
          </div>
          <div className="tw-my-3 row">
            <span className='col tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2'>
              Welcome to Finocontrol Consultancy Services LLP, a premier provider of educational, advisory, consulting, and training services. We are proud to have a legacy of excellence and have been ranked among the Top 10 best Financial Modeling companies in the world.
              Our team of highly skilled professionals has extensive experience in financial modeling and analysis, and we are dedicated to helping our clients achieve their financial objectives. We offer a wide range of Services, including financial modeling and analysis, business valuation, investment banking, financial due diligence, and Training Programmes.
              But we're not just about finance – we're also passionate about finding the right talent for our clients. So if you're looking for a finance partner who can help you manage your financial structure, find the right talent, and achieve your financial goals, you've come to the right place. We work closely with our clients to understand their unique needs and challenges and develop customized solutions to meet those needs.
            </span>
          </div>
        </div>

      <div className='specialized_in_section'>
        <div className='container '>
          <div className='tw-py-12 tw-w-full tw-h-full tw-text-white'>
            <div className='tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' >
              We Specialize In
            </div>
            <div className='row tw-my-6'>
              <div className='col'>
                <p className={`tw-cursor-pointer tw-text-[10px] lg:tw-text-base tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ${specializedState==="Financial Modeling"?"tw-opacity-100 tw-font-semibold":"tw-opacity-80"} `} onClick={()=>setSpecializedState("Financial Modeling")}>Financial Modeling</p>
              </div>
              <div className='col'>
                <p className={`tw-cursor-pointer tw-text-[10px] lg:tw-text-base tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ${specializedState==="Investment Banking"?"tw-opacity-100 tw-font-semibold":"tw-opacity-80"}`} onClick={()=>setSpecializedState("Investment Banking")}>Investment Banking</p>
              </div>
              <div className='col'>
                <p className={`tw-cursor-pointer tw-text-[10px] lg:tw-text-base tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ${specializedState==="Business Valuation"?"tw-opacity-100 tw-font-semibold":"tw-opacity-80"}`} onClick={()=>setSpecializedState("Business Valuation")}>Business Valuation</p>
              </div>
              <div className='col'>
                <p className={`tw-cursor-pointer tw-text-[10px] lg:tw-text-base tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ${specializedState==="Training & Career Development"?"tw-opacity-100 tw-font-semibold":"tw-opacity-80"}`} onClick={()=>setSpecializedState("Training & Career Development")}>Training & Career <br /> Development</p>
              </div>
              <div className='col'>
                <p className={`tw-cursor-pointer tw-text-[10px] lg:tw-text-base tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full ${specializedState==="Stock Market"?"tw-opacity-100 tw-font-semibold":"tw-opacity-80"}`} onClick={()=>setSpecializedState("Stock Market")}>Stock Market</p>
              </div>
            </div>
            <div className='tw-my-6 tw-flex tw-justify-center tw-items-center'>
              <p className='tw-text-base md:tw-text-lg lg:tw-text-xl tw-mb-3 tw-font-semibold tw-text-[#fef63c]'>
                {
                  specializedInContent[specializedState][0]
                }
              </p>
            </div>
            <div className='tw-my-6 tw-flex tw-justify-center tw-items-center'>
              <p className='tw-text-sm lg:tw-text-base tw-my-2'>
                {
                  specializedInContent[specializedState][1]
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='tw-my-10 container'>
        <div className='container'>
          <div className="">
            <div className='tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' >
              Our Story
            </div>
            <span className='tw-my-6 tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium text_color tw-mt-16'>2020</span>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-mr-32 -tw-mt-10'><img src={leftStepIcon} alt="left step" className='tw-w-[4rem]' /></span>
            <div className='row -tw-mt-6'>
              <div className='col-lg-4 col-12'>
                <img className='img-fluid tw-flex tw-justify-center' src={about1} alt="about1" />
              </div>
              <div className='col-lg-8 col-12 tw-flex tw-justify-center tw-items-center'>
                <span className='fino_box tw-p-3 lg:tw-px-12 tw-min-w-[80%]'>
                  <span className='tw-font-medium'>Founded with a vision to make Indian youth</span> <span className='tw-font-medium fino_box_text'>financially literate and tech-informed by 2030.</span>
                  <br/>
                  Initially, established as a <span className='tw-font-medium fino_box_text'>one-on-one consultation platform</span> complemented by a dedicated focus on producing informative articles.
                  <br/>
                  Reached monthly <span className='tw-font-medium fino_box_text'>readership of 20,000+</span> and gained over <span className='tw-font-medium fino_box_text'>1,000 paid subscribers in Q1.</span>
                </span>
              </div>
            </div>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-mr-32 -tw-mt-10'><img src={rightStepIcon} alt="left step" className='tw-w-[4rem]' /></span>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-ml-32 -tw-mt-8'><img src={rightStepIcon} alt="left step" className='tw-w-[4rem]' /></span>

            <div className='row -tw-mt-6 tw-flex tw-flex-col-reverse lg:tw-flex-row'>
              <div className='col-lg-8 col-12 tw-flex tw-justify-center tw-items-center '>
                <span className='fino_box tw-px-3 lg:tw-px-12 tw-py-3 tw-flex-col tw-min-w-[100%] tw-font-medium'>
                  Recognizing senior management's acknowledgment
                  that only <span className='tw-font-medium fino_box_text'>0.01% of students</span> are well-suited for a finance career, 
                  we assembled a <span className='tw-font-medium fino_box_text'>highly distinguished team of finance professionals.</span>
                </span>
              </div>
              <div className='col-lg-4 col-12'>
                <img className='img-fluid tw-flex tw-justify-center' src={about2} alt="about1" />
              </div>
            </div>

            <span className='tw-hidden xl:tw-flex tw-justify-center tw-ml-32 -tw-mt-10'><img src={leftStepIcon} alt="left step" className='tw-w-[4rem]' /></span>

          </div>

          <div className="">
            <span className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium text_color tw-mt-16'>2021</span>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-mr-32 -tw-mt-5'><img src={leftStepIcon} alt="left step" className='tw-w-[4rem]' /></span>
            <div className='row -xl:tw-mt-6'>
              <div className='col-lg-4 col-12'>
                <img className='img-fluid tw-flex tw-justify-center' src={about3} alt="about1" />
              </div>
              <div className='col-lg-8 col-12 tw-flex tw-items-center'>
                <span className='fino_box tw-py-3 row tw-min-w-[100%]'>
                  <span className='tw-flex tw-justify-center tw-font-medium'>Launched our <span className='fino_box_text tw-ml-1'> 4 Flagship Programmes:</span></span>
                  <ul style={{ listStyleType: "disc" }} className='tw-pl-16' >
                    <li>Certified Investment Banking Professional Programme</li>
                    <li>Certified Financial Modeling and Business Valuation Trainee Programme</li>
                    <li>Certified Financial Reporting and Analyst Trainee Programme</li>
                    <li>Certified Equity Research and Valuation Analyst Trainee Programme</li>
                  </ul>
                </span>
              </div>
            </div>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-mr-32 -tw-mt-6'><img src={rightStepIcon} alt="left step" className='tw-w-[4rem]' /></span>

          </div>

          <div className="">
            <span className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium text_color tw-mt-16'>2022</span>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-ml-44 -tw-mt-6'><img src={rightStepIcon} alt="left step" className='tw-w-[4rem]' /></span>
            <div className='row tw-flex tw-flex-col-reverse lg:tw-flex-row'>
              <div className='col-lg-8 col-12 tw-flex tw-items-center'>
                <span className='fino_box tw-px-3 lg:tw-px-12 tw-py-3 tw-flex-col tw-min-w-[100%] tw-font-medium'>
                  Introduced B2B <span className='fino_box_text'>finance consulting services</span>, leveraging our advisory expertise to assist founders with finance structuring. 
                  <br/>
                  Our comprehensive services encompass <span className='fino_box_text'>financial analysis, cash flow management, and investment planning.</span>
                </span>
              </div>
              <div className='col-lg-4 col-12'>
                <img className='img-fluid tw-flex tw-justify-center' src={about4} alt="about1" />
              </div>
            </div>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-ml-44 -tw-mt-8'><img src={leftStepIcon} alt="left step" className='tw-w-[4rem]' /></span>
          </div>

          <div className="">
            <span className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium text_color tw-mt-16'>2023</span>
            <span className='tw-hidden xl:tw-flex tw-justify-center tw-mr-32 -tw-mt-6'><img src={leftStepIcon} alt="left step" className='tw-w-[4rem]' /></span>

            <div className='row'>
              <div className='col-lg-4 col-12'>
                <img className='img-fluid tw-flex tw-justify-center' src={about5} alt="about1" />
              </div>
              <div className='col-lg-8 col-12 tw-flex tw-items-center'>
                <div className='fino_box tw-px-3 lg:tw-px-12 tw-py-3 tw-min-w-[100%] tw-font-medium'>
                  Achieving <span className='fino_box_text'>100% year-on-year growth</span>, our bootstrapped company has attained a valuation of <span className="fino_box_text">4 crores</span> and established a robust network in just the first <span className="fino_box_text">2 years  </span> of business Operation.
                  <br/>
                  Now we are a community of <span className='fino_box_text'>10,000+ Alumni, 100+ Hiring partners, and 200+ Industrial Partners.</span>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
      <div className='about_us_bg_bottom'>
        <div className='container tw-h-full tw-py-32 tw-text-white'>
          <div className='row tw-flex lg:tw-justify-between tw-justify-center tw-items-center'>
            <div className='tw-w-fit col-12 col-lg-8 tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-medium'>
              Want to learn about Finance?
            </div>
            <div className='tw-w-fit tw-my-3 lg:tw-my-0 col-12 col-lg-4 '>
              <Link to="/course" ><button className='tw-text-sm md:tw-text-base lg:tw-text-lg tw-btn tw-px-24 tw-btn-lg tw-rounded-full tw-border-[#C12CB4] hover:tw-bg-[white] hover:tw-text-black tw-transition-colors tw-bg-[#C12CB4]  tw-capitalize'>Get Certified</button></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs