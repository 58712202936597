import React from 'react'
import './ProudOf.css'
let ico1= 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/online-learning.svg'
let ico2= 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/exam.svg'
let ico3= 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/review.svg'
let ico4= 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/online-class.svg'

function ProudOf() {
  return (
    <div className="proud_of_set_bg tw-pt-16 tw-pb-8">
        <div className="container">
            <div className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium ' style={{textAlign:'center'}}>We are Proud</div>
            <div className='text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>
                <div className="tw-flex tw-justify-center tw-max-w-xl">
                    Our Programmes are carefully curated after advanced deep research and developed by Leading Finance Leaders with over 10 plus years of experience, CFA charterholders, CA Rank holders, CFOs and NISM Examiners
                </div>
            </div>

            <div className='tw-mt-8 lg:tw-my-12'>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center">
                        <div className='tw-flex-col tw-group hover:tw-bg-white tw-transition-colors tw-w-[250px] tw-h-[175px] lg:tw-w-[280px] lg:tw-h-[196px] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                            <img loading="eager" alt="icon" src={ico1} />
                            <span className='tw-mt-5 group-hover:tw-text-[#6440FB] tw-font-medium'>10, 000+</span>
                            <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>Alumni worldwide</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center">
                        <div className='tw-flex-col tw-group hover:tw-bg-white tw-transition-colors tw-w-[250px] tw-h-[175px] lg:tw-w-[280px] lg:tw-h-[196px] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                            <img loading="eager" alt="icon" src={ico2} />
                            <span className='tw-mt-5 group-hover:tw-text-[#6440FB] tw-font-medium'>300+</span>
                            <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>Industrial Partners </span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center">
                        <div className='tw-flex-col tw-group hover:tw-bg-white tw-transition-colors tw-w-[250px] tw-h-[175px] lg:tw-w-[280px] lg:tw-h-[196px] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                            <img alt="icon" src={ico3} />
                            <span className='tw-mt-5 group-hover:tw-text-[#6440FB] tw-font-medium'>100+</span>
                            <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>Potential Recruiters</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center">
                        <div className='tw-flex-col tw-group hover:tw-bg-white tw-transition-colors tw-w-[250px] tw-h-[175px] lg:tw-w-[280px] lg:tw-h-[196px] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                            <img alt="icon" src={ico4} />
                            <span className='tw-mt-5 group-hover:tw-text-[#6440FB] tw-font-medium'>1,00,000+</span>
                            <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>Student & Professional Community</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProudOf