import React from 'react'
import ambujaPng from './assets/ambuja.png'
import daburPng from './assets/dabur.png'
import infosysPng from './assets/infosys.png'
import ltPng from './assets/l&t.png'
import swiggyPng from './assets/swiggy.png'
import tataPng from './assets/tata.png'


function CorporateProject({courseName, corporateLiveProjectContent}) {
  return (
    <div className='tw-bg-[#FCB900] tw-pt-2 tw-pb-10'>
        <div className='container'>
            <p className='tw-text-center tw-font-medium tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-my-5'>Corporate Live Projects You Undergo</p>
            <p>{corporateLiveProjectContent}</p>
            <div className='row -tw-mt-5 tw-flex tw-justify-center'>
              <div className="col-12 col-md-4 tw-flex tw-justify-center tw-mt-10 tw-scale-90"><img src={ambujaPng} alt="ambuja" width={140}/></div>
              <div className="col-12 col-md-4 tw-flex tw-justify-center tw-mt-10 tw-scale-90"><img src={swiggyPng} alt="swiggy" width={140}/></div>
              <div className="col-12 col-md-4 tw-flex tw-justify-center tw-mt-10 tw-scale-90"><img src={tataPng} alt="tata" width={140}/></div>
              <div className="col-12 col-md-4 tw-flex tw-justify-center tw-my-2 md:tw-scale-y-[0.4] md:tw-scale-x-[0.8] lg:tw-scale-y-[0.4] lg:tw-scale-x-50 tw-scale-x-[0.6] tw-scale-y-[0.55]"><img src={ltPng} alt="l&t"/></div>
              {!courseName==="Certified Investment Banking Professional Programme" ?<div className="col-12 col-md-4 tw-flex tw-justify-center tw-my-1 md:tw-my-3 tw-scale-90"><img src={daburPng} alt="dabur" width={140}/></div>:""}
              <div className="col-12 col-md-4 tw-flex tw-justify-center tw-my-2 md:tw-scale-y-[0.4] md:tw-scale-x-[0.8] lg:tw-scale-y-[0.4] lg:tw-scale-x-50 tw-scale-x-[0.6] tw-scale-y-[0.55]"><img src={infosysPng} alt="infosys" height={20}/></div>
            </div>
        </div>
    </div>
  )
}

export default CorporateProject