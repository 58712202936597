import React, { useState } from 'react'
import {useFormik} from 'formik'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import './ForgotPassword.css'
import Toast from '../../helpersComponents/Toast.js'
import {forgotPasswordSchema} from '../../formikSchema/schema';
import serverAPI from '../../utils/baseAPI';

function ForgotPassword({mainEmail=""}) {
  const [isEmailSent, setIsEmailSent]= useState('');
  const [toast, setToast]= useState({isSuccess:'', message:''});

  const forgotPasswordForm = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (value, action)=>{
      sendPasswordRecoveryMail(value);
    }
  });
  function sendPasswordRecoveryMail({email}){
    if(!isEmailSent) {
      serverAPI.post(`/api/forgot-password/send-verification-code`, {
        email
      }).then(e=>{
        setIsEmailSent(true);
        setToast({isSuccess:true, message:e.data.msg});

        setTimeout(() => {
          setIsEmailSent('');
        }, 120*1000); //120 minute wait
      }).catch(e=>{
        setToast({isSuccess:false, message: e.response.data.msg});

        setIsEmailSent(false);
        setTimeout(() => {
          setIsEmailSent('');
        }, 4000);
      }).finally(()=>{
        setTimeout(() => {
          setToast({isSuccess: '', message:''});
        }, 4000);
      })
    } else {
      setToast({isSuccess: true, message: "email already sent please wait for 2minute to send verification code again"})
      //console.log('email already sent please wait for 2minute to send verification code again');
      //call your backend api here;
    }
  }
  
  return (
    <div className=' tw-mt-16 '>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forget Password</title>
        <meta name="description" content="Forget password, no problem we are here to help you out" />
      </Helmet>
      {(()=>{
        if(toast.isSuccess==='') return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message} />
      })()}
      <div className="row tw-min-h-[93vh]">
        {/* <div className="tw-h-auto col-md-5 bg_color tw-hidden md:tw-flex tw-justify-center tw-items-center">
          <img src={leftImage} alt="left-img" className='img-fluid' />
        </div> */}
        <div className="col tw-flex tw-justify-center tw-items-center">
          <div className="tw-my-5 tw-w-[90%] lg:tw-w-[450px] tw-rounded-2xl tw-bg-white">
            <div className="tw-py-10 tw-px-10 drop_shadow tw-rounded-lg ">
              <span className='tw-text-3xl tw-font-bold'>
                <span>Reset Password</span>
              </span>
              <div className='tw-label-text tw-mt-5'>
                <span><span className='tw-opacity-60'>Already have an account.</span> <Link to='/login'><span className='tw-text-[#6440FB] tw-cursor-pointer hover:tw-underline'> Sign in here</span></Link> </span>
              </div>
              <form onSubmit={forgotPasswordForm.handleSubmit} className='tw-flex tw-justify-center tw-flex-col' >
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500'>*</span></label>
                    {forgotPasswordForm.touched.email && forgotPasswordForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{forgotPasswordForm.errors.email}</p> : <></>}
                  </span>
                  <input value={forgotPasswordForm.values.email} onChange={forgotPasswordForm.handleChange} name="email" required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                </div>
                <div className="tw-pt-4">
                  <button disabled={isEmailSent?true:false} type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
                    <span>Send password reset email</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword