import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'

import barIcon from './assets/bar-chart.svg';
import clockIcon from './assets/wall-clock.svg';
import docIcon from './assets/document.svg';

import './Courses.css'

import { downToUpVariant } from '../../animationVariants/variants'
import { getActiveCourse, getNotActiveCourse } from '../../../redux/course/courseAction'

function Courses() {
  const [course, setCourse] = useState('ongoing');
  const dispatch = useDispatch();

  const ongoingCourse = useSelector(state => state.course.active.data) || [];
  const upcomingCourse = useSelector(state => state.course.notActive.data) || [];

  useEffect(() => {
    dispatch(getActiveCourse());
    dispatch(getNotActiveCourse());
  }, []);

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Explore our Flagship Corporate Express pathway</div>
          <div className='tw-flex tw-justify-between tw-flex-wrap'>
            <div className='tw-text-xs lg:tw-text-sm tw-opacity-60 '>Exclusive specialized, Futuristic & Industry ready Programmes designed for Students & Working professionals</div>
          </div>
        </div>
        <div className="col-12 col-lg-4 tw-tabs lg:tw-justify-end tw-py-2">
          <span className={`tw-tab tw-tab-lifted ${(course === "ongoing") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse("ongoing")}>Ongoing</span>
          <span className={`tw-tab tw-tab-lifted ${(course === "upcoming") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse('upcoming')}>Upcoming</span>
        </div>
      </div>

      {/* ongoing content */}
      <div className='tw-py-5 row tw-flex tw-justify-center'>
        {course === "ongoing" && ongoingCourse.map(item => {
          return <motion.div key={item?._id} variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-w-[20rem] lg:tw-w-auto tw-m-auto tw-p-2 col-12 col-md-6 tw-max-w-[25rem] md:tw-max-w-none'>
            <div className="tw-card lg:tw-card-side tw-bg-base-100 tw-shadow-md tw-p-1">
              <figure><div className={`tw-rounded-xl`} style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', width: '300px', minHeight: '200px' }} >  </div></figure>
              <div className="tw-py-8 tw-px-3 lg:tw-px-8 lg:tw-py-3 tw-gap-2 tw-flex tw-flex-col">
                <div className="tw-flex tw-rating tw-rating-xs tw-items-center">
                  <div className='tw-text-xs lg:tw-text-sm '> {item.rating} &nbsp;</div>
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" defaultChecked />
                </div>
                <h2 className=" tw-text-md ">{item.shortName}</h2>

                <div className='tw-flex tw-justify-between'>
                  <span className='tw-flex tw-items-center'> <img loading="eager" src={docIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>{item?.lessonCount || "12+"} Lessons</p></span>
                  <span className='tw-mx-5 tw-flex tw-items-center'> <img loading="eager" src={clockIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>{item?.duration}</p></span>
                  <span className='tw-flex tw-items-center'> <img loading="eager" src={barIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>Beginner to Advanced</p></span>
                </div>

                <div className='tw-divider tw-m-2' />

                <div className="tw-card-actions tw-justify-between tw-items-center tw-mt-3 ">
                  <div>
                    <del className='tw-opacity-40'>&#x20B9; {item.actualPrice}/-</del>
                    &nbsp;<span>&#x20B9; {item.offerPrice}/-</span>
                  </div>
                  <div>
                    <Link to={`/course/${item?.courseLinkName || item._id}`}><button className='tw-btn tw-btn-primary tw-btn-sm tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-8'>Join</button></Link>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        })}

        {course === "upcoming" && upcomingCourse.length > 0 && upcomingCourse.map(item => {
          return <motion.div key={item?._id} variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-flex tw-justify-center tw-items-center tw-w-[100%] lg:tw-w-[50%] tw-my-3'>
            <div className="tw-card lg:tw-card-side tw-bg-base-100 tw-shadow-md tw-p-1">
              <figure><div className={`tw-rounded-xl`} style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', width: '300px', minHeight: '200px' }} >  </div></figure>
              <div className="tw-py-8 tw-px-3 lg:tw-px-8 lg:tw-py-3 tw-gap-2 tw-flex tw-flex-col">
                <div className="tw-flex tw-rating tw-rating-xs tw-items-center">
                  <div className='tw-text-xs lg:tw-text-sm '> {item.rating} &nbsp;</div>
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
                  <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" defaultChecked />
                </div>
                <h2 className=" tw-text-md ">{item.shortName}</h2>

                <div className='tw-flex tw-justify-between'>
                  <span className='tw-flex tw-items-center'> <img loading="eager" src={docIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>{item?.lessonCount || "12+"} lesson</p></span>
                  <span className='tw-mx-5 tw-flex tw-items-center'> <img loading="eager" src={clockIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>{item?.duration}</p></span>
                  <span className='tw-flex tw-items-center'> <img loading="eager" src={barIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>Beginner to Advanced</p></span>
                </div>

                <div className='tw-divider tw-m-2' />

                <div className="tw-card-actions tw-justify-between tw-items-center tw-mt-3 ">
                  <div>
                    <del className='tw-opacity-40'>&#x20B9; {item.actualPrice}/-</del>
                    &nbsp;<span>&#x20B9; {item.offerPrice}/-</span>
                  </div>
                  <div>
                    { 
                      item?.hasData && <Link to={`/course/${item._id}`}><button className='tw-btn tw-btn-primary tw-btn-sm tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-8'>Explore</button></Link>
                    }
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        })}
      </div>

    </div>
  )
}

export default Courses