import React from 'react'
import './CertificationJourney.css'

let ico1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/rating 1.svg'
let ico2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/science 1.svg'
let ico3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/online-learning 1.svg'
let ico4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/certificate 1.svg'

function CertificationJourney() {
  return (
    <div className="journey_set_bg text-light tw-py-20">
      <div className="container tw-flex tw-justify-center tw-flex-col tw-h-full">
        <div className="tw-flex tw-flex-col">
          <div className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' style={{ textAlign: 'center' }}>Start your Certification Today</div>
          <div className='text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-70'>
            <div className="tw-flex tw-justify-center tw-max-w-xl">allow us to create an impact in your career</div>
          </div>

          <div className="tw-flex tw-justify-center tw-mt-10 text-dark">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center tw-py-3">
                <div className='tw-flex-col tw-bg-white tw-shadow-lg tw-transition-colors tw-w-[18.75rem] tw-h-[15.625rem] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                  <img loading="eager" alt="icon" src={ico1} />
                  <span className='tw-mt-5 tw-font-medium'>Learn with Experts</span>
                  <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15.625rem]'>Develop your hands-on experience with Industry practitioners</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center tw-py-3">
                <div className='tw-flex-col tw-bg-white tw-shadow-lg tw-transition-colors tw-w-[18.75rem] tw-h-[15.625rem] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                  <img loading="eager" alt="icon" src={ico2} />
                  <span className='tw-mt-5 tw-font-medium'>Futuristic Pattern</span>
                  <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15.625rem]'>Undergo a constructive & practical approach of learning</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center tw-py-3">
                <div className='tw-flex-col tw-bg-white tw-shadow-lg tw-transition-colors tw-w-[18.75rem] tw-h-[15.625rem] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                  <img loading="eager" alt="icon" src={ico3} />
                  <span className='tw-mt-5 tw-font-medium'>Flexible learning</span>
                  <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15.625rem]'>Corporate friendly, Live & Interactive Session</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 tw-flex tw-justify-center tw-py-3">
                <div className='tw-flex-col tw-bg-white tw-shadow-lg tw-transition-colors tw-w-[18.75rem] tw-h-[15.625rem] tw-rounded-lg tw-flex tw-justify-center tw-items-center'>
                  <img loading="eager" alt="icon" src={ico4} />
                  <span className='tw-mt-5 tw-font-medium'>Global Recognition</span>
                  <span className='tw-mt-1 text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15.625rem]'>Get Certified and achieve an industry standard position</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificationJourney