import React from 'react'
import { motion } from 'framer-motion'
import { downToUpVariant } from '../../animationVariants/variants'
let icon1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon1.svg'
let icon2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon2.svg'
let icon3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon3.svg'
let icon4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon4.svg'
let lineUp = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/LineUp.svg'
let lineDown = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/LineDown.svg'

function YourJourneyCurve() {
    return (
        <div className='tw-mt-16 lg:tw-mt-8 lg:tw-mb-24'>
            <span className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium '>Your Journey Curve</span>
            <div className='text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60'>We prepare you for the future</div>
            <div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className="tw-flex tw-justify-between tw-flex-wrap">
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-flex tw-flex-col tw-items-center tw-mx-1 tw-mt-8 lg:tw-mx-0 tw-w-full md:tw-w-auto'>
                    <img loading="eager" className='img-fluid ' src={icon2} alt="icon1" />
                    <span className='tw-mt-2 tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15rem] md:tw-max-w-[10rem] lg:tw-max-w-[126px] xl:tw-max-w-[10rem]'>Enroll Quickly & Securely</span>
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className="tw-hidden lg:tw-flex tw-h-[120px] tw-items-center tw-mt-8">
                    <img loading="eager" src={lineUp} className='' alt="" />
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-flex tw-flex-col tw-items-center tw-mx-1 tw-mt-8 lg:tw-mx-0 tw-w-full md:tw-w-auto'>
                    <img loading="eager" className='img-fluid ' src={icon1} alt="icon2" />
                    <span className='tw-mt-2 tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15rem] md:tw-max-w-[10rem] lg:tw-max-w-[126px] xl:tw-max-w-[10rem]'>Bridge your Corporate Project Experience with us</span>
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className="tw-hidden lg:tw-flex tw-h-[120px] tw-items-center tw-mt-8">
                    <img loading="eager" src={lineDown} className='' alt="" />
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-flex tw-flex-col tw-items-center tw-mx-1 tw-mt-8 lg:tw-mx-0 tw-w-full md:tw-w-auto'>
                    <img loading="eager" className='img-fluid ' src={icon3} alt="icon3" />
                    <span className='tw-mt-2 tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15rem] md:tw-max-w-[10rem] lg:tw-max-w-[126px] xl:tw-max-w-[10rem]'>Get your profile reviewed by renowned industry experts</span>
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className="tw-hidden lg:tw-flex tw-h-[120px] tw-items-center tw-mt-8">
                    <img loading="eager" src={lineUp} className='' alt="" />
                </motion.div>
                <motion.div variants={downToUpVariant} viewport={{ once: true }} initial="hidden" whileInView="visible" className='tw-flex tw-flex-col tw-items-center tw-mx-1 tw-mt-8 lg:tw-mx-0 tw-w-full md:tw-w-auto'>
                    <img loading="eager" className='img-fluid ' src={icon4} alt="icon" />
                    <span className='tw-mt-2 tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60 tw-max-w-[15rem] md:tw-max-w-[10rem] lg:tw-max-w-[126px] xl:tw-max-w-[10rem]'>Get Evaluated under GPA Audit & be Certified </span>
                </motion.div>
            </div>
        </div>
    )
}

export default YourJourneyCurve