import React from 'react'
import bajajPng from './assets/bajajFinance.png'
import cskPng from './assets/csk.png'
import godrejPng from './assets/godrej.png'
import hulPng from './assets/hul.png'
import suzukiPng from './assets/suzuki.png'
import walmartPng from './assets/walmart.png'

import hdfcPng from './assets/hdfc.png'
import zomatoPng from './assets/zomato.png'
import deltaPng from './assets/delta.png'
import reliancepng from './assets/reliance.png'

// const data= {
//     'Certified Investment Banking Professional Programme': {
//         content: 'Learn by doing in our range of hands-on real-world case studies on Public Manufacturing Companies, use of transaction multiples in acquisition cases of private Food-tech companies, as well as IPO analysis of companies to be listed on exchange, like Paytm. From simulation analysis of a leverage buyout deals with varying levels of debt load to replicating financial models of any company of choice, become a professional in Investment Banking!',

//     },
// }

function CaseStudy({courseName}) {
    return (
        <div className='tw-text-white light_dark_bg tw-pt-2 tw-pb-8'>
            <div className='container'>
                <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-[#FFC700]`}>Case Study Investigation</p>
                <p>
                    {
                        courseName==='Certified Investment Banking Professional Programme' 
                        ? <>Learn by doing in our range of hands-on
                        real-world case studies on Public Manufacturing
                        Companies, use of transaction multiples in acquisition
                        cases of private Food-tech companies, as well as IPO analysis of companies to be listed on exchange, like Paytm. From simulation analysis of a leverage buyout deals with varying levels of debt load to replicating financial models of any company of choice,
                        become a professional in Investment Banking!</>
                        :
                        <>Our experienced Investment Bankers and CFA Charterholders, will teach 
                        you on real life practical case studies for companies belonging to diverse sectors such as automobile, sports, FMCG and you can learn to build financial models from scratch. Trainees also learn about simulation, sensitivity and scenario analysis and IPL team Valuations. Apart from this, our trainers make sure they explain the concepts of accounting treatment while preparing a financial model. This approach helps you understand 
                        and gain better insights about various financial performance metrics of 
                        a company.</>
                    }
                </p>
                <div className="row tw-flex tw-justify-between tw-mt-3">
                    {
                        courseName==='Certified Investment Banking Professional Programme'
                        ? 
                        <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={zomatoPng} alt="" width={200}/></div> 
                        :
                        <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={zomatoPng} alt="" width={200}/></div>
                    }
                    <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={suzukiPng} alt="" width={200}/></div>
                    {
                        courseName==='Certified Investment Banking Professional Programme' 
                        ?
                        <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={reliancepng} alt="" width={200}/></div>
                        :
                        <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={deltaPng} alt="" width={200}/></div>
                    }
                    <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={hulPng} alt="" width={200}/></div>
                    <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={cskPng} alt="" width={200}/></div>
                    {
                        courseName==='Certified Investment Banking Professional Programme'
                        ?
                        <></>
                        // <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={deltaPng} alt="" width={200}/></div>
                        :
                        <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={reliancepng} alt="" width={200}/></div>
                    }
                    <div className="col-6 col-md-4 tw-flex tw-justify-center tw-my-3"><img src={hdfcPng} alt="" width={200}/></div>

                </div>
            </div>
        </div>
    )
}

export default CaseStudy