import React from 'react';
import left_arrow_icon from '../assets/left_arrow.svg';
import styles from './TraineeCard.module.css'

function TraineeCard({traineeCardData}) {
    return (
        <div className="tw-mx-5 md:tw-mx-0 tw-w-96 md:tw-w-full md:tw-scale-[0.85] tw-card tw-bg-base-100 tw-shadow-md tw-px-6 tw-py-4 tw-flex tw-justify-between">
            <div className='tw-flex tw-justify-end'>
                <p className='tw-border-[#41A404] tw-border-2 tw-text-[#41A404] tw-rounded-xl tw-w-fit tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-gap-3 tw-text-xs md:tw-text-sm'> 
                    {
                        traineeCardData.hike==='Fresher'
                        ? 
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                            <path d="M21.1875 11.4V6.2L11.1875 1L1.1875 6.2L11.1875 11.4L16.3875 9V14.6C16.3875 16.2 13.9875 17.8 11.1875 17.8C8.3875 17.8 5.9875 16.2 5.9875 14.6V9" stroke="#41A404" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
                            <path d="M16 0V1.77778H21.8542L14.2222 9.40978L10.4062 5.59378C10.2395 5.42714 10.0135 5.33352 9.77778 5.33352C9.54208 5.33352 9.31602 5.42714 9.14933 5.59378L0 14.7431L1.25689 16L9.77778 7.47911L13.5938 11.2951C13.7605 11.4618 13.9865 11.5554 14.2222 11.5554C14.4579 11.5554 14.684 11.4618 14.8507 11.2951L23.1111 3.03467V8.88889H24.8889V0H16Z" fill="#41A404"/>
                        </svg>
                    }
                    {traineeCardData.hike}
                </p>
            </div>
            <figure><img src={traineeCardData?.img} alt="instructor_img" className='img-fluid tw-w-36 md:tw-w-40 lg:tw-w-auto -tw-mt-1' /></figure>
            <div className="tw-my-3 !tw-text-sm md:tw-text-base">
                <h2 className={`tw-card-title tw-flex tw-justify-center tw-my-1 ${styles.card_header}`}>{traineeCardData?.name}</h2>
                <p className='tw-opacity-75 tw-text-center'>{traineeCardData?.batch}</p>
                <div className='row tw-mt-5'>
                    <div className='col-5 tw-flex tw-flex-col tw-items-center'>
                        <span className='tw-opacity-75 tw-font-semibold'>From: </span>
                        <span className='tw-text-center'>{traineeCardData.from}</span>
                    </div>
                    <div className='col tw-flex tw-items-center'>
                        <img src={left_arrow_icon} alt="left arrow" />
                    </div>
                    <div className='col-5 tw-flex tw-flex-col tw-items-center'>
                        <span className='tw-opacity-75 tw-font-semibold'>To: </span>
                        <span className='tw-text-center tw-font-bold'>{traineeCardData.to}</span>
                    </div>
                </div>
                <br/>
                <div className='tw-w-full tw-flex tw-justify-center'>
                    <img src={traineeCardData.companyImg} alt="company_log" />
                </div>
                {/* <p className='tw-text-center tw-font-semibold tw-mt-1'>{traineeCardData.to}</p> */}
            </div>
        </div>
    )
}

export default TraineeCard