import React from 'react'

function NewCourseButton({buttonName, clickHandler=(()=>{}), isBtnLike=false, btnBgColor='EBBA0D', btnTextColor="black"}) {
  return (
    <div>
      <label onClick={clickHandler} tabIndex={0} className={`${isBtnLike?"active:hover:tw-scale-[0.999] tw-transition":""} tw-cursor-pointer tw-text-sm tw-py-2 tw-px-10 tw-scale-110 tw-text-${btnTextColor} tw-border tw-border-[#${btnBgColor}] tw-bg-[#${btnBgColor}] tw-capitalize hover:tw-bg-[#${btnBgColor}] hover:tw-brightness-95 hover:tw-border-[#${btnBgColor}] tw-rounded-xl tw-w-full tw-font-medium tw-flex tw-justify-center`}>
        {buttonName}
      </label>
    </div>
  )
}

export default NewCourseButton