import React from 'react'

function Instructors({eventData}) {
  return (
    <div>
      <div className='tw-mt-12 tw-mb-8 md:tw-my-5 tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-flex tw-flex-col'>
        Instructor
      </div>
      {eventData.curriculum.map(item => {
        return (
          <div key={item?._id?._id}>
            <div>
              <div className='tw-flex tw-items-center tw-my-3'>
                <div className="tw-avatar">
                  <div className="tw-w-28 tw-rounded-full ring ring-primary tw-ring-offset-base-100 tw-ring-offset-2">
                    <img src={item?._id?.img} alt="instructor" />
                  </div>
                </div>
                <div className='tw-flex tw-flex-col tw-ml-3'>
                  <div className='tw-text-xs lg:tw-text-sm tw-font-medium tw-flex tw-flex-col'>{item?._id?.name}</div>
                  <div className='overview_css tw-text-xs lg:tw-text-sm tw-opacity-95'>{item?._id?.post}</div>
                </div>
              </div>
              <p className='overview_css tw-text-xs lg:tw-text-sm tw-opacity-95'>{item?._id?.about}</p>
            </div>
            <br />
          </div>
        )
      })}
    </div>
  )
}

export default Instructors