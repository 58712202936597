import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import serverAPI from '../../utils/baseAPI';
import Spinner from '../../helpersComponents/Spinner';
import {Helmet} from "react-helmet";

function SingleBlog() {
  const [blogData, setBlogData] = useState([]);
  const location = useLocation();
  const pathSplit = location.pathname.split('/');
  const blogId = pathSplit[pathSplit.length - 1];
  const [loginFailedMsg, setLoginFailedMsg]= useState("");


  useEffect(() => {
    serverAPI.get(`/api/blog/${blogId}`).then(e => {
      setBlogData(e.data.data[0]);
    }).catch(e => {
      setLoginFailedMsg(e.response.data.msg);
    })
  }, [blogId]);

  if(loginFailedMsg!==""){
    return <div className='tw-flex tw-justify-center tw-items-center tw-h-screen tw-w-screen'>{loginFailedMsg}</div>
  }

  return blogData.length === 0 ? <Spinner /> : (
    <div className='tw-mt-28 tw-mb-16 container tw-min-h-[85vh]'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blogData?.title}</title>
        <meta name="description" content={blogData?.intro} />
      </Helmet>
      <div className='text-center'>
        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium tw-uppercase' >
          {blogData.genre}
        </div>
        <div className='tw-my-2 tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold' >
          {blogData.title}
        </div>
        <div className="row tw-flex tw-items-center tw-justify-between">
          <span className='col tw-text-xs lg:tw-text-sm tw-opacity-70'>
            {moment(blogData.createdAt).format("MMMM DD, YYYY")}
          </span>
        </div>
      </div>

      <div className="tw-my-14 container tw-flex tw-justify-center ">
        {/* tw-h-[600px] */}
        <img src={blogData.thumbnail} alt="thumbnail" className='img-fluid tw-rounded' />
        {/* <div className='tw-w-[100%] tw-h-auto'>
          <div style={{ backgroundImage: `url(${blogData.thumbnail})`, backgroundSize: 'cover', height: '100%', backgroundRepeat: 'no-repeat', objectFit:'cover' }} className=" tw-w-auto lg:tw-w-[100%] tw-rounded-md">
            fds
          </div>
        </div> */}
      </div>

      <div className='lg:tw-mx-40 tw-my-8 tw-text-sm lg:tw-text-base'>
        {
          blogData.texts.slice(0, blogData.texts.length).map((text, i)=>{
            return <div key={i}>
              <p className="tw-my-5">{text.split('\n').map((line) => <GetElement key={line} line={line} />)}</p>
              {i < blogData.images.length && <div className='tw-mb-8 tw-mt-6 tw-flex tw-justify-center tw-h-auto'> 
              {/* tw-h-[350px] */}
                <img src={blogData.images[i]} className="img-fluid tw-rounded" alt="" />
              </div>}
            </div>
          })
        }
      </div>
    </div>
  )
}

function GetElement({ line }) {
  if (line.startsWith('~bo')) return <b>{line.replace('~bo', '')}</b>;
  if (line.startsWith('~it')) return <i className='tw-opacity-75'>{line.replace('~it', '')}</i>;
  if(line.startsWith('~lio')) return (
    <div
      style={{
        // marginTop: '0.5rem',
        marginLeft: '2rem',
        // marginBottom: '0.5rem',
      }}
      className='tw-opacity-75'
    >
      <span style={{height:5, width:5, backgroundColor:'black', borderRadius:'50%', display: 'inline-block'}}></span>
      <div style={{marginLeft:'10px', position:'relative', top:'-20px'}}>
        <span>{line.replace('~lio', '')}</span>
      </div>
    </div>
  );
  if (line.startsWith('~li')) return (
      <div
        style={{
          marginTop: '0.5rem',
          marginLeft: '2rem',
          marginBottom: '0.5rem',
        }}
        className='tw-opacity-75'
      >
        <>
          {line.replace('~li', '')} <br />
        </>
      </div>
    );
    if(line.startsWith('~ref')) return (
      <div
        className='tw-opacity-75'
      >
        <span style={{height:5, width:5, backgroundColor:'black', borderRadius:'50%', display: 'inline-block'}}></span>
        <div style={{marginLeft:'10px', position:'relative', top:'-20px'}}>
        <a target='__blank' rel="noreferrer noopener" href={line.replace('~ref', '')} style={{color:'dodgerblue'}}>{line.replace('~ref', '')}</a>
        </div>
      </div>
    );
  return (
    <span className='tw-opacity-75'>
      {line} <br />
    </span>
  );
}

export default SingleBlog