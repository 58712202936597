import React from 'react'

export function Modal({ setShowModal, children, zIndex=1000 }) { //children and function to disable the modal
    function handlecloseModal() {
        setShowModal(false);
    }

    return (<>
        <div className={`tw-z-[${zIndex}] tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center tw-fixed tw-z-[1000] tw-backdrop-blur-sm`} onClick={handlecloseModal}>
            <div className="tw-card md:tw-card-side tw-bg-base-100 tw-shadow-xl" onClick={(e) => e.stopPropagation()}>
                <button className="tw-btn tw-btn-md tw-btn-ghost tw-btn-circle tw-absolute tw-right-0" onClick={handlecloseModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                <div className='tw-min-w-[340px] tw-min-h-[340px] md:tw-min-w-[400px] md:tw-min-h-[400px] tw-flex tw-justify-center tw-items-center'>
                    {children}
                </div>
            </div>
        </div>
    </>)
}