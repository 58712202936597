import serverAPI from '../../utils/baseAPI';
import {ACTIVE_SELF_PACED_COURSE, ALL_SELF_PACED_COURSE, CLEAR_ALL_SELF_PACED_COURSE, NOT_ACTIVE_SELF_PACED_COURSE} from './selfPacedTypes';

export const getActiveSelfPacedCourse= ()=>{
    //console.log('running get active coures')
    return async (dispatch) =>{
        await serverAPI.get(`/api/self-paced-course/status/active`)
        .then(e=>{
            console.log(e.data);
            dispatch(setActiveSelfPacedCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setActiveSelfPacedCourse=(data)=>{
    return {
        type: ACTIVE_SELF_PACED_COURSE,
        payload: data
    }
}
export const getNotActiveSelfPacedCourse= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/self-paced-course/status/not-active`)
        .then(e=>{
            dispatch(setNotActiveSelfPacedCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setNotActiveSelfPacedCourse=(data)=>{
    return {
        type: NOT_ACTIVE_SELF_PACED_COURSE,
        payload: data
    }
}

export const getAllSelfPacedCourse= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/self-paced-course`)
        .then(e=>{
            dispatch(setAllSelfPacedCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setAllSelfPacedCourse=(data)=>{
    return {
        type: ALL_SELF_PACED_COURSE,
        payload: data
    }
}

export const selfPacedCourseLogOutClear=()=>{
    return {
        type: CLEAR_ALL_SELF_PACED_COURSE
    }
}