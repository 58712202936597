import React from 'react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

function LearnFrom({ instructorData }) {
    return (
        <div className='tw-pt-2 tw-pb-8'>
            <div className='container'>
                <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-[#FFC700]`}>
                    <span className='tw-text-[#5B1B73CC]'>Learn From Our</span>
                    <span className='tw-text-[#C12CB4] tw-ml-1'>Top Experts In Industry</span>
                </p>
                <div className='tw-flex tw-justify-evenly tw-flex-wrap tw-flex-gap-3'>
                    {
                        instructorData?.map(instructor => <InstructorCard instructorName={instructor.name} instructorPosition={instructor.post} instructorImg={instructor.img} />)
                    }
                </div>
            </div>
        </div>
    )
}

function InstructorCard({ instructorName, instructorPosition, instructorImg }) {
    return (
        <div className='col-12 col-md-4 col-lg-auto tw-my-6 tw-scale-95'>
            <div className='tw-flex tw-flex-col tw-gap-3'>
                <div className='tw-flex tw-justify-center'>
                    <img src={instructorImg} alt="instructor" />
                </div>
                <div className='tw-text-[#C12CB4] tw-font-medium tw-text-center'>
                    {instructorName}
                </div>
                <div className='tw-text-sm tw-text-center'>
                    {instructorPosition}
                </div>
            </div>
        </div>
    )
}

export default LearnFrom
