import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import './Footer.css';
let logo = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/finocontrol_logo_main.svg'
let moboLogo = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/finocontrol_logo_main_mobo.svg'
let tw = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/tw.svg'
let linkedin = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/in.svg'
let ig = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/ig.svg'
let fb = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/fb.svg'


function Footer() {
  const [isMobo, setIsMobo]=useState(false);
  useEffect(()=>{
    if(window.screen.width <= 992) {
      setIsMobo(true);
    } else {
      setIsMobo(false)
    }
  }, [])

  return (
    <div className=' tw-bg-[#1a064f] text-light ' style={{ position: 'relative', zIndex: 100 }}>
      <div className="container tw-py-6">

        <div className="tw-pb-3 row tw-flex tw-items-center tw-justify-between">
          <span className='col'>
            <img className="img-fluid tw-w-36 lg:tw-w-40" src={isMobo? moboLogo :logo} alt="fin logo" />
          </span>
          <span className='col'>
            <span className='tw-flex tw-justify-end tw-items-center'>
              <span className='tw-text-base tw-font-medium d-none d-md-flex tw-mr-12'>Follow us on social media</span>
              <span className="tw-flex row">
                <a href="https://www.facebook.com/finocontrol" rel="noreferrer noopener" target='__blank' className="col tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"> <button ><img src={fb} alt="" /></button> </a>
                <a href="https://twitter.com/finocontrol" rel="noreferrer noopener" target='__blank' className="col tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"> <img src={tw} alt="" /></a>
                <a href="https://www.instagram.com/finocontrol/" rel="noreferrer noopener" target='__blank' className="col tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"> <img src={ig} alt="" /></a>
                <a href="https://www.linkedin.com/company/finocontrol" rel="noreferrer noopener" target='__blank' className="col tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"> <img src={linkedin} alt="" /></a>
              </span>
            </span>
          </span>
        </div>

        <div className='tw-my-1 tw-divider before:tw-bg-white before:tw-opacity-[0.15] after:tw-bg-white after:tw-opacity-[0.15]' />

        <div className=''>
          <div className="row tw-justify-between">
            <div className="tw-my-12 col-6 col-md-4 col-lg-2">
              <div className="tw-flex tw-flex-col">
                <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase'>Get to Know Us</span>
                <Link to="/about" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>About Us</span></Link>
                <Link to="/course" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>Learn</span></Link>
                <Link to="/course" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>Get Certified</span></Link>
                {/* <Link to="/careers" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>Careers</span></Link> */}
              </div>
            </div>
            <div className="tw-my-12 col-6 col-md-4 col-lg-2">
              <div className="tw-flex tw-flex-col">
                <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase'>Let Us Help You</span>
                <Link to="/terms-and-conditions" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>term & conditions</span></Link>
                <Link to="/privacy-policy" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>privacy policy</span></Link>
                <Link to="/refund-policy" className='tw-my-2' ><span className='tw-text-sm tw-capitalize'>refund policy</span></Link>
              </div>
            </div>
            {/* <div className="tw-my-12 col-6 col-md-4 col-lg-2">
              <div className="tw-flex tw-flex-col">
                <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase'>Connect with us</span>
                <a href='https://www.instagram.com/finocontrol/' className='tw-text-sm tw-capitalize tw-my-2'>Instagram</a>
                <a href='https://www.facebook.com/finocontrol' className='tw-text-sm tw-capitalize tw-my-2'>Facebook</a>
                <a href='https://twitter.com/finocontrol' className='tw-text-sm tw-capitalize tw-my-2'>Twitter</a>
                <a href='https://www.linkedin.com/company/finocontrol' className='tw-text-sm tw-capitalize tw-my-2'>LinkedIn</a>
              </div>
            </div> */}
            <div className="tw-my-12 col-6 col-md-4 col-lg-2">
              <div className="tw-flex tw-flex-col">
                {/* <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase tw-h-[1.6rem]'></span> */}
                <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase'>Contact us</span>
                <span className='tw-text-sm tw-my-2'> Mail: <a href='mailto:hello@finocontrol.com' className='hover_black_footer' target='__blank' > hello@finocontrol.com</a>  </span>
                <span className='tw-text-sm tw-my-1'>Address: Chandaka Industrial Estate, Patia, Bhubaneswar, Odisha, India</span>
                {/* <span className='tw-text-sm tw-my-1'></span>
                <span className='tw-text-sm tw-my-1'>Odisha, India</span> */}
                <span className='tw-text-sm tw-my-2'>Mobile: <a href='tel:+918093555001' className='hover_black_footer' target='__blank'> +91 80935 55001</a></span>
                <span className='tw-text-sm tw-my-2'>GSTIN: <span className='hover_black_footer'> 21AAHFF9203B1Z3</span></span>
              </div>
            </div>
            <div className="tw-min-w-[290px] tw-my-12 col-6 col-md-4 col-lg-2">
              <div className="tw-flex tw-flex-col">
                <span className='tw-text-base tw-mb-5 tw-font-medium tw-uppercase'>get in touch</span>
                <span className='tw-text-sm tw-capitalize tw-my-2'>We don't send spam so don't worry.</span>
                <span className="tw-flex">


                  <form className='tw-w-full'>
                    <label for="search" className="tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-sr-only">Search</label>
                    <div className="tw-relative">
                      <input type="email" className="tw-rounded-full tw-block tw-w-full tw-p-4 tw-pr-24 tw-text-sm tw-text-gray-900 tw-border tw-border-gray-300 tw-bg-gray-50 focus:tw-ring-blue-500 focus:tw-border-blue-500 " placeholder="Email" required />
                      <button type="submit" className="tw-rounded-full tw-btn-primary tw-text-white tw-absolute tw-right-2.5 tw-bottom-2.5 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-text-sm tw-px-4 tw-py-2">Submit</button>
                    </div>
                  </form>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='tw-my-1 tw-divider before:tw-bg-white before:tw-opacity-[0.15] after:tw-bg-white after:tw-opacity-[0.15]' />

        <div className="row tw-text-sm">
          <div className="col-12 col-md-6 tw-pt-8 tw-py-2 tw-flex tw-items-center">(Registered & Recognized Under Ministry of Corporate Affairs, India)</div>
          <div className="col-12 col-md-6 lg:tw-pt-8 lg:tw-py-2 tw-flex tw-justify-start tw-items-center lg:tw-justify-end">Copyright © {new Date().getFullYear()} Finocontrol India | All Right Reserved.</div>
          {/* <div className="col-12 col-md-6 tw-py-8">
            <div className="row tw-justify-between">
              <span className='tw-py-2 lg:tw-py-0 tw-min-w-fit col-6 col-md-2 tw-capitalize'>help</span>
              <span className='tw-py-2 lg:tw-py-0 tw-min-w-fit col-6 col-md-2 tw-capitalize'>privacy & policy</span>
              <span className='tw-py-2 lg:tw-py-0 tw-min-w-fit col-6 col-md-2 tw-capitalize'>cookie notice</span>
              <span className='tw-py-2 lg:tw-py-0 tw-min-w-fit col-6 col-md-2 tw-capitalize'>security</span>
              <span className='tw-py-2 lg:tw-py-0 tw-min-w-fit col-6 col-md-2 tw-capitalize'>term of use</span>
            </div>
          </div> */}
        </div>

      </div>

    </div>
  )
}

export default Footer
