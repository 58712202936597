import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import excelIcon from './assets/excel.png'
import ppIcon from './assets/pp.png'
import chatGptIcon from './assets/chatgpt.png';
import powerBIIcon from './assets/powerbi.png';
import linkedInIcon from './assets/LinkedInIcon.png';

function CorporateTool({courseName}) {
    return (
        <div className='tw-text-white light_dark_bg tw-pt-2 tw-pb-8 tw-min-h-[50vh] tw-flex tw-justify-center tw-items-center tw-flex-col'>
            <div className='container'>
                <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-[#FFC700]`}>Corporate Tools You work with</p>
                <p>
                    The {courseName} course gives you a
                    Futuristic approach to learning and hands-on practice with new-age tools
                    like Excel, Powerpoint, Power BI, LinkedIn & Chatgpt.
                    These tools will look great on your Resume/
                    CV and are currently in demand by top institutions, startups & Big MNCs.
                </p>
            </div>
            <Swiper
                spaceBetween={20}
                grabCursor={true}
                centeredSlides={true}
                // slidesPerView={5}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                speed={1200}
                pagination={true}
                loop={true}
                modules={[Autoplay]}
                className="tw-mt-8 container"
                breakpoints={{
                    0: {
                        slidesPerView: 3,
                    },
                    576: { 
                        slidesPerView: 3,
                        // spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 4,
                        // spaceBetween: -100
                    },
                    992: {
                        slidesPerView: 4,
                        // spaceBetween: 20
                    },
                    1200:{
                      slidesPerView: 5,
                    },
                    1400: {
                        slidesPerView: 5,
                    }
                }}
            >
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={excelIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={ppIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={linkedInIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={spIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={trIcon} alt="newsLogo" width={260}/></SwiperSlide> */}
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={chatGptIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={powerBIIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={quickBooksIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={copyAiIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={screenerIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={finChatIcon} alt="newsLogo" width={260}/></SwiperSlide> */}




                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={excelIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={ppIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={linkedInIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={spIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={trIcon} alt="newsLogo" width={260}/></SwiperSlide> */}
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={chatGptIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={powerBIIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={quickBooksIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={copyAiIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={screenerIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={finChatIcon} alt="newsLogo" width={260}/></SwiperSlide> */}



                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={excelIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={ppIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={linkedInIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={spIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={trIcon} alt="newsLogo" width={260}/></SwiperSlide> */}
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={chatGptIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={powerBIIcon} alt="newsLogo" width={260}/></SwiperSlide>
                {/* <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={quickBooksIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={copyAiIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={screenerIcon} alt="newsLogo" width={260}/></SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: "center", height: 'auto' }} ><img  className='tw-scale-90 md:tw-scale-75' src={finChatIcon} alt="newsLogo" width={260}/></SwiperSlide> */}

            </Swiper>
        </div>
    )
}

export default CorporateTool