import React, {useEffect} from 'react'
import sendIcon from './assets/Send.svg'
import { Link } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {getAllBlog} from '../../../redux/blog/blogAction'
import moment from 'moment'

function Blog() {
  const blogDispatch= useDispatch();

  const blogsData = useSelector(state => state.blog.allBlog?.data?.slice(0, 4)) || [];
  //console.log(blogsData);

  useEffect(()=>{
    if (blogsData.length === 0)
      blogDispatch(getAllBlog());
  }, [blogDispatch, blogsData]);
  return (
    <div className='tw-my-20 container'>
      <div className='tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' >Blog</div>
      <div className="row tw-flex tw-items-center tw-justify-between">
        <span className='col tw-text-xs lg:tw-text-sm tw-opacity-70'>
          10,000+ unique online course list designs
        </span>
        <button className='col tw-flex tw-justify-end'>
          <Link to="/blogs"><span className='tw-btn tw-capitalize tw-rounded-full tw-bg-[#6440fb12] tw-text-[#6440FB] tw-border-none hover:tw-bg-[#6440fb63] tw-w-[10rem] lg:tw-w-[12rem]'>browse blog <img loading="eager" className='tw-ml-2' src={sendIcon} alt="sendIcon" /></span></Link>
        </button>
      </div>

      <div className="tw-my-10 row">
        {blogsData.map(item=>{
          return <div key={item?._id} className="col-12 col-md-6 col-lg-4 col-xl-3 tw-mb-4">

          <div className="lg:tw-min-h-[360px] tw-flex tw-justify-between tw-flex-col tw-shadow-md tw-pb-4 tw-p-1 tw-w-[19rem] tw-m-auto tw-bg-base-100 tw-rounded-lg tw-transition-shadow hover:tw-shadow-md">
            <img loading="eager" src={item.thumbnail} alt="Shoes" className='tw-object-cover tw-rounded-lg' />
            <div className='tw-mt-4 tw-p-2 '>
              <span className='tw-text-[#6440FB] tw-text-sm tw-uppercase'>{item.genre}</span>
              <p className='tw-text-base tw-my-2 tw-font-medium'>{item.title}</p>
              <div className='tw-flex tw-justify-between tw-items-center'>
                <p className='tw-text-sm tw-opacity-70'>{moment(item.createdAt).format("MMMM DD, YYYY")}</p>
                <Link to={`/blogs/${item._id}`}>
                  <button className='tw-btn tw-btn-sm tw-btn-primary '>&rarr;</button>
                </Link>
              </div>
            </div>
          </div>
          

        </div>
        })}
      </div>
    </div>
  )
}

export default Blog