import React, { useEffect, useState } from 'react'
const initialFaq = [
  // {
  //   title: 'Can I get a Demo Recording?',
  //   content: "Yes, you can. Please drop a mail to hello@finocontrol.com",
  //   _id: "a1"
  // },
  {
    title: 'In what areas do you provide Internships?',
    content: 'Financial Analyst & Research Analyst ',
    _id: "a2"
  },
  {
    title: 'What are the timings for this Certification Programme?',
    content: "It's a corporate friendly training programme and workshop. The trainers themselves are working in corporates.",
    _id: "a3"
  },
  {
    title: 'We\'re working professionals, can we have access to all the live Session recordings?',
    content: 'Yes, you\'ll have lifetime access to all the recorded lectures along with excel and reading materials.',
    _id: "a4"
  },
  {
    title: 'Would it be self placed or with live guidance?',
    content: 'Live and interactive sessions will be conducted.',
    _id: "a5"
  },
  {
    title: 'Do you provide Placements Assistance & mentoring?',
    content: 'We bring in placements from Top Investment Banks, Unicorn Startups & Big 4 Accounting Firms. We are ranked 9th globally for our excellence in Finance training courses, and our certificates of recognition and recommendations are worth a lot in the market.',
    _id: "a6"
  },
  {
    title: 'What is the referral scheme?',
    content: 'If you enroll and you recommend your friend to enroll as well, after they have enrolled, you\'ll get a cashback of Rs 500/- (applicable for the person who made full payment while enrolling)',
    _id: "a7"
  },
  {
    title: 'I belong to a Non-Finance background and aim to switch to Finance. How will this training help me transition to a career in Finance?',
    content: 'Our Certified Programmes are exclusively developed for a wider audience taking into consideration that you are a newbie to Finance. Our Experts undergo deeper research and design the curriculum from Basics to Advanced level that is best suitable for a student & corporate professional as well. Thus, irrespective of your educational background, and experience, you can be very sure to choose the Certification and be an industry-ready professional that will act as a strong presence in the corporate world. A lot of the freshers and professionals from Sales, HR, Marketing, Tax, and Audit have transitioned their careers to Finance After Completion of the training programme.',
    _id: "a8"
  }, 
  {
    title: 'Currently my CTC is "x" LPA . How much hike can I expect after doing this Course?',
    content: 'If your education and experience are aligned with Finance or any related areas of Finance such as Wealth, Tax, Credit, and Retail banking then you can expect a 30%-50% hike as a fresher or professional which can climb to 80%-150% as well depending upon the profile, location, technical skills & negotiation. Ideally, Students and professionals from other backgrounds should aim to enter into the core domain first irrespective of observing hikes. However, once they are in the system their growth will be exponentially high too.',
    _id: "a9"
  },
  {
    title: 'What would be the average CTC after doing this course?',
    content: 'The average CTC after receiving your Certificate of Recognition from us is ₹7- ₹8 LPA as a Fresher and it can range from ₹12 - ₹40 LPA depending upon the level of relevant experience you carry. Our Alumni work across each and every company including BIG 4s, Investment Banks, and Leading Startups from India and abroad.',
    _id: "a10"
  },
  {
    title: 'I have joined the Programme but I am finding it difficult to cope with concepts and not able to work on my Live Project, What should I do?',
    content: `You don't need to worry once your enrolled for the programme. Ensuring your competencies will be our responsibility. You will assigned with a mentor to guide you through your professional journey. You will have 1-on-1 Doubt clearing sessions with the trainers when you find it difficult to capture the concepts. The Expert team of CFAs, CAs, & Investment Bankers will make sure to provide you an detailed insight and hand-holding support till the corporate live project. As a Student, you also need to be disciplined and committed to the programme and complete all assessments on time.`,
    _id: "a11"
  }
]
function HowCanWeHelp({ courseFaq = [] }) {
  const [faq, setFaq] = useState(initialFaq);

  useEffect(() => {
    setFaq([...initialFaq, ...courseFaq]);
  }, [courseFaq])
  return (
    <div className='tw-my-8'>
      <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-pb-6 `}>How Can We Help ?</p>
      <div className='container'>
        {
          faq?.map((item, index) => {
            return <div className='tw-mb-2' key={item?._id}>
              <div className={`!tw-bg-white tw-rounded-xl tw-border-[1px]`}>
                <div className={`tw-collapse tw-collapse-arrow`}>
                  <input type="checkbox" className="tw-peer" />
                  {/* peer-checked:tw-bg-secondary peer-checked:tw-text-secondary-content */}
                  <div className={`tw-transition-all tw-collapse-title tw-capitalize tw-rounded-xl tw-text-sm md:tw-text-base tw-font-medium peer-checked:tw-rounded-b-none`}>
                    {/* <span className='tw-opacity-90'>module #{index + 1} &nbsp; : &nbsp;</span> */}
                    <span>{item.title}</span>
                    {/* <span> &nbsp; (6 Hours)</span> */}
                  </div>
                  <div className="tw-px-5 tw-transition-all peer-checked:tw-mt-0 tw-collapse-content">
                    {/* {
                          item.desc.map((oneDesc, index) => { */}
                    <span className="tw-flex tw-items-center tw-my-2">
                      {/* <div className='tw-max-w-[22px] tw-opacity-80' ><IconList/></div> */}
                      <p className='tw-ml-3 overview_css tw-text-xs lg:tw-text-sm tw-opacity-95'>{item.content}</p>
                    </span>
                    {/* })
                        } */}
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default HowCanWeHelp