import React from 'react'

let logo1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small1.svg';
let logo2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big1.svg';
let logo3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small2.svg';
let logo4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big2.svg';
let logo5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small3.svg';
let logo6 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big3.svg';

let logo7 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small4.svg';
let logo8 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big4.svg';
let logo9 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small5.svg';
let logo10 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big5.svg';
let logo11 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo1.svg';
let logo12 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo13.svg';

let logo13 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo6.svg';
let logo14 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo12.svg';
let logo15 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo11.svg';
let logo16 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Coforge.svg';
let logo17 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Peaceful-Progress.svg';
let logo18 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/c4d.svg';

function PlacementRecruiters() {
  return (
    <div className='tw-my-16 lg:tw-mt-8 lg:tw-mb-24 hide_overflow'>
          <span className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium '>Our Placement Recruiters</span>
          {/* above recruiter */}
          <div className='row tw-overflow-auto slide self_hide_x_scrollbar icon_hover_paused' style={{ marginTop: '2rem', flexWrap: 'nowrap', overflow: 'hidden' }}>
            <div className='tw-flex tw-items-center row animate_left ' style={{ position: 'relative' }}>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo1} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo2} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo3} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo4} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo5} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo6} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
            </div>
            <div className='tw-flex tw-items-center row animate_left' style={{ position: 'relative' }}>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo1} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo2} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo3} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo4} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo5} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo6} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
            </div>
          </div>
          {/* below recruiter */}
          <div className='row tw-overflow-auto slide self_hide_x_scrollbar icon_hover_paused' style={{ marginTop: '2rem', flexWrap: 'nowrap', overflow: 'hidden' }}>
            <div className='tw-flex tw-items-center row animate_right' style={{ position: 'relative' }}>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo7} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo8} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo9} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo10} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo11} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo12} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
            </div>
            <div className='tw-flex tw-items-center row animate_right' style={{ position: 'relative' }}>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo7} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo8} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo9} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo10} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo11} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo12} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
            </div>
          </div>         

          <div className='row tw-overflow-auto slide self_hide_x_scrollbar icon_hover_paused' style={{ marginTop: '2rem', flexWrap: 'nowrap', overflow: 'hidden' }}>
            <div className='tw-flex tw-items-center row animate_left ' style={{ position: 'relative' }}>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo13} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo14} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo15} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo16} className="tw-h-[2.2rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo17} className="tw-h-[2.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo18} className="tw-h-[2.6rem] inc_on_hover" alt="" /> </span>
            </div>
            <div className='tw-flex tw-items-center row animate_left' style={{ position: 'relative' }}>
            <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo13} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo14} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo15} className="tw-h-[3.6rem] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo16} className="tw-h-[2.2em] inc_on_hover" alt="" /> </span>
              <span className='col tw-flex tw-justify-center tw-items-center'><img loading="eager" src={logo17} className="tw-h-[2.6rem] inc_on_hover" alt="" /> </span>
              <span className='col lg:tw-flex lg:tw-justify-center lg:tw-items-center tw-hidden md:tw-flex'><img loading="eager" src={logo18} className="tw-h-[2.6rem] inc_on_hover" alt="" /> </span>
            </div>
          </div> 
        </div>
  )
}

export default PlacementRecruiters