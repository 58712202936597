import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css/navigation';
import "swiper/css";

import './IndustrialPartner.css'

let indusImg1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner1.svg'
let indusImg2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner2.svg'
let indusImg3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner3.svg'
let indusImg4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner4.svg'
let indusImg5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner5.svg'
let indusImg6 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner6.svg'
let indusImg7 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner7.svg'
let indusImg8 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner8.svg'
let indusImg9 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner9.svg'
let indusImg10 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner10.svg'
let indusImg11 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner11.svg'
let indusImg12 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner12.svg'
let indusImg13 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner13.svg'
let indusImg14 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner14.svg'
let indusImg15 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner15.svg'
let indusImg16 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner16.svg'
let indusImg17 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-industrial-partners/partner17.svg'

function IndustrialPartner() {
  return (
    <div className='container tw-my-16 tw-flex tw-items-center'>
      <div className='hide_overflow'>
        <div className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium '>Our Industrial Partners</div>
        <div className='text-center tw-text-xs lg:tw-text-sm tw-opacity-60'>Over 7320 hours of Corporate practice training and engagement activities delivered</div>
        <Swiper
          breakpoints={{
            1500:{
              slidesPerView:5,
              // spaceBetween:225
            },
            1400:{
                slidesPerView:5,
                // spaceBetween:200
            },
            1200:{
              slidesPerView:5,
              // spaceBetween:160
            },
            1000:{
              slidesPerView:5,
              // spaceBetween:125
            },
            800: {
              slidesPerView: 3,
              // spaceBetween: 180
            },
            700:{
              slidesPerView: 3,
              // spaceBetween: 100
            },
            550: {
              slidesPerView: 3,
              // spaceBetween: 80
            },
            450: {
              slidesPerView: 3,
              // spaceBetween: 40
            },
            350: {
              slidesPerView:3,
              // spaceBetween: 30
            },
            0:{
              slidesPerView: 3,
              // spaceBetween: 40
            }
          }}
          grabCursor={true}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={1000}
          pagination={true}
          loop={true}
          modules={[Autoplay]}
          className="tw-mt-8 tw-flex tw-justify-center tw-items-center"
        >
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg1} alt=""  className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg2} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
              {/* <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg3} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide> */}
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg4} alt="" className='lg:tw-w-[95px] lg:tw-h-[95] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg5} alt="" className='lg:tw-w-[150px] lg:tw-h-[150px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg6} alt="" className='lg:tw-w-[130px] lg:tw-h-[130px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>

          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg1} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg7} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg8} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg9} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg10} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg11} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>

          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg12} alt="" className='lg:tw-w-[130px] lg:tw-h-[130px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg13} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg14} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg15} alt="" className='lg:tw-w-[130px] lg:tw-h-[130px] tw-w-[100px] tw-h-[100px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg16} alt="" className='lg:tw-w-[90px] lg:tw-h-[90px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>
          <SwiperSlide className='tw-py-8 inc_on_hover_industrial_partner'><span style={{justifyContent:'center', alignItems:'center', display:'flex', }} className='tw-h-[60px] lg:tw-h-[100px]'><img loading="eager" src={indusImg17} alt="" className='lg:tw-w-[100px] lg:tw-h-[100px] tw-w-[90px] tw-h-[90px]'/></span></SwiperSlide>

          <div className='tw-flex tw-justify-center tw-mt-3'>
            <SwiperButtonPrev>
              <span className='tw-pr-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &larr; </span> 
            </SwiperButtonPrev>
            <SwiperButtonNext>
                <span className='tw-pl-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &rarr;</span>
            </SwiperButtonNext>
          </div>
        </Swiper>
        
      </div>
    </div>
  )
}


const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <button onClick={() => swiper.slideNext()}>{children}</button>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <button onClick={() => swiper.slidePrev()}>{children}</button>;
};

export default IndustrialPartner
