import React from 'react'

function IconList({ outCircleColor = "#263238", inCircleColor = "black" }) {
  return (
    <svg width="12" height="12" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" stroke={outCircleColor} />
      <circle cx="16" cy="16" r="9" fill={inCircleColor} fill-opacity="0.7" />
    </svg>
  )
}

export default IconList