import React from 'react'
import styles from './Curriculum.module.css'
import IconList from './IconList'

function Curicullum({ curriculum }) {
  return (
    <div className='tw-my-8'>
      <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-pb-6 `}>Our Holistic Curriculum</p>
      <div className='container'>
        {
          curriculum?.map((item, index) => {
            return <div className='tw-mb-2' key={item?._id}>
              <div className={`!tw-bg-white tw-rounded-xl tw-border-[1px]`}>
                <div className={`tw-collapse tw-collapse-arrow`}>
                  <input type="checkbox" className="tw-peer" />
                  <div className={`tw-transition-all tw-collapse-title tw-capitalize !tw-text-white ${styles.curriculum_bg} tw-rounded-xl tw-text-sm md:tw-text-base tw-font-medium peer-checked:tw-rounded-b-none`}>
                    <span className='tw-opacity-90'>module #{index + 1} &nbsp; : &nbsp;</span>
                    <span>{item.title}</span>
                    <span> ( {item?.duration || '4 Hours'} )</span>
                  </div>
                  <div className="tw-px-5 tw-transition-all peer-checked:tw-mt-0 tw-collapse-content">
                    {
                      item?.desc?.map((oneDesc, index) => {
                        return <span key={index} className="tw-flex tw-items-center tw-my-2">
                          <div className='tw-max-w-[22px] tw-opacity-80' ><IconList /></div>
                          <p className='tw-ml-3 overview_css tw-text-xs lg:tw-text-sm tw-opacity-95'>{oneDesc}</p>
                        </span>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default Curicullum