import React from 'react';
import Icon1 from './assets/icon1.svg'
import Icon2 from './assets/icon2.svg'
import Icon3 from './assets/icon3.svg'
import Icon4 from './assets/icon4.svg'
import Icon5 from './assets/icon5.svg'
import Icon6 from './assets/icon6.svg'
import Icon7 from './assets/icon7.svg'
import Icon8 from './assets/icon8.svg'
import Icon9 from './assets/icon9.svg'
import Icon10 from './assets/icon10.svg'
import Icon11 from './assets/icon11.svg'
import Icon12 from './assets/icon12.svg'
const profileData = [
    {
        _id: 1,
        icon: Icon1,
        heading: "Financial Analyst"
    },
    {
        _id: 2,
        icon: Icon12,
        heading: "HR Manager"
    },
    {
        _id: 3,
        icon: Icon3,
        heading: "Database Administrator"
    },
    {
        _id: 4,
        icon: Icon4,
        heading: "Data Consultant"
    },
    {
        _id: 5,
        icon: Icon5,
        heading: "Project Manager"
    },
    {
        _id: 6,
        icon: Icon6,
        heading: "Data Visualisation Specialist"
    },
    {
        _id: 7,
        icon: Icon7,
        heading: "Financial Controller"
    },
    {
        _id: 8,
        icon: Icon8,
        heading: "Business Intelligence Analyst"
    },
    {
        _id: 9,
        icon: Icon9,
        heading: "Reporting Analyst"
    },
    {
        _id: 10,
        icon: Icon10,
        heading: "Market Research Researcher"
    },
    {
        _id: 11,
        icon: Icon11,
        heading: "Customer Insight Analyst"
    },
    {
        _id: 12,
        icon: Icon2,
        heading: "And many More..."
    },
]

function ProfileAfter({courseName}) {
    return (
        <div className='tw-pt-2 tw-pb-8'>
            <div className='container'>
                <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6`}>
                    <span>Profiles You Enter After Certified Excel & Power BI Modeler Using AI</span>
                </p>
                <div className='row'>
                    {
                        profileData?.map(item => {
                            return <div key={item._id} className={`col-12 col-md-6`}>
                                <ProfileCard icon={item.icon} heading={item.heading} />
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function ProfileCard({ icon, heading }) {
    return (
        <div className='tw-p-4'>
            <div className='row tw-border-[1px] tw-border-dotted tw-border-black tw-rounded-3xl'>
                <div className="col-12 col-lg-3 tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-h-[130px]' src={icon} alt="profilecard" width={130} /></div>
                <div className="col-12 col-lg-9 tw-flex tw-justify-center tw-items-center tw-my-3 tw-font-semibold">{heading}</div>
            </div>
        </div>
    )
}

export default ProfileAfter