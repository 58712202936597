import React, {useState} from 'react'
import ShowCounter from './ShowCounter'
import { Link } from 'react-router-dom';
import Toast from '../../../helpersComponents/Toast';
import { useFormik } from 'formik';
import { loginSchema } from '../../../formikSchema/schema';
import {useDispatch} from 'react-redux';
import {login, loginError} from '../../../redux/login/loginActions'

import serverAPI from '../../../utils/baseAPI'

import './Login.css';

function SignUp({ days }) {
  const loginDispatch= useDispatch();
  const THREE_DAYS_IN_MS = days * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const endOfOfferTime = NOW_IN_MS + THREE_DAYS_IN_MS;

  const [toast, setToast]= useState({isSuccess:'', message:''});
  const loginForm = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    validationSchema: loginSchema,
    onSubmit:(value, action)=>{
      serverAPI.post(`/api/login`, value)
      .then(e=>{
        setToast({isSuccess:true, message: e.data.msg})
        action.resetForm();
        loginDispatch(login());
      })
      .catch(e=>{
        setToast({isSuccess:false, message: e.response.data.msg});
        //console.log(e);
        loginDispatch(loginError(e.response.data.msg));
      }).finally(()=>{
        setTimeout(() => {
          setToast({isSuccess:'', message:''});
        }, 4000);
      })
    }
  })

  return (
    <div className="signup_set_bg">
      {(()=>{
        if(toast.isSuccess==="") return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message}/>
      })()}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 tw-my-20 tw-flex tw-justify-center">
            <div className="lg:tw-w-[400px] tw-rounded-2xl tw-bg-white">
              <div className="tw-py-6 tw-px-4">
                <div className='text-center tw-text-base tw-font-medium'>
                  Create Your <Link to="/signup" ><span className='tw-text-[#6440FB] tw-underline'>Free Account</span></Link>
                </div>
                <form onSubmit={loginForm.handleSubmit} className='tw-flex tw-justify-center tw-flex-col' >
                  <div className='tw-pt-4'>
                    <span className="tw-label">
                      <label htmlFor="email" className='tw-label-text'>Email Address</label>
                      {loginForm.touched.email && loginForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{loginForm.errors.email}</p> : <></>}
                    </span>
                    <input onChange={loginForm.handleChange} value={loginForm.values.email} name="email" required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  <div className='tw-pt-4'>
                    <span className="tw-label">
                      <label htmlFor="password" className='tw-label-text'>Password</label>
                      {loginForm.touched.password && loginForm.errors.password ? <p className='tw-text-xs tw-text-red-500'>{loginForm.errors.password}</p> : <></>}
                    </span>
                    <input onChange={loginForm.handleChange} value={loginForm.values.password} name="password" minLength={8} maxLength={20} required type="password" placeholder="********" className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  <div className="tw-py-2 ">
                    <Link to="/forgot-password">
                      <span className='hover:tw-text-black tw-cursor-pointer tw-text-xs tw-opacity-60 hover:tw-underline'>
                        Forgot your password?
                      </span>
                    </Link>
                  </div>
                  <div className="tw-pt-4">
                    <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'> Login </button>
                  </div>
                  <div className="tw-pt-2">
                    <span className=' tw-flex tw-justify-center tw-text-xs tw-opacity-60 '>By continuing, you accept our Terms of Use, our Privacy Policy and that your data is stored in the USA. You confirm you are at least 16 years old (13 if you are an authorized Classrooms user).</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-light">
            <div className="tw-mx-8 tw-flex tw-justify-center tw-h-full tw-flex-col">
              <span className='tw-text-base md:tw-text-xl lg:tw-text-2xl tw-flex tw-flex-col tw-font-semibold'>
                <span>Register Now and Get a 30%</span>
                <span className='-tw-mt-1'>Discount</span>
              </span>
              <span className='tw-mt-2 tw-text-xs tw-mb-7 lg:tw-mb-3'>Get Certified today and begin your career in Finance</span>
              <ShowCounter targetDate={endOfOfferTime} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp