import React from 'react'
import styles from './PastCard.module.css'


function PastCard({content="", subContent="", theme="", icon="", addPastCardStyle=""}) {
    // console.log('in past card')
  return (
    <div className={`tw-mt-8 md:tw-mt-10 tw-h-fit ${addPastCardStyle}`}> 
        <div className={theme==="dark" ? styles.theme_dark : styles.past_card}>
          <div className='row tw-h-full'>
            <div className='col-sm-9 tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-[20px] tw-h-full'>
              <p className={`tw-text-center tw-flex tw-justify-center tw-items-center tw-text-sm md:tw-text-base  ${theme==="dark"? 'tw-text-white' : `tw-text-[#C12CB4]`} `}>{content}</p>
              <p className={`tw-text-center tw-flex tw-justify-center tw-items-center tw-text-sm md:tw-text-base  ${theme==="dark"? 'tw-text-white' : `tw-text-[#5B1B73]`} `}>{subContent}</p>
            </div>
            <div className='col-sm-3 tw-p-0 sm:tw-flex tw-justify-end tw-hidden'>
              <img src={icon} alt="icon" width={120} className={styles.icon}/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default React.memo(PastCard);