import serverAPI from '../../utils/baseAPI';
import {ACTIVE_EVENT, NOT_ACTIVE_EVENT, ALL_EVENT, CLEAR_ALL} from './eventTypes';

export const getActiveEvent= ()=>{
    //console.log('running get active event')
    return (dispatch) =>{
        serverAPI.get(`/api/event/status/active`)
        .then(e=>{
            dispatch(setActiveEvent(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setActiveEvent=(data)=>{
    return {
        type: ACTIVE_EVENT,
        payload: data
    }
}
export const getNotActiveEvent= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/event/status/not-active`)
        .then(e=>{
            dispatch(setNotActiveEvent(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setNotActiveEvent=(data)=>{
    return {
        type: NOT_ACTIVE_EVENT,
        payload: data
    }
}

export const getAllEvent= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/event`)
        .then(e=>{
            dispatch(setAllEvent(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setAllEvent=(data)=>{
    return {
        type: ALL_EVENT,
        payload: data
    }
}

export const EventLogOutClear=()=>{
    return {
        type: CLEAR_ALL
    }
}