import {ACTIVE_EVENT, NOT_ACTIVE_EVENT, ALL_EVENT, CLEAR_ALL} from './eventTypes';
import { combineReducers } from 'redux';

const activeEventReducer= (state= [], action)=>{
    switch (action.type) {
        case ACTIVE_EVENT: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}

const notActiveEventReducer= (state= [], action)=>{
    switch (action.type) {
        case NOT_ACTIVE_EVENT: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}
const allEventReducer= (state= [], action)=>{
    switch (action.type) {
        case ALL_EVENT: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}

const eventReducer= combineReducers({
    active: activeEventReducer, 
    notActive: notActiveEventReducer, 
    all: allEventReducer
});

export default eventReducer;