import React, {useEffect, useState} from 'react'
import './Login.css'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import Toast from '../../helpersComponents/Toast';

import { useFormik } from 'formik';
import { loginSchema } from '../../formikSchema/schema';

import {useDispatch} from 'react-redux'
import {login, loginError} from '../../redux/login/loginActions'
import serverAPI from '../../utils/baseAPI'
import { useLocation, useNavigate } from 'react-router-dom';

import loginImage from './assets/4707071.jpg'

function Login() {
  const location = useLocation();
  const navigate= useNavigate();
  const whereToRedirect = location?.search?.split("?redirectTo=")[1] || "/course";
  const authMessage= location?.search?.split("?authMessage=")[1]?.split("%20").join(" ");

  const [toast, setToast]= useState({isSuccess:'', message:''});
  // console.log(whereToRedirect);
  useState(()=>{
    if(whereToRedirect) 
      setToast({isSuccess:false, message: authMessage})
      setTimeout(() => {
        setToast({isSuccess:'', message:''});
      }, 4000);
  }, [whereToRedirect])

  const loginDispatch= useDispatch();

  useEffect(()=>{
    //console.log('cookies ', window.document.cookie);  
  })

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    validationSchema: loginSchema,
    onSubmit:(value, action)=>{
      serverAPI.post(`/api/login`, value)
      .then(e=>{
        setToast({isSuccess:true, message: e.data.msg})
        action.resetForm();
        loginDispatch(login());
        navigate(whereToRedirect);
      })
      .catch(e=>{
        setToast({isSuccess:false, message: e.response.data.msg});
        loginDispatch(loginError(e.response.data.msg));
        // console.log(e.response.data.msg)
      }).finally(()=>{
        setTimeout(() => {
          setToast({isSuccess:'', message:''});
        }, 4000);
      })
    }
  })
  return (
    <div className=' tw-mt-16 '>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <meta name="description" content="Login into your finocontrol account" />
      </Helmet>
      {(()=>{
        if(toast.isSuccess==="") return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message}/>
      })()}
      <div className="row tw-min-h-[93vh]">
        {/* <div className="tw-h-auto col-md-5 bg_color tw-hidden md:tw-flex tw-justify-center tw-items-center">
          <img src={leftImage} alt="left-img" className='img-fluid' />
        </div> */}
        <div className="col tw-flex tw-justify-center tw-items-center">
          <div className="tw-my-5 tw-w-[90%] lg:tw-w-[450px] tw-rounded-2xl tw-bg-white">
            <div className="tw-py-10 tw-px-10 drop_shadow tw-rounded-lg ">
              <span className='tw-text-3xl tw-font-bold'>
                <span>Login</span>
              </span>
              <div className=' tw-flex tw-justify-center tw-items-center'>
                <img src={loginImage} className='img-fluid' alt="login" width={275} />  
              </div>
              <div className='tw-label-text tw-mt-5'>
                <span><span className='tw-opacity-60'>Don't have an account yet?</span> <Link to="/signup"><span className='tw-text-[#6440FB] tw-cursor-pointer hover:tw-underline'> Sign up for free</span></Link></span>
              </div>
              <form onSubmit={loginForm.handleSubmit} className='tw-flex tw-justify-center tw-flex-col' >
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500'>*</span></label>
                    {loginForm.touched.email && loginForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{loginForm.errors.email}</p> : <></>}
                  </span>
                  <input onChange={loginForm.handleChange} value={loginForm.values.email} name="email" required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                </div>
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="password" className='tw-label-text'>Password <span className='tw-text-red-500'>*</span></label>
                    {loginForm.touched.password && loginForm.errors.password ? <p className='tw-text-xs tw-text-red-500'>{loginForm.errors.password}</p> : <></>}
                  </span>
                  <input onChange={loginForm.handleChange} value={loginForm.values.password} name="password" minLength={8} maxLength={20} required type="password" placeholder="********" className="tw-input tw-input-bordered tw-w-full " />
                </div>

                <div className="tw-pt-1">
                  <Link to="/forgot-password">
                    <span className='tw-cursor-pointer tw-text-xs tw-opacity-60 hover:tw-text-black hover:tw-underline'>
                      Forgot your password?
                    </span>
                  </Link>
                </div>

                <div className="tw-pt-2">
                  <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
                    <span>Login</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login