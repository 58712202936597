import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import {Link} from 'react-router-dom'
import { Autoplay } from "swiper";
import 'swiper/css/navigation';
import "swiper/css";

import './TrustedBy.css'
// import leftImg from './assets/images/imagesmid-left.svg'
import correctSign from './assets/images/correctSign.svg'
import InstructorCard from './InstructorCard/InstructorCard';

import {instructorsData} from './InstructorCard/InstructorData.js'

function TrustedBy() {
  return (
    <div className="tw-my-16">
      <div className='trusted_set_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <div className='tw-w-full tw-h-full col-lg-6 col-12 tw-justify-center tw-flex-col lg:tw-my-12 tw-mt-12 tw-mb-10'>
                <Swiper
                  loop={true}
                  speed={1200}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  pagination={true}
                  modules={[Autoplay]}
                >
                  {instructorsData.map(oneInstructor => {
                    return <SwiperSlide key={oneInstructor?._id} style={{display:'flex', justifyContent:"center"}} className=''>
                      <InstructorCard instructor= {oneInstructor} />
                    </SwiperSlide>
                  })}

                  <div className='tw-flex tw-justify-center tw-mt-3'>
                    <SwiperButtonPrev>
                      <span className='tw-pr-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &larr; </span>
                    </SwiperButtonPrev>
                    <SwiperButtonNext>
                      <span className='tw-pl-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &rarr;</span>
                    </SwiperButtonNext>
                  </div>
                </Swiper>
                {/* <img loading="eager" src={leftImg} className="img-fluid" alt="left_img" /> */}
              </div>
            </div>
            <div className='col-lg-6 col-12 tw-pl-2 tw-text-xs lg:tw-text-sm tw-mb-10 lg:tw-my-10'>
              <p className='tw-mb-6 md:tw-my-5 tw-text-base md:tw-text-lg lg:tw-text-xl self_main_content_font tw-flex tw-flex-col '>
                Trusted by <br />
                World's Leading Investment Bankers
              </p>

              <p className='tw-opacity-60'>We serve a broad range of companies, organizations, and institutions through our Training, Consulting, execution and advisory capabilities.</p>
              <div className='tw-flex tw-flex-col tw-my-8'>
                <span className='tw-flex tw-items-center'><img loading="eager" className='tw-pr-2 tw-py-2' src={correctSign} alt="correct sign" /> Dynamic & Intensive Corporate training </span>
                <span className='tw-flex tw-items-center'><img loading="eager" className='tw-pr-2 tw-py-2' src={correctSign} alt="correct sign" /> Financial Modeling </span>
                <span className='tw-flex tw-items-center'><img loading="eager" className='tw-pr-2 tw-py-2' src={correctSign} alt="correct sign" /> Global Investment Research </span>
                <span className='tw-flex tw-items-center'><img loading="eager" className='tw-pr-2 tw-py-2' src={correctSign} alt="correct sign" /> Private Wealth Planning </span>
              </div>

              <Link to="/course"><button className='tw-btn tw-capitalize'>See More</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <button onClick={() => swiper.slideNext()}>{children}</button>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <button onClick={() => swiper.slidePrev()}>{children}</button>;
};


export default TrustedBy