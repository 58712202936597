import React from 'react'
import PastCard from './PastCard/PastCard';

import helloIcon from './assets/helloIcon.svg'

import pastCardIcon1 from './assets/past_card_icon1.png'
import pastCardIcon2 from './assets/past_card_icon2.png'
import pastCardIcon3 from './assets/past_card_icon3.png'
import pastCardIcon4 from './assets/past_card_icon4.png'
import pastCardIcon5 from './assets/past_card_icon5.png'
import pastCardIcon6 from './assets/past_card_icon6.png'
import pastCardIcon7 from './assets/past_card_icon7.png'
import pastCardIcon8 from './assets/past_card_icon8.png'

function PastExperience() {
  return (
    <div className='tw-mt-8 container'>
        <div className='tw-flex tw-justify-center tw-items-center tw-text-sm lg:tw-text-lg xl:tw-text-xl tw-font-bold tw-text-[#C12CB4]'><img src={helloIcon} alt='hello_icon' width={40} />&nbsp;If you have come until here, then you must be a </div>
        <div className='row'> 
          <div className='col-12 col-md-8 col-lg-8'>
            <PastCard content="Students or Graduates aiming for" subContent="a career in corporate industry" theme="" icon={pastCardIcon1} />
            <PastCard content="B.Tech/ BCOM/ BBA/ BA looking for a" subContent="Global Certification" theme="dark" icon={pastCardIcon2} />
            <PastCard content="MA/ MCA/ M.sc/ MBA Student " subContent="looking to be Job-Ready" theme="" icon={pastCardIcon3} />
          </div>
          <div className='offset-md-4 col-12 col-md-8 col-lg-8'>
            <PastCard content="An Individual with 2-8 years of career gap" subContent="" theme="dark" icon={pastCardIcon4} />
          </div>
          <div className="past_career_women_bg">
            <div className='offset-md-4 col-12 col-md-8 col-lg-8 '>
              <PastCard content="A Fresher looking to gain corporate Exposure," subContent="Internship & Get Certified!" theme="" icon={pastCardIcon5} />
              <PastCard content="A Working Professional with 2-20 years of " subContent="experience aiming to make a Career Transition" theme="dark" icon={pastCardIcon6} />
              <PastCard content="A Professional looking to learn Advanced " subContent="& Futuristic skills for higher growth & Promotion" theme="" icon={pastCardIcon7} />
              <PastCard content="A Founder willing to Master Finance & " subContent="make business Projections" theme="dark" icon={pastCardIcon8} addPastCardStyle="tw-mb-6" />
            </div>
          </div>
        </div>
    </div>
  )
}

export default PastExperience