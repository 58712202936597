import serverAPI from '../../utils/baseAPI';
import {ALL_BLOG, CLEAR_ALL} from './blogTypes';

export const getAllBlog= ()=>{
    ////console.log('running get blog')
    return (dispatch) =>{
        serverAPI.get(`/api/blog`)
        .then(e=>{
            let sortedBlog= e.data.data;
            sortedBlog= sortedBlog.sort((first, second)=>{
                let a= new Date(first.createdAt).getTime();
                let b= new Date(second.createdAt).getTime();
                return b-a;
            })
            //console.log('sorted one', sortedBlog);
            dispatch(setAllBlog(sortedBlog));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setAllBlog=(data)=>{
    return {
        type: ALL_BLOG,
        payload: data
    }
}

export const blogLogOutClear=()=>{
    return {
        type: CLEAR_ALL
    }
}