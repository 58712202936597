import React, { useState, useEffect } from 'react'
import './SignUp.css'
import {Helmet} from "react-helmet";
import { Link, /* useNavigate */ } from 'react-router-dom';
import { useFormik } from 'formik';
import { signUpSchema } from '../../formikSchema/schema';
import Toast from '../../helpersComponents/Toast';
import serverAPI from '../../utils/baseAPI'

function SignUp() {
  // const navigate= useNavigate()
  const [toast, setToast]= useState({isSuccess:'', message:''});
  const signUpForm = useFormik({
    validationSchema: signUpSchema,
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',

      countryResiding: '',
      stateResiding: 'state',
      contactNumber: '',
      profession: '',

      company: '',
      college:'',
      dob: '',
      course: '',
      
      graduationYear: ''
    },
    onSubmit: (value, action) => {
      serverAPI.post(`/api/signup`, value)
      .then(e=>{
        //console.log(e);
        setToast({isSuccess:true, message: e.data.msg});
        action.resetForm();
      })
      .catch(e=>{
        setToast({isSuccess:false, message: e.response.data.msg});
        //console.log(e);
      }).finally(()=>{
        setTimeout(() => {
        setToast({isSuccess:'', message: ''});
        }, 4000);
      })
    }
  });
  
  const [allCountry, setAllCountry] = useState([{ name: "Not able to fetch data", emoji: "NOT" }]);
  const [countryStates, setSelectedStates] = useState([{ name: "Not able to fetch data", id: 1 }]);

  useEffect(() => {
    serverAPI.get(`/api/form-data/all-country`)
      .then(e => {
        //console.log(e.data.data[0]);
        setAllCountry(e.data.data[0]);
      })
      .catch(e => {
        // console.log('error in fetching country data');
      })
  }, [])

  useEffect(() => {
    serverAPI.get(`/api/form-data/${signUpForm.values.countryResiding}/states`)
      .then(e => {
        setSelectedStates(e.data.data[0]);
      })
      .catch(e => {
        // console.log('error in fetching state data');
      })
  }, [signUpForm.values.countryResiding])

  return (
    <div className=' tw-mt-16 '>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up</title>
        <meta name="description" content="Join Finocontrol" />
      </Helmet>
      {(()=>{
        if(toast.isSuccess==="") return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message}/>
      })()}
      <div className="row tw-min-h-[93vh]">
        {/* <div className="tw-h-auto col-md-5 bg_color tw-hidden md:tw-flex tw-justify-center tw-items-center">
          <img src={leftImage} alt="left-img" className='img-fluid' />
        </div> */}
        <div className="col tw-flex tw-justify-center tw-items-center">
          <div className="tw-my-5 tw-w-[90%] lg:tw-w-[450px] tw-rounded-2xl tw-bg-white">
            <div className="tw-py-10 tw-px-10 drop_shadow tw-rounded-lg ">
              <span className='tw-text-3xl tw-font-bold'>
                <span>SignUp</span>
              </span>
              <div className='tw-label-text tw-mt-5'>
                <span><span className='tw-opacity-60'>Already have an account.</span> <Link to='/login'><span className='tw-text-[#6440FB] tw-cursor-pointer hover:tw-underline'> Sign in here</span></Link> </span>
              </div>
              <form onSubmit={signUpForm.handleSubmit} className='tw-flex tw-justify-center tw-flex-col' >
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="name" className='tw-label-text'>Name </label>
                    {signUpForm.touched.name && signUpForm.errors.name ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.name}</p> : <></>}
                  </span>
                  <input name="name" value={signUpForm.values.name} onChange={signUpForm.handleChange} required type="text" placeholder="Name" className="tw-input tw-input-bordered tw-w-full " />
                </div>

                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="email" className='tw-label-text'>Email Address </label>
                    {signUpForm.touched.email && signUpForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.email}</p> : <></>}
                  </span>
                  <input name="email" value={signUpForm.values.email} onChange={signUpForm.handleChange} required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                </div>

                <div>

                  <div className="row">
                    <div className="col">
                      <div className='tw-pt-1'>
                        <span className="tw-label">
                          <label htmlFor="password" className='tw-label-text'>Password </label>
                        </span>
                        <input name="password" value={signUpForm.values.password} className='tw-input tw-input-bordered tw-w-full' minLength={8} maxLength={20} onChange={signUpForm.handleChange} required type="password" placeholder="********" />
                      </div>
                    </div>
                    <div className="col">
                      <div className='tw-pt-1'>
                        <span className="tw-label">
                          <label htmlFor="confirm_password" className='tw-label-text'>Confirm Password </label>
                        </span>
                        <input name="confirmPassword" value={signUpForm.values.confirmPassword} className='tw-input tw-input-bordered tw-w-full' minLength={8} maxLength={20} onChange={signUpForm.handleChange} required type="password" placeholder="********" />
                      </div>
                    </div>
                  </div>
                  {(()=>{
                    if(signUpForm.errors.password) return <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.password}</p>;
                    else if(signUpForm.errors.confirmPassword) return <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.confirmPassword}</p> 
                  })()}
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="Country Residing" className='tw-label-text'>Country Residing </label>
                      {signUpForm.touched.countryResiding && signUpForm.errors.countryResiding ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.countryResiding}</p> : <></>}
                    </span>
                    <select name="countryResiding" value={signUpForm.values.countryResiding} required onChange={signUpForm.handleChange} className="tw-input tw-input-bordered tw-w-full ">
                      <option disabled selected value="" >Country</option>
                      {
                        allCountry.map(item => (
                          <option key={item.name} value={item.name}>{item.name} {item.emoji}</option>
                        ))
                      }
                    </select>
                  </div>
                  { signUpForm.values.countryResiding && 
                    <div className='tw-pt-1'>
                      <span className="tw-label">
                        <label htmlFor="State Residing" className='tw-label-text'>State Residing </label>
                        {signUpForm.touched.stateResiding && signUpForm.errors.stateResiding ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.stateResiding}</p> : <></>}
                      </span>
                      <select name='stateResiding' value={signUpForm.values.stateResiding} onChange={signUpForm.handleChange} required className="tw-input tw-input-bordered tw-w-full ">
                        <option value="" disabled={countryStates.length === 0 ? false : true} selected>State</option>
                        {
                          countryStates.map(item => (
                            <option key={item.id} value={item.name}>{item.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  }
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="contact number" className='tw-label-text'>Contact Number </label>
                      {signUpForm.touched.contactNumber && signUpForm.errors.contactNumber ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.contactNumber}</p> : <></>}
                    </span>
                    <input name="contactNumber" value={signUpForm.values.contactNumber} onChange={signUpForm.handleChange} required type="number" min={10000_00000} max={99999_99999} placeholder={`9900000000`} className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  {/* 
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="Profession" className='tw-label-text'>Profession </label>
                      {signUpForm.errors.profession ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.profession}</p> : <></>}
                    </span>
                    <input name="profession" value={signUpForm.values.profession} onChange={signUpForm.handleChange} required type="text" placeholder="Profession" className="tw-input tw-input-bordered tw-w-full " />
                  </div> 
                  */}
                  
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="Profession" className='tw-label-text'>Profession </label>
                      {signUpForm.touched.profession && signUpForm.errors.profession ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.profession}</p> : <></>}
                    </span>
                    <select name='profession' value={signUpForm.values.profession} onChange={signUpForm.handleChange} required className="tw-input tw-input-bordered tw-w-full ">
                      <option value="" disabled selected>Your Profession</option>
                      <option value='working'> Working</option>
                      <option value='student'> Student</option>
                      <option value='other'> Other</option>
                    </select>
                  </div>
                  
                  {(signUpForm.values.profession==="working" || signUpForm.values.profession==="other") && 
                    <div className='tw-pt-1'>
                      <span className="tw-label">
                        <label htmlFor="company" className='tw-label-text'>Company</label>
                        {signUpForm.touched.company && signUpForm.errors.company ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.company}</p> : <></>}
                      </span>
                      <input required name="company" value={signUpForm.values.company} onChange={signUpForm.handleChange} type="text" placeholder="Company" className="tw-input tw-input-bordered tw-w-full " />
                    </div>
                  }
                  {(signUpForm.values.profession==="student" || signUpForm.values.profession==="other") &&
                    <div className='tw-pt-1'>
                      <span className="tw-label">
                        <label htmlFor="College" className='tw-label-text'>College</label>
                        {signUpForm.touched.college && signUpForm.errors.college ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.college}</p> : <></>}
                      </span>
                      <input required name="college" value={signUpForm.values.college} onChange={signUpForm.handleChange} type="text" placeholder="College" className="tw-input tw-input-bordered tw-w-full " />
                    </div>
                  } 
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="DOB" className='tw-label-text'>DOB </label>
                      {signUpForm.touched.dob && signUpForm.errors.dob ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.dob}</p> : <></>}
                    </span>
                    <input name="dob" value={signUpForm.values.dob} onChange={signUpForm.handleChange} type="date" placeholder="DOB" min="1950-01-01" max={new Date().toISOString().split('T')[0]} className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="Course" className='tw-label-text'>Course Enrolled</label>
                      {signUpForm.touched.course && signUpForm.errors.course ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.course}</p> : <></>}
                    </span>
                    <input name="course" value={signUpForm.values.course} onChange={signUpForm.handleChange} type="text" placeholder="Course Enrolled" className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  <div className='tw-pt-1'>
                    <span className="tw-label">
                      <label htmlFor="Graduating in" className='tw-label-text'>Graduation Year </label>
                      {signUpForm.touched.graduationYear && signUpForm.errors.graduationYear ? <p className='tw-text-xs tw-text-red-500'>{signUpForm.errors.graduationYear}</p> : <></>}
                    </span>
                    <input name="graduationYear" value={signUpForm.values.graduationYear} onChange={signUpForm.handleChange} type="number" required min={1900} max={2099} placeholder="2023" className="tw-input tw-input-bordered tw-w-full " />
                  </div>

                </div>

                <div className="tw-pt-4">
                  <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
                    <span>begin your journey with us</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp