import React from 'react';
import practicalExposure from './assets/practical_exposure.png'

function Guaranteed() {
  return (
    <div className='tw-pt-2 tw-pb-8'>
        <div className='container'>
            <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-[#FFC700]`}>
                <span className='tw-text-[#5B1B73CC]'>Land in</span>
                <span className='tw-text-[#C12CB4] tw-mx-1'>a Guaranteed Internship</span>
                <span className='tw-text-[#5B1B73CC]'>With Us</span>
            </p>
            <div className='tw-flex tw-justify-between tw-flex-wrap tw-flex-gap-3'>
              <p>
              Stand a chance to get an internship at
              Finocontrol as a "Financial Analyst" 
              or a
              "Research Analyst", which will hone your skills,
              provide you a platform 
              to unveil your strengths,
              build up your knowledge and gain corporate
              exposure. Remember, companies value
              experience just as much
              as certification!
              </p>
              <span className='tw-flex tw-justify-center tw-w-full '>
                <img className='tw-mt-3 img-fluid sm:tw-max-w-md md:tw-max-w-lg lg:tw-max-w-xl xl:tw-max-w-2xl' src={practicalExposure} alt="practicalexposure icon" />
              </span>
            </div>
        </div>
    </div>
  )
}

export default Guaranteed