import React from 'react'
import './SpecializationCard.css'

function SpecializationCard({props}) {
  const {id, title, desc, img}= props;
  return (
    <div style={{width:'fit-content'}} className="tw-py-3"> 
      <div className='style_card tw-rounded-lg tw-mx-6 tw-bg-[#EEF2F6] hover:tw-bg-[#1A064F] hover:tw-text-white smooth' style={{width:'190px', height:'240px'}}>
        <div className='tw-flex tw-flex-col tw-h-[100%] tw-items-center tw-justify-evenly'>
        <span><img loading="eager" src={img} alt={id} /></span> 
        <span className='tw-text-sm lg:tw-text-md'> {title} <br/> <span className='tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60'></span></span>
        </div>
      </div>
  </div>
  )
}

export default SpecializationCard