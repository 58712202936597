import React from 'react'
import PersueCard from './PersueCard/PersueCard'
function WhyShouldYouPersue({courseName}) {
  return (
    <div className='tw-bg-[#20035E] tw-py-5'>
        <div className='container'>
            <p className='tw-text-center tw-font-medium tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-mt-0 tw-mb-5 tw-text-white'>
              {/* Why Should You Pursue Certification in {courseName} From Finocontrol ? */}
              Why should you pursue a {courseName} Cohort from Finocontrol? 
            </p>
            <PersueCard color={"white"}/>
        </div>
    </div>
  )
}

export default WhyShouldYouPersue