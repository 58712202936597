import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";
import Toast from '../../helpersComponents/Toast';

let serverURL= 'http://localhost:8080';
const isProd = process.env.REACT_APP_NODE_ENV || false;
if(isProd) {
  serverURL='/'
  if(process.env.REACT_APP_DEV_SERVER_URL) {
    serverURL=process.env.REACT_APP_DEV_SERVER_URL;
  }
}

function VerifyCertificate() {
  const [certId, setCertId]= useState('');
  const [toast, setToast]= useState({isSuccess:'', message:''});

  function getCertificate(e) {
    e.preventDefault();
    if(!certId) {
      setToast({isSuccess:false, message: "Empty Certificate"});
      setTimeout(()=>{
        setToast({isSuccess:'', message: ""});
      },3000);
      return;
    }
    // if(!certId.match(/^(FC|fc)-\d{2,}-\S+-\d+$/)) {
    //   setToast({isSuccess:false, message: "Not a valid certificate id"});
    //   setTimeout(() => {
    //     setToast({isSuccess:'', message: ""});
    //   }, 3000);
    //   return;
    // }
    
    window.open(`${serverURL}/certificate/getCertificate/${certId}`, '__blank');
  }
 

  return (
    <div className='container tw-mb-16 tw-mt-24'>
      {(()=>{
        if(toast.isSuccess==="") return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message}/>
      })()}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verify and download certificate</title>
        <meta name="description" content="Verify and download certificate" />
      </Helmet>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Verify and download your certificate</div>
          <div className='tw-flex tw-justify-between tw-flex-wrap'>
            <div className='tw-text-xs lg:tw-text-sm tw-opacity-60 '>
              A certificate formally verifies achievements, qualifications, and training completions.
            </div>
          </div>
        </div>
       
      </div>

      
      <div className="container row tw-min-h-[50vh] tw-flex tw-justify-center tw-items-center">
        <div className="row tw-h-fit tw-justify-center">
          <span className='tw-font-bold tw-my-4 lg:tw-text-lg tw-text-xs'>Enter the <span style={{color:'#C12CB4'}}>Unique Certificate Number</span> Below to Verify your Certificate of Completion</span>
          <div className='tw-max-w-full md:tw-max-w-[80%] tw-flex tw-justify-center tw-p-2 md:tw-p-4 tw-bg-[#F7F5FA] tw-rounded-xl'>
            <input type="text" class="tw-w-full tw-md-w-[70%] tw-block tw-w-full tw-p-1 md:tw-p-4 tw-text-xs md:tw-text-sm tw-text-gray-900 tw-border tw-border-gray-300 tw-bg-gray-50 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="Enter Certificate Number" required style={{borderRadius:"8px 0px 0px 8px"}} onChange={(e)=>setCertId(e.target.value)}/>
            <button type="submit" onClick={getCertificate} class="tw-w-[30%] tw-btn-primary tw-text-white tw-right-2.5 tw-bottom-2.5 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-text-xs md:tw-text-sm tw-px-2 md:tw-px-4 tw-py-1 md:tw-py-2" style={{borderRadius:"0px 8px 8px 0px"}}>
              Verify Certificate
            </button>
          </div>
        </div>

        {/* <form onSubmit={getCertificate}>
          <input required type="text" onChange={(e)=>setCertId(e.target.value)} placeholder="Certificate Id" className="tw-input tw-input-bordered tw-w-full " />
          <div className="tw-pt-4">
            <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
              <span>Submit</span>
            </button>
          </div>
        </form> */}
        
      </div>
    </div>
  )
}

export default VerifyCertificate