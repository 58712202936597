import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, EffectCoverflow } from "swiper";
import 'swiper/css/navigation';
import "swiper/css";
import styles from "./inTheNews.module.css"

const testimonialData = [
    {
        _id: 1,
        img: require('./assets/img1.png'),
        content: 'How Finocontrol Is Transforming The Lives of College students, Middle class and Retail Investors During Recession...',
        link: 'https://www.influencive.com/how-finocontrol-is-transforming-the-lives-of-college-students-middle-class-and-retail-investors-during-recession/'
    },
    {
        _id: 2,
        img: require('./assets/img2.png'),
        content: 'In this Competitive Industry, Finocontrol is building Future Investment Bankers',
        link: 'https://business.republicnewsindia.com/in-this-competitive-industry-finocontrol-is-building-future-investment-bankers/'
    },
    {
        _id: 3,
        img: require('./assets/img3.png'),
        content: 'How in post covid world an Indian start up, Finocontrol became the fastest growing fin-tech platform?',
        link: 'https://disruptmagazine.com/how-in-post-covid-world-an-indian-start-up-finocontrol-became-the-fastest-growing-fin-tech-platform/'
    },
    {
        _id: 4,
        img: require('./assets/pinkstories.png'),
        content: 'Finocontrol sets the record as the company with more than 60% women workforce & hired more freshers over experienced professionals',
        link: 'https://pinkstories.in/finocontrol-sets-the-record-as-the-company-with-more-than-60-women-workforce-and-hired-more-freshers-over-experienced-professionals/'
    },
    {
        _id: 5,
        img: require('./assets/askHenry.png'),
        content: '10+ Best Financial Modeling Courses Online',
        link: 'https://www.henryharvin.com/blog/financial-modeling-courses-online/'
    },
    {
        _id: 6,
        img: require('./assets/kanak.png'),
        content: 'Transforming Careers And Businesses:Meet The Visionary Founder Of Finocontrol, EduFintech Startup',
        link: 'https://youtu.be/GffYkCBgkNs?si=iGtVyIg24eRaM52v'
    },
    {
        _id: 7,
        img: require('./assets/img1.png'),
        content: 'How Finocontrol Is Transforming The Lives of College students, Middle class and Retail Investors During Recession...',
        link: 'https://www.influencive.com/how-finocontrol-is-transforming-the-lives-of-college-students-middle-class-and-retail-investors-during-recession/'
    },
    {
        _id: 8,
        img: require('./assets/img2.png'),
        content: 'In this Competitive Industry, Finocontrol is building Future Investment Bankers',
        link: 'https://business.republicnewsindia.com/in-this-competitive-industry-finocontrol-is-building-future-investment-bankers/'
    },
    {
        _id: 9,
        img: require('./assets/img3.png'),
        content: 'How in post covid world an Indian start up, Finocontrol became the fastest growing fin-tech platform?',
        link: 'https://disruptmagazine.com/how-in-post-covid-world-an-indian-start-up-finocontrol-became-the-fastest-growing-fin-tech-platform/'
    },
    {
        _id: 10,
        img: require('./assets/pinkstories.png'),
        content: 'Finocontrol sets the record as the company with more than 60% women workforce & hired more freshers over experienced professionals',
        link: 'https://pinkstories.in/finocontrol-sets-the-record-as-the-company-with-more-than-60-women-workforce-and-hired-more-freshers-over-experienced-professionals/'
    },
    {
        _id: 11,
        img: require('./assets/askHenry.png'),
        content: '10+ Best Financial Modeling Courses Online',
        link: 'https://www.henryharvin.com/blog/financial-modeling-courses-online/'
    },
    {
        _id: 12,
        img: require('./assets/kanak.png'),
        content: 'Transforming Careers And Businesses:Meet The Visionary Founder Of Finocontrol, EduFintech Startup',
        link: 'https://youtu.be/GffYkCBgkNs?si=iGtVyIg24eRaM52v'
    },
   
]


function InTheNews() {
    return (
        <div className='tw-bg-black tw-pt-5 tw-pb-5'>
            <p className='tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-white'>In The News</p>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                loop={true}
                // coverflowEffect={{
                //     rotate: 0,
                //     stretch: 0,
                //     depth: 323,
                //     modifier: 3,
                //     slideShadows: true,
                // }}

                breakpoints={{
                    950:{
                      slidesPerView:3,
                    },
                    750: {
                        slidesPerView: 2,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    0:{
                      slidesPerView: 1,
                      spaceBetween: 30,
                      coverflowEffect: {
                        depth:0
                      },
                    }
                }}
                speed={1200}
                modules={[EffectCoverflow, Autoplay]}
                // modules={[Autoplay]}

                className="container tw-py-5"
            >
                {testimonialData?.map(item => {
                    return <>
                        <SwiperSlide key={item._id}>
                            {/* <TestimonialCard cardData={item} /> */}
                            <InTheNewsCard img={item.img} content={item.content} link={item.link}/>
                        </SwiperSlide>
                        
                    </>
                })}
            </Swiper>
        </div>
    )
}

// function TestimonialCard({ cardData }) {
//     return (
//         <div className='lg:tw-max-w-[400px] tw-mx-8 lg:tw-mx-5 md:tw-mx-0 tw-rounded-2xl tw-flex tw-justify-between tw-flex-col tw-py-3 tw-px-2 lg:tw-py-6 lg:tw-px-10 tw-h-[330px] lg:tw-h-[400px] tw-bg-white'>
//             <h2 className='tw-card-title ' style={{ color: '#6440FB', textDecorationThickness: '0.25rem' }}>Our Happy Trainees</h2>
//             <p className="tw-my-3 tw-flex tw-justify-center tw-items-center tw-p-0 tw-m-0 tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-80 tw-h-fit">
//                 {cardData.desc}
//             </p>
//             <div className='tw-divider tw-mt-0 tw-p-0' />
//             <div className='tw-my-3 tw-flex -tw-mt-4'>
//                 <div className="tw-avatar">
//                     <div className="tw-w-20 tw-rounded-full ring ring-primary tw-ring-offset-base-100 tw-ring-offset-2">
//                         <img src={cardData.img} alt="person" />
//                     </div>
//                 </div>
//                 <div className='tw-flex tw-flex-col tw-justify-center tw-ml-4'>
//                     <span>{cardData.name}</span>
//                     <span className='tw-text-xs lg:tw-text-sm tw-opacity-70'>{cardData.batch}</span>
//                 </div>
//             </div>
//         </div>
//     )
// }


function InTheNewsCard({img, content, link}) {
    return (
        <a href={link} target='__blank' style={{ display: 'flex', justifyContent: 'center' }}>

            {/* {
                cardData?.map(({img}) => { */}
                    {/* // return ( */}
                        <span className={`${styles.main_card}`}>
                            <img src={img} alt="img" className={`${styles.img_radius} img-fluid `} />
                            <span className={`${styles.card_content} tw-text-sm tw-py-8 tw-px-4`}>
                                {content}
                                {/* Why does the world need another design agency? */}

                            </span>
                        </span>
                    {/* // )
                })
            } */}

            <br />
            <br />

        </a>
    )
}

export default InTheNews