import React from 'react'
import {Helmet} from "react-helmet";

function TermsAndConditions() {
  return (
    <div className='container tw-mb-16 tw-mt-24 tw-min-h-[85vh]'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Terms and conditions</title>
            <meta name="description" content="Terms and conditions" />
        </Helmet>
        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Terms Of Service Agreement</div>
        <div className='tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-capitalize'>LAST REVISION: 05-10-2021</div>
        <div className='tw-my-3 tw-text-sm lg:tw-text-base tw-opacity-75 tw-font-medium tw-capitalize'>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</div>

        <div className='tw-my-4'>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 ' style={{lineHeight:'1.50rem'}}>
                This Terms of Service Agreement (the "Agreement") governs your use of this website, www.finocontrol.com. Finocontrol Consultancy Services LLP offer of products for purchase on this Website, or your purchase of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. Finocontrol Consultancy Services LLP reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website. Finocontrol Consultancy Services LLP will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. Finocontrol Consultancy Services LLP encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Finocontrol Consultancy Services LLP for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website. If you would like to print this Agreement, please click the print button on your browser toolbar.
            </p>
        </div>
        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>I. Products</div>
        <div className='tw-my-4'>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{lineHeight:'1.50rem'}}>
                Terms of Offer. This Website offers for sale certain products (the "Products"). By placing an order for Products through this Website, you agree to the terms set forth in this Agreement.
            </p>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{lineHeight:'1.50rem'}}>
                <span className='tw-font-semibold'> Customer Solicitation: </span> Unless you notify our third party call center reps or direct Finocontrol Consultancy Services LLP sales reps, while they are calling you, of your desire to opt out from further direct company communications and solicitations, you are agreeing to continue to receive further emails and call solicitations Finocontrol Consultancy Services LLP and its designated in house or third party call team(s).
            </p>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{lineHeight:'1.50rem'}}>
                <span className='tw-font-semibold'>  Opt Out Procedure: </span>  We provide 3 easy ways to opt out of from future solicitations.
                <br/>
                <span>1. You may use the opt out link found in any email solicitation that you may receive.</span> <br/>
                <span>2. You may also choose to opt out, via sending your email address to: hello@finocontrol.com. </span> <br/>
                <span>3. You may send a written remove request to Chandrasekharpur, Bhubaneswar, Odisa, India.</span>
            </p>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{lineHeight:'1.50rem'}}>
                <span className='tw-font-semibold'> Proprietary Rights: </span>Finocontrol Consultancy Services LLP has proprietary rights and trade secrets in the Products. You may not copy, reproduce, resell or redistribute any Product manufactured and/or distributed by Finocontrol Consultancy Services LLP. Finocontrol Consultancy Services LLP also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images and other information.
            </p>
            <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{lineHeight:'1.50rem'}}>
                <span className='tw-font-semibold'>  Sales Tax: </span> If you purchase any Products, you will be responsible for paying any applicable sales tax.
            </p>
        </div>
        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>II. Website</div>
        <div className='tw-my-4'> 
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Content; Intellectual Property; Third Party Links. In addition to making Products available, this
                    Website also offers information and marketing materials. This Website also offers information, both
                    directly and through indirect links to third-party websites, about nutritional and dietary
                    supplements. Finocontrol Consultancy Services LLP does not always create the information offered on
                    this Website; instead the information is often gathered from other sources. To the extent that
                    Finocontrol Consultancy Services LLP does create the content on this Website, such content is
                    protected by intellectual property laws of the India, foreign nations, and international bodies.
                    Unauthorized use of the material may violate copyright, trademark, and/or other laws. You
                    acknowledge that your use of the content on this Website is for personal, noncommercial use. Any
                    links to third-party websites are provided solely as a convenience to you. Finocontrol Consultancy
                    Services LLP does not endorse the contents on any such third-party websites. Finocontrol Consultancy
                    Services LLP is not responsible for the content of or any damage that may result from your access to
                    or reliance on these third-party websites. If you link to third-party websites, you do so at your
                    own risk.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Use of Website; Finocontrol Consultancy Services LLP is not responsible for any damages resulting
                    from use of this website by anyone. You will not use the Website for illegal purposes. You will (1)
                    abide by all applicable local, state, national, and international laws and regulations in your use
                    of the Website (including laws regarding intellectual property), (2) not interfere with or disrupt
                    the use and enjoyment of the Website by other users, (3) not resell material on the Website, (4) not
                    engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other
                    type of unsolicited communication, and (5) not defame, harass, abuse, or disrupt other users of the
                    Website
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            License. By using this Website, you are granted a limited, non-exclusive, non-transferable right to
                    use the content and materials on the Website in connection with your normal, noncommercial, use of
                    the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such
                    content or information without express written authorization from Finocontrol Consultancy Services
                    LLP or the applicable third party (if third party content is at issue).
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Posting. By posting, storing, or transmitting any content on the Website, you hereby grant
                    Finocontrol Consultancy Services LLP a perpetual, worldwide, non-exclusive, royalty-free,
                    assignable, right and license to use, copy, display, perform, create derivative works from,
                    distribute, have distributed, transmit and assign such content in any form, in all media now known
                    or hereinafter created, anywhere in the world. Finocontrol Consultancy Services LLP does not have
                    the ability to control the nature of the user-generated content offered through the Website. You are
                    solely responsible for your interactions with other users of the Website and any content you post.
                    Finocontrol Consultancy Services LLP is not liable for any damage or harm resulting from any posts
                    by or interactions between users. Finocontrol Consultancy Services LLP reserves the right, but has
                    no obligation, to monitor interactions between and among users of the Website and to remove any
                    content Finocontrol Consultancy Services LLP deems objectionable, in MuscleUP Nutrition 's sole
                    discretion.
            </p>
            
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium'>III. Disclaimer of warranties</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE WEBSITE AND PRODUCTS ARE OFFERED
                    ON AN "AS IS" AND "AS AVAILABLE" BASIS. Finocontrol Consultancy Services LLP EXPRESSLY DISCLAIMS ALL
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO
                    THE PRODUCTS OR WEBSITE CONTENT, OR ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS.
                    ("PRODUCTS" INCLUDE TRIAL PRODUCTS.)
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Finocontrol Consultancy Services LLP MAKES NO
                    WARRANTY:
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.

            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE, RELIABLE, COMPLETE, OR
                    TIMELY. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL
                    CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL
                    BE CORRECTED.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS
                    MAY NOT APPLY TO YOU.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>IV. Limitation of Liability</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Finocontrol Consultancy Services LLP ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY,
                    OR OTHWERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND PRODUCTS AND/OR FOR ANY BREACH OF THIS
                    AGREEMENT IS SOLELY LIMITED TO THE AMOUNT YOU PAID, LESS SHIPPING AND HANDLING, FOR PRODUCTS
                    PURCHASED VIA THE WEBSITE.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Finocontrol Consultancy Services LLP WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT OR THE PRODUCTS IN ANY MANNER,
                    INCLUDING LIABILITIES RESULTING FROM (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR
                    PRODUCTS; (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY PRODUCTS PURCHASED OR
                    OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.
            </p>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
            
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>V. Indemnification</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            You will release, indemnify, defend and hold harmless Finocontrol Consultancy Services LLP, and any
                    of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns
                    from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and
                    expenses, of third parties relating to or arising out of (1) this Agreement or the breach of your
                    warranties, representations and obligations under this Agreement; (2) the Website content or your
                    use of the Website content; (3) the Products or your use of the Products (including Trial Products);
                    (4) any intellectual property or other proprietary right of any person or entity; (5) your violation
                    of any provision of this Agreement; or (6) any information or data you supplied to Finocontrol
                    Consultancy Services LLP. When Finocontrol Consultancy Services LLP is threatened with suit or sued
                    by a third party, Finocontrol Consultancy Services LLP may seek written assurances from you
                    concerning your promise to indemnify Finocontrol Consultancy Services LLP; your failure to provide
                    such assurances may be considered by Finocontrol Consultancy Services LLP to be a material breach of
                    this Agreement. Finocontrol Consultancy Services LLP will have the right to participate in any
                    defense by you of a third-party claim related to your use of any of the Website content or Products,
                    with counsel of Finocontrol Consultancy Services LLP choice at its expense. Finocontrol Consultancy
                    Services LLP will reasonably cooperate in any defense by you of a third-party claim at your request
                    and expense. You will have sole responsibility to defend Finocontrol Consultancy Services LLP
                    against any claim, but you must receive Finocontrol Consultancy Services LLP prior written consent
                    regarding any related settlement. The terms of this provision will survive any termination or
                    cancellation of this Agreement or your use of the Website or Products.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>VI. PRIVACY</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Finocontrol Consultancy Services LLP believes strongly in protecting user privacy and providing you
                    with notice of MuscleUP Nutrition 's use of data. Please refer to Finocontrol Consultancy Services
                    LLP privacy policy, incorporated by reference herein, that is posted on the Website.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>VII. Agreement to be bound</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            By using this Website or ordering Products, you acknowledge that you have read and agree to be bound
                    by this Agreement and all terms and conditions on this Website.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>VIII. General</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
            Force Majeure. Finocontrol Consultancy Services LLP will not be deemed in default hereunder or held
                    responsible for any cessation, interruption or delay in the performance of its obligations hereunder
                    due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict,
                    labor strike, lockout, or boycott. Cessation of Operation. Finocontrol Consultancy Services LLP may
                    at any time, in its sole discretion and without advance notice to you, cease operation of the
                    Website and distribution of the Products. Entire Agreement. This Agreement comprises the entire
                    agreement between you and Finocontrol Consultancy Services LLP and supersedes any prior agreements
                    pertaining to the subject matter contained herein. Effect of Waiver. The failure of Finocontrol
                    Consultancy Services LLP to exercise or enforce any right or provision of this Agreement will not
                    constitute a waiver of such right or provision. If any provision of this Agreement is found by a
                    court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
                    endeavor to give effect to the parties' intentions as reflected in the provision, and the other
                    provisions of this Agreement remain in full force and effect. Governing Law; India. This Website
                    originates from the [Bhubaneswar, Odisa]. This Agreement will be governed by the laws of the State
                    of Odisa without regard to its conflict of law principles to the contrary. Neither you nor
                    Finocontrol Consultancy Services LLP will commence or prosecute any suit, proceeding or claim to
                    enforce the provisions of this Agreement, to recover damages for breach of or default of this
                    Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located
                    in State of [Odisa]. By using this Website or ordering Products, you consent to the jurisdiction and
                    venue of such courts in connection with any action, suit, proceeding or claim arising under or by
                    reason of this Agreement. You hereby waive any right to trial by jury arising out of this Agreement
                    and any related documents. Statute of Limitation. You agree that regardless of any statute or law to
                    the contrary, any claim or cause of action arising out of or related to use of the Website or
                    Products or this Agreement must be filed within one (1) year after such claim or cause of action
                    arose or be forever barred. Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT, YOU
                    HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF A
                    CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION
                    WITH THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY. Termination. Finocontrol Consultancy Services LLP
                    reserves the right to terminate your access to the Website if it reasonably believes, in its sole
                    discretion, that you have breached any of the terms and conditions of this Agreement. Following
                    termination, you will not be permitted to use the Website and Finocontrol Consultancy Services LLP
                    may, in its sole discretion and without advance notice to you, cancel any outstanding orders for
                    Products. If your access to the Website is terminated, Finocontrol Consultancy Services LLP reserves
                    the right to exercise whatever means it deems necessary to prevent unauthorized access of the
                    Website. This Agreement will survive indefinitely unless and until Finocontrol Consultancy Services
                    LLP chooses, in its sole discretion and without advance to you, to terminate it. Domestic Use.
                    Finocontrol Consultancy Services LLP makes no representation that the Website or Products are
                    appropriate or available for use in locations outside India. Users who access the Website from
                    outside India do so at their own risk and initiative and must bear all responsibility for compliance
                    with any applicable local laws. Assignment. You may not assign your rights and obligations under
                    this Agreement to anyone. Finocontrol Consultancy Services LLP may assign its rights and obligations
                    under this Agreement in its sole discretion and without advance notice to you. BY USING THIS WEBSITE
                    OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF
                    THIS AGREEMENT.
            </p>
        </div>
    </div>
  )
}

export default TermsAndConditions