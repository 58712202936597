import axios from "axios";
const isProd = process.env.REACT_APP_NODE_ENV || false;
let serverURL= 'http://localhost:8080';

if(isProd) {
    serverURL='/'
}
const serverAPI= axios.create({
    withCredentials: true, 
    baseURL: serverURL
});

export default serverAPI;
