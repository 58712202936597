import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css/navigation';
import "swiper/css";

import TraineeCard from './TraineeCard/TraineeCard';
import styles from './RecentlyPlacedTrainee.module.css'

let acuityLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/acuty_n.png'
let c4dLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/c4d_n.png'
let coforgeLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/coforge_n.png'
let eyLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/ey_n.png'
let finocontrolLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/finocontrol_n.png'
let mmcLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/logo-mmc 1.png'
let peacefulLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/pp_n.png'
let sgLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/sg_n.png';
let dutscheBank=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/dnb_n.png';
let finlender_ico= 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/fin_n.png'
let bny_ico= "https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/bny_n.png";
let ntf_ico="https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/ntf_n.png";
let londom_seg_ico="https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/lse_n.png"

const traineeData= [
    
    {
    batch: "FMBV Batch",
    from: "Investment Bankzing Associate",
    hike: "80% Hike",
     
    _id: 1,
    name: "Shivani Gera",
    to: "Senior Analyst",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/shivanyGera.png',
    companyImg: eyLogo,
  },
  {
    batch: "FMBV Batch",
    from: "Youtube",
    hike: "42% Hike",
    
    _id: 2,
    name: "Michael Arun",
    to: "Investment Analyst",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/michaelArun.png',
    companyImg: peacefulLogo
  },
  {
    batch: "FMBV Batch",
    from: "Equity Research Analyst",
    hike: "Fresher",
    
    _id: 3,
    name: "Ruchi Pattanaik",
    to: "Investment Banking Analyst",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/ruchiPattanaik.png',
    companyImg: sgLogo
  },
  {
    batch: "FMBV Batch",
    from: "B.Com",
    hike: "Fresher",
    
    _id: 4,
    name: "T.S Nivas",
    to: "Investment Banking Analyst",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/tsnivas.png',
    companyImg: acuityLogo
  },
  {
    batch: "FMBV Batch",
    from: "M.Com",
    hike: "Fresher",
    
    _id: 5,
    name: "Shivanshu Dubey",
    to: "Investment Banking Analyst",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/shivamDubey.png',
    companyImg: sgLogo
  },
  {
    batch: "FMBV Batch",
    from: "B.Com",
    hike: "Fresher",
    
    _id: 6,
    name: "Sharel Monis",
    to: "Financial Consultant",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/sharelMonis.png',
    companyImg: finocontrolLogo
  },
  {
    batch: "FMBV Batch",
    from: "Senior Analyst",
    hike: "70% Hike",
    
    _id: 7,
    name: "Anish Sharma",
    to: "Assistant Manager",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/anishSharma.png',
    companyImg: eyLogo
  },
//   {
//     batch: "FMBV Batch",
//     from: "Investment Bankzing Associate",
//     hike: "112% Hike",
    
//     _id: 8,
//     name: "Maddila Sai Lavanya",
//     to: "Consultant (Investments)",
//     img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/maddila+sai+lavanya.png',
//     companyImg: c4dLogo
//   },
  {
    batch: "FMBV Batch",
    from: "Relationship Officer",
    hike: "51% Hike",
    
    _id: 9,
    name: "Rahul Singh",
    to: "Relationship Officer",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/rahulSingh.png',
    companyImg: coforgeLogo
  },
  {
    batch: "FMBV Batch",
    from: "Fixed Income Analyst",
    hike: "35% Hike",
    
    _id: 10,
    name: "Ajay Raja",
    to: "Investments Analyst ",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/ajayRaja.png',
    companyImg: c4dLogo
  },
  // {
  //   batch: "IB Batch",
  //   from: "Investment Associate",
  //   hike: "48% Hike",
    
  //   _id: 11,
  //   name: "Debojyoti Bose",
  //   to: "Assistant Manager",
  //   img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/debojyotiBose.png',
  //   companyImg: mmcLogo
  // },
  {
    batch: "FMBV Batch",
    from: "MBA",
    hike: "Fresher",
    
    _id: 12,
    name: "Anshul Chauhan",
    to: "Analyst Intern Investment Banking",
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/anshulChauhan.png',
    companyImg: sgLogo
  },
  {
    batch: "FMBV Batch",
    from: "M.Com",
    hike: "Fresher",
    
    _id: 13,
    name: 'Shashank Shukla',
    to: 'Analyst',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/shashankShukla.png',
    companyImg: dutscheBank
  }, 
  {
    batch: "FMBV Batch",
    from: "B.Com",
    hike: "Fresher",
    
    _id: 14,
    name: 'Sannidhya Gupta',
    to: 'Finance Consultant',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/sanidhyaGupta.png',
    companyImg: sgLogo
  }, 
  {
    batch: "FMBV Batch",
    from: "M.Com",
    hike: "Fresher",
    
    _id: 15,
    name: 'Keshav Didwania',
    to: 'Senior Associate',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/keshavDidwania.png',
    companyImg: acuityLogo
  }, 
  {
    batch: "FMBV Batch",
    from: "Business Analyst",
    hike: "62% Hike",
    
    _id: 16,
    name: 'Shivam',
    to: 'Financial Analyst',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/shivam.png',
    companyImg: finlender_ico
  },
  {
    batch: "IB Batch",
    from: "MBA",
    hike: "Fresher",
    
    _id: 17,
    name: 'Prathana haridoss',
    to: 'Analyst',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/prathanaHaridoss.png',
    companyImg: bny_ico
  },
  {
    batch: "FMBV Batch",
    from: "Senior Associate",
    hike: "66% Hike",
    
    _id: 18,
    name: 'Mahak Arneja',
    to: 'Senior Executive',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/mahakArneja.png',
    companyImg: ntf_ico
  },
  {
    batch: "FRA BATCH",
    from: "Business Analyst",
    hike: "37% Hike",
    
    _id: 19,
    name: 'Satish',
    to: 'Research Analyst',
    img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/course-testimonials/satish.png',
    companyImg: londom_seg_ico
  }
]


function RecentlyPlacedTrainee() {
    return (
        <div className='light_dark_bg tw-pt-5 tw-pb-5'>
            <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 ${styles.header}`}>Our Recently Placed Trainees</p>
            <Swiper
                loop={true}
                speed={1200}
                grabCursor={true}
                centeredSlides={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                spaceBetween={30}
                // pagination={true}
                modules={[Autoplay]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 2,
                        // spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        // spaceBetween: -100
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 3,
                    }
                }}
                className="-md:tw-my-3 container"
            >
                {traineeData?.map(trainee => {
                    return <SwiperSlide key={trainee?._id} style={{ display: 'flex', justifyContent: "center" }} className='!tw-h-auto'>
                        <TraineeCard traineeCardData={trainee} />
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    )
}

export default RecentlyPlacedTrainee