import React from 'react'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
let errorImg = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/ErrorImg.svg';

function ErrorPage() {
  return (
    <div className='container tw-mb-16 tw-mt-24 tw-min-h-screen lg:tw-min-h-[85vh] tw-flex tw-justify-center tw-items-center '>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page not found - 404</title>
        <meta name="description" content="Looks like you are lost in the ocean let's help you to take back you home" />
      </Helmet>
      <div className='row'>
        <div className='col-lg-6 col-12'>
          <img src={errorImg} alt="errorLeftLogo"/>
        </div>
        <div className='col-lg-6 col-12 tw-pl-6 tw-mt-6 lg:tw-mt-0 tw-flex tw-flex-col tw-justify-center'>
          {/* <div className='tw-flex tw-flex-col tw-justify-evenly'> */}
            <span className='tw-text-9xl tw-font-semibold tw-text-[#1A064F]'>40<span className='tw-text-[#6440FB]'>4</span></span>
            <span className='tw-text-3xl tw-font-semibold'>Oops! It looks like you're lost.</span>
            <span className='tw-mt-2 tw-opacity-60'>The page you're looking for isn't available. Try to search again or use the go to.</span>
            <span className='tw-mt-6'><Link to="/"><span className='tw-btn tw-btn-wide tw-bg-[#6440FB] tw-capitalize tw-border-[#6440FB] tw-font-normal'>Go Back To Homepage</span></Link></span>
          {/* </div> */}
          
        </div>
      </div>
    </div>
  )
}

export default ErrorPage