import React from 'react'
import {motion} from 'framer-motion'
import {downToUpVariant} from '../../animationVariants/variants'

let aw1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/award1.png'
let aw2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/award2.png'
let aw3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/award3.png'
let aw4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/award4.png'
function OurJourney() {
  return (

    <div className='tw-mt-16 tw-mb-12'  >
    <div className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium '>Our Journey so far!</div>
    <div className='text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60 '>Delivering exceptional client experience since 2020</div>

    <div className="tw-py-6" >
      <div className='row tw-justify-around'>

        <motion.div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible" style={{ maxWidth: '250px' }} className='col-12 col-lg-3 col-md-6 tw-flex tw-flex-col tw-h-[100%] tw-items-center tw-justify-evenly'>
          <span style={{ borderRadius: '999px' }} className='tw-mx-6 hover:tw-text-white' ><img loading="eager" className='tw-p-[20px]' style={{transform: 'scale(1.25)'}} src={aw1} alt="learning icon" /></span>
          <span className='tw-my-1 tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <span className='tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60'>Honored to be the Top 100 Most impactful companies in the Edtech space</span>
          </span>
        </motion.div>

        <motion.div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible" style={{ maxWidth: '250px' }} className='col-12 col-lg-3 col-md-6 tw-flex tw-flex-col tw-h-[100%] tw-items-center tw-justify-evenly'>
          <span style={{ borderRadius: '999px' }} className='tw-mx-6 hover:tw-text-white' ><img loading="eager" className='tw-p-[20px]' style={{transform: 'scale(1.25)'}} src={aw2} alt="learning icon" /></span>
          <span className='tw-my-1 tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <span className='tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60'>We had undergone all the standard evaluation criteria and proudly got recognized</span>
          </span>
        </motion.div>

        <motion.div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible" style={{ maxWidth: '250px' }} className='col-12 col-lg-3 col-md-6 tw-flex tw-flex-col tw-h-[100%] tw-items-center tw-justify-evenly'>
          <span style={{ borderRadius: '999px' }} className='tw-mx-6 hover:tw-text-white' ><img loading="eager" className='tw-p-[20px]' style={{transform: 'scale(1.25)'}} src={aw3} alt="learning icon" /></span>
          <span className='tw-my-1 tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <span className='tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60'>Successfully helped over 1000s of aspirants to build their career in finance</span>
          </span>
        </motion.div>

        <motion.div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible" style={{ maxWidth: '250px' }} className='col-12 col-lg-3 col-md-6 tw-flex tw-flex-col tw-h-[100%] tw-items-center tw-justify-evenly'>
          <span style={{ borderRadius: '999px' }} className='tw-mx-6 hover:tw-text-white' ><img loading="eager" className='tw-p-[20px]' style={{transform: 'scale(1.25)'}} src={aw4} alt="learning icon" /></span>
          <span className='tw-my-1 tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <span className='tw-text-center tw-text-xs lg:tw-text-sm tw-opacity-60'>We secured a rank with Top 10 companies within 1 year of Operations</span>
          </span>
        </motion.div>

      </div>
    </div>

  </div>
  )
}

export default OurJourney