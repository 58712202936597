import React from 'react'
import {motion} from 'framer-motion'
import {Helmet} from "react-helmet";
import './Home.css';

import graduateIcon from '../../assets/icons/graduate-cap_1.svg'
import readIcon from '../../assets/icons/read_1.svg'
import trophyIcon from '../../assets/icons/trophy.svg'
// import rightImg from '../../assets/images/Frame1.svg'

import {rightToLeftVariant, leftToRightVariant} from '../animationVariants/variants'

import ProudOf from './ProudOf/ProudOf';
import CertificationJourney from './CertificationJourney/CertificationJourney';
import Login from './Login/Login';
import Blog from './Blog/Blog';
import IndustrialPartner from './IndustrialPartner/IndustrialPartner';
import Courses from './Courses/Courses.js'
import TrustedBy from './TrustedBy/TrustedBy.js';
import Testimonial from './Testimonial/Testimonial.js';
import Specialization from './Specialization/Specialization';
import YourJourneyCurve from './YourJourneyCurve/YourJourneyCurve';
import PlacementRecruiters from './PlacementRecrutiters/PlacementRecruiters';
import OurJourney from './OurJourney/OurJourney';
import {Link} from 'react-router-dom';

function Home() {
//console.log("home ran")
  return (
    <div className=' ' style={{ overflowX: 'hidden' }}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Finocontrol</title>
          <meta property="og:description" content="Finocontrol Consultancy Services LLP has a legacy in providing educational, advisory, consulting, and training services and is ranked as the 'Top 10 best FM Companies in the world'" />
      </Helmet>
      <div className={` tw-background set_bg tw-pb-6 lg:tw-pb-40 tw-min-h-screen lg:tw-min-h-[90vh]`} style={{ backgroundRepeat: 'no-repeat' }}>
        <div className='container tw-py-6 lg:tw-py-0'>
          <motion.div variants={leftToRightVariant} initial="hidden" animate="visible" className='row text-light'>
            <div  className="col-lg-6 col-12 tw-flex tw-justify-center tw-flex-col tw-pt-24 lg:tw-pt-20 xl:tw-pt-24 ">
              <motion.p variants={leftToRightVariant} className='tw-my-8 md:tw-my-5 lg:tw-leading-[5rem] tw-text-2xl md:tw-text-4xl lg:tw-text-5xl self_main_content_font tw-flex tw-flex-col '>Begin your journey today, <span>with Top <span className='tw-underline tw-underline-offset-8' style={{ color: '#00FF84', textDecorationThickness: '0.25rem' }}>Professionals</span></span> </motion.p>
              <motion.p variants={leftToRightVariant} className='tw-my-4 md:tw-my-5 lg:tw-leading-[2rem] tw-text-sm md:tw-text-base lg:tw-text-lg'>Designed and developed by India's Leading Investment Bankers, CFA Charterholders, CFOs and NISM Experts</motion.p>
              <motion.span variants={leftToRightVariant} className='tw-my-2'>
                <Link to="/course" ><button className='tw-px-6 tw-btn tw-btn-primary tw-mr-6' style={{ textTransform: 'capitalize' }}>Get Certified</button></Link>
                <Link to="/course" ><button className='tw-px-8 tw-btn tw-btn-outline self_explore_btn_effect ' style={{ color: '#00FF84', textTransform: 'capitalize' }}>Explore</button></Link>
              </motion.span>
              <span  className='tw-flex tw-mt-12 tw-mb-12' style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <motion.span variants={leftToRightVariant} className='tw-flex tw-text-xs tw-place-items-center tw-pt-3 lg:tw-pt-0'><img src={graduateIcon} className="tw-pr-2" alt="graduate" />Over 10,000+ Alumni</motion.span>
                <motion.span variants={leftToRightVariant} className='tw-flex tw-text-xs tw-place-items-center tw-pt-3 lg:tw-pt-0'><img src={trophyIcon} className="tw-pr-2" alt="trophy" />Recognised by MSME, Govt. of India</motion.span>
                <motion.span variants={leftToRightVariant} className='tw-flex tw-text-xs tw-place-items-center tw-pt-3 lg:tw-pt-0'><img src={readIcon} className="tw-pr-2" alt="read" />Decode Finance today</motion.span>
              </span>
            </div>
            <motion.div variants={rightToLeftVariant} className='tw-flex col-lg-6 col-12 tw-justify-center tw-flex-col tw-pt-5 lg:tw-pt-24 xl:tw-pt-28 '>
              <img src='https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/Frame1.svg' className="img-fluid" alt="left_img" />
            </motion.div>
          </motion.div>

        </div>
      </div>

      <div className='container'>
        <YourJourneyCurve/>
        <PlacementRecruiters/>
        <Specialization/>
        <OurJourney/>
        <Courses />
      </div>

      <TrustedBy />

      <IndustrialPartner />
      <div className='tw-mt-16'>
        <Testimonial />
        <ProudOf />
        <CertificationJourney />
        <Login days={0}/>
        <Blog />
      </div>
    </div>
  )
}

export default Home

