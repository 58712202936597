import {LOGIN_USER, LOGOUT_USER,LOGIN_ERROR} from './loginTypes';
import Cookies from 'js-cookie'

const initialState= {
    isLoggedIn: false,
    error: '',
}

const reducer= (state= initialState, action)=>{
    switch (action.type) {
        case LOGIN_USER: return {
            isLoggedIn: true,
            error:''
        }
        case LOGOUT_USER: {
            // window.document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            Cookies.remove('finocontrol-token', {path:'/'});
            return {
            isLoggedIn: false,
            error: ''
        }}
        case LOGIN_ERROR: return {
            isLoggedIn: false,
            error: action.payload,
        }
        default: return state;
    }
}

export default reducer