import React from 'react'
import styles from './WorriedAbout.module.css'
import NewCourseButton from '../../common/NewCourseButton';
import { getFormattedPrice } from '../../../../../library';
import IconList from '../Curriculum/IconList';
import { TalkToExpert } from '../../RamNewSingleCourse';
import { Link } from 'react-router-dom';
let logo1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small1.svg';
let logo2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big1.svg';
let logo3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small2.svg';
let logo4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big2.svg';
let logo5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small3.svg';
let logo6 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big3.svg';

let logo7 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small4.svg';
let logo8 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big4.svg';
let logo9 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small5.svg';
let logo10 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big5.svg';
let logo11 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo1.svg';
let logo12 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo13.svg';

let logo13 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo6.svg';
let logo14 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo12.svg';
let logo15 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo11.svg';
let logo16 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Coforge.svg';
let logo17 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Peaceful-Progress.svg';
let logo18 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/c4d.svg';

function WorriedAbout({ actualPrice, discountedPrice, offerPrice, enrolLink, courseName }) {
    return (
        <div className='-tw-mt-4 tw-pb-8'>
            <div className='container'>
                
                <div className={`${styles.fee_detail_div} tw-mt-8`}>
                    <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6`}>
                        <span>Program Fee Details</span>
                    </p>
                    <p className={styles.fee_detail_text}>
                        The Average Price of a CEPM Certification in the market is above {getFormattedPrice(20000)}/- <br />Learning from us ! will not only save Money but will Bring Better Career Endevaours
                    </p>
                    <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-mb-0 lg:tw-my-8">
                        <div className="tw-grid tw-bg-transparent md:tw-my-6 tw-mx-4 md:tw-mx-10 tw-flex-grow tw-rounded-box ">
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Get Trained under<span className={styles.highlight_text}>with Top Industry Leaders</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'><span className={styles.highlight_text}>Industry Standard</span> Curriculum</span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'><span className={styles.highlight_text}>Practical based learning</span> Approach</span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Lucrative <span className={styles.highlight_text}>Career Opportunities</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Guaranteed <span className={styles.highlight_text}>Research Internship*</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Avail <span className={styles.highlight_text}>Easy EMI</span>  Options</span>
                            </div>

                        </div>
                        <div className="tw-divider md:tw-divider-horizontal"></div>
                        <div className="tw-mb-6 md:tw-my-6 tw-mx-6 tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center">
                            <p className='tw-text-[#5B1B73] tw-font-bold tw-text-base lg:tw-text-lg'>Hurry Up ! <span className='tw-font-medium'> Only Few Seats Left</span></p>
                            <del className='tw-uppercase tw-my-2 tw-font-bold tw-text-base lg:tw-text-lg'>Actual <span className='tw-font-semibold'>{getFormattedPrice(actualPrice)}/-</span></del>
                            <p className='tw-uppercase tw-my-2 tw-text-[#CB33B9] tw-font-bold tw-text-lg lg:tw-text-xl tw-flex tw-items-center'>Discounted <span className='tw-ml-2 tw-text-[#773D91] tw-text-xl tw-font-bold'>{getFormattedPrice(discountedPrice)}/-</span></p>
                            <p className='tw-uppercase tw-my-2 tw-text-[#CB33B9] tw-font-bold tw-text-xl lg:tw-text-2xl tw-flex tw-items-center'>Early Bird Price <span className='tw-ml-2 tw-text-[#773D91] tw-text-3xl tw-font-bold'>{getFormattedPrice(offerPrice)}/-</span></p>
                            <p className='tw-flex tw-justify-center tw-text-sm tw-text-[#773D91] tw-my-2'>*All prices are inclusive GST</p>
                            <a href={enrolLink} target='__blank' className='tw-btn tw-btn-primary tw-btn-wide tw-my-2'>Enroll Now</a>
                        </div>
                    </div>
                    <div className='tw-flex tw-items-center tw-justify-center tw-pb-8 tw-flex-col md:tw-flex-row tw-gap-6'>
                        <span className='tw-mr-3 tw-mb-3 md:tw-my-0'>Still Confused??</span>
                        <div className='tw-flex tw-flex-col tw-max-w-[70%]'>
                            <div className="tw-dropdown tw-dropdown-hover">
                                <NewCourseButton buttonName="Talk To Top Experts in Industry" />
                                <ul tabIndex={0} className="tw-dropdown-content !tw-z-[101] tw-menu tw-py-2 tw-rounded-box">
                                    <TalkToExpert />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorriedAbout