import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useFormik } from 'formik';
import { requestACallbackSchema } from '../../../formikSchema/schema'
import { toast } from 'react-toastify';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


import './RamNewSingleCourse.css'

import {Modal as CommonModal} from '../../common/Modal';

import Icon1 from './assets/assistance.svg';
import Icon2 from './assets/peoples.svg';
import Icon3 from './assets/exposure.svg';
import Icon4 from './assets/training.svg';
// import Icon5 from '../assets/NewSingleCourse/icon5.svg'



import serverAPI from '../../../utils/baseAPI';
import Spinner from '../../../helpersComponents/Spinner';
import { UserContext } from '../../../userContext/UserState';
import waLogo from '../../../assets/icons/whatsAppIcon.svg';
import googleIcon from '../assets/logo/google_logo.svg'
import onlineCourseIcon from '../assets/icons/online_course.svg'
import scholarCapIcon from '../assets/icons/scholar_cap_icon.svg'
import isoCertPng from '../assets/icons/isoCert.png'
import leftGirl from './assets/left-girl.png'

import RecentlyPlacedTrainee from './component/RecentlyPlacedTrainee/RecentlyPlacedTrainee';
import WhyShouldYouPersue from './component/whyShouldYouPersue/WhyShouldYouPersue';
import PastExperience from './component/PastExperience/PastExperience';
import Curicullum from './component/Curriculum/Curriculum';
import CorporateTool from './component/CorporateTool/CorporateTool';
import CorporateProject from './component/CorporateProject/CorporateProject';
import CaseStudy from './component/CaseStudy/CaseStudy';
import LearnFrom from './component/LearnFrom/LearnFrom';
import Brochure from './component/Brochure/Brochure';
import NewCourseButton from './common/NewCourseButton';
import Guaranteed from './component/Guaranteed/Guaranteed';
import TraineeAboutUs from './component/TraineeAboutUs/TraineeAboutUs';
import ProfileAfter from './component/ProfileAfter/ProfileAfter';
import WorriedAbout from './component/WorriedAbout/WorriedAbout';
import HowCanWeHelp from './component/HowCanWeHelp/HowCanWeHelp';
import InTheNews from './component/InTheNews/InTheNews';
import ErrorPage from '../../ErrorPage/ErrorPage';
import jpmLogo from './assets/JPMorgan Chase & Co. - jpeg.png'

let logo1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small1.svg';
let logo2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big1.svg';
let logo3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small2.svg';
let logo4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big2.svg';
let logo5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small3.svg';
let logo6 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big3.svg';

let logo7 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small4.svg';
let logo8 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big4.svg';
let logo9 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small5.svg';
let logo10 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big5.svg';
let logo11 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo1.svg';
let logo12 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo13.svg';

let logo13 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo6.svg';
let logo14 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo12.svg';
let logo15 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo11.svg';
let logo16 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Coforge.svg';
let logo17 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Peaceful-Progress.svg';
let logo18 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/c4d.svg';
let eyLogo=  'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/success-stories-persons-image/company_logo/ey_n.png'
let pwcLogo = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small2.svg';


const testimonialData=[
  {_id: 1, iconImg: Icon1, content: 'Designed & Developed By Top Industry Experts'},
  {_id: 2, iconImg: Icon2, content: '2 Months of Corporate standard Live Training with Industry Experts'},
  {_id: 3, iconImg: Icon3, content: 'Guaranteed Reasearch Internship'},
  {_id: 4, iconImg: Icon4, content: 'CV/ Linkedin optimization with Expert guidance'},

  {_id: 5, iconImg: Icon1, content: 'Designed & Developed By Top Industry Experts'},
  {_id: 6, iconImg: Icon2, content: '2 Months of Corporate standard Live Training with Industry Experts'},
  {_id: 7, iconImg: Icon3, content: 'Guaranteed Reasearch Internship'},
  {_id: 8, iconImg: Icon4, content: 'CV/ Linkedin optimization with Expert guidance'},

  {_id: 9, iconImg: Icon1, content: 'Designed & Developed By Top Industry Experts'},
  {_id: 10, iconImg: Icon2, content: '2 Months of Corporate standard Live Training with Industry Experts'},
  {_id: 11, iconImg: Icon3, content: 'Guaranteed Reasearch Internship'},
  {_id: 12, iconImg: Icon4, content: 'CV/ Linkedin optimization with Expert guidance'},
]

function RamNewSingleCourse() {
  const [courseData, setCourseData] = useState([]);
  const location = useLocation();
  const pathSplit = location.pathname.split('/');
  const courseId = pathSplit[pathSplit.length - 1];
  const [loginFailedMsg, setLoginFailedMsg] = useState("");
  const { userState } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showRequestCallback, setShowRequestCallback] = useState(false);
  const [loading, setLoading] = useState(true);
  // console.log('hi')

  // useEffect(() => {
  //   let timerId = null;

  //   if (!userState.isShowSingleCourseModalDisabled) {
  //     timerId = setTimeout(() => {
  //       setShowModal(true);
  //     }, 30 * 1000);
  //   } else {
  //     clearTimeout(timerId);
  //   }

  //   return () => {
  //     clearTimeout(timerId);
  //   }
  // }, [userState.isShowSingleCourseModalDisabled])

  useEffect(() => {
    serverAPI.get(`/api/course/${courseId}`).then(e => {
      const tempCourseData = e.data.data[0];
      tempCourseData.instructors_data = tempCourseData.instructors_data.sort((firstIns, secondIns) => secondIns.priorityOrder - firstIns.priorityOrder);
      setCourseData(tempCourseData);
    }).catch(e => {
      console.log(e)
      setLoginFailedMsg(e?.response?.data?.msg);
    }).finally(() => {
      setLoading(false);
    })
  }, [courseId]);
  // console.log(object);

  // if (!loading) {
  //   return <div className={`${userState.offerHeader === true ? 'tw-mt-36 md:tw-mt-14' : 'tw-mt-6'} `}>
  //     <ErrorPage />
  //   </div>
  // }

  return loading ? <Spinner /> : (
    <div className=''>
      <Helmet>
        <title>{courseData?.name}</title>
        <meta name="description" content={courseData?.overview} />
      </Helmet>
      {
        showModal && <Modal setShowModal={setShowModal} setShowRequestCallback={setShowRequestCallback} />
      }
      {
        <div className='tw-absolute'>
          <RequestACallback setShowRequestCallback={setShowRequestCallback} courseData={courseData} />
        </div>
      }
      <div className={`set_single_course_bg_2 img-fluid tw-mt-16 lg:tw-mt-16 lg:tw-pb-16`}>
        <div className={`container ${userState.offerHeader === true ? 'tw-mt-36 md:tw-mt-14' : 'tw-mt-8'} `}>
          <div className='tw-flex tw-flex-col tw-gap-1'>
            <p className='tw-text-[#FFC700] tw-text-2xl md:tw-text-5xl tw-font-semibold tw-text-center'>{courseData?.shortName} <br/> <span style={{color: 'white'}}> + </span> <br/> Certification In Power Point Essentials <span style={{color: 'white'}}>(FREE)</span> </p>
            <div className=' tw-text-lg md:tw-text-lg tw-text-center tw-mt-4'>
              <p>Get Market Ready & Walk one step ahead in the Professional Finance Industry. </p>
              <p>No Prior Experience required to get started.</p>
            </div>
            
            <div className='tw-text-xl md:tw-text-xl tw-text-center tw-mt-6 tw-flex tw-justify-center tw-gap-6'>
              <div className='tw-flex tw-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" fill="none" className='tw-scale-75'>
                  <path d="M3 10.619L9.66667 17.2857L23 3" stroke="#E8B80E" stroke-width="5.8875" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
                <span className='tw-ml-1'>Get Trained</span>
              </div>
              <div className='tw-flex tw-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" fill="none" className='tw-scale-75'>
                  <path d="M3 10.619L9.66667 17.2857L23 3" stroke="#E8B80E" stroke-width="5.8875" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
                <span className='tw-ml-1'>Get Certified</span>
              </div>
            </div>            
          </div>

          <div className='tw-flex tw-justify-center tw-mt-6 tw-z-50'>
            <div className='tw-flex tw-flex-col md:tw-flex-row tw-gap-x-10 tw-gap-y-6'>
              <Link to={courseData?.enrolLink} target='__blank'><NewCourseButton buttonName="Enroll Now" btnBgColor='007BFF' btnTextColor='white'/></Link>
              <div className="tw-dropdown tw-dropdown-hover">
                <NewCourseButton buttonName="Talk To our Expert Team" />
                <ul tabIndex={0} className="tw-dropdown-content !tw-z-[101] tw-menu tw-py-2 tw-rounded-box">
                  <TalkToExpert />
                </ul>
              </div>
            </div>
          </div>

          <div className='row tw-mt-10'>
            <div className='tw-font-medium tw-mb-3 tw-text-center'>
              Our Experts Work At:
            </div>
            <AlumiWorksAtSlider />
            {/* in the news */}
            <CardContainerSlider />
            {/* <div className='tw-flex tw-justify-center tw-my-6'>
              
              <a href={`//wa.me/+919938739729 ?text=Hi%20I'm%20inquiring%20about%20${courseData.shortName}%20program%20please%20reach%20out%20to%20me,%20thanks.`} target='__blank' rel="noopener noreferrer" className='whats_app_bg tw-py-3 tw-rounded-full tw-px-6 tw-font-semibold tw-flex tw-items-center tw-gap-2 tw-mt-8'>
                Chat With Us
                <img src={waLogo} alt="wa" />
              </a>
            </div> */}

        

            {/* <div className='col-12 col-lg-5 tw-text-center tw-text-black tw-bg-white sm:tw-bg-transparent tw-flex tw-justify-center tw-items-center tw-mt-8 lg:tw-mt-0'>
              <div className='tw-flex tw-flex-col tw-gap-y-2 our_aluminies tw-my-6'>
                <div className='tw-font-medium tw-text-lg md:tw-text-white lg:tw-text-black'>
                  Our Alumni Work At
                </div>
                <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-gap-y-1 lg:tw-gap-y-1'>
                  <div className='row tw-w-full tw-justify-between'>
                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={eyLogo} alt="company" className='img-fluid' width={80} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={pwcLogo} alt="company" className='img-fluid' width={70} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo5} alt="company" className='img-fluid' width={80} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={jpmLogo} alt="company" className='img-fluid' width={80} />
                    </div>

                  </div>
                  <div className='row tw-w-full tw-justify-between'>
                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo8} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo6} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo10} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo11} alt="company" className='img-fluid' width={100} />
                    </div>

                  </div>
                  <div className='row tw-w-full tw-justify-between'>
                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo7} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo15} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo16} alt="company" className='img-fluid' width={100} />
                    </div>

                    <div className='col-3 tw-flex tw-item-center tw-justify-center'>
                      <img src={logo17} alt="company" className='img-fluid' width={100} />
                    </div>

                  </div>
                </div>
                
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='light_dark_bg tw-gap-5 tw-min-h-[40vh] tw-flex tw-justify-center tw-items-center'>
        <div className='container'>
          <div className='row tw-h-full tw-flex tw-items-center'>
            <div className='col-12 tw-my-8  tw-scale-[0.8] col-md-3 tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-white'>
              <div className='tw-flex tw-items-center'>
                <div className='tw-w-10 tw-h-10 star_css'></div>
                <div className='tw-w-10 tw-h-10 star_css'></div>
                <div className='tw-w-10 tw-h-10 star_css'></div>
                <div className='tw-w-10 tw-h-10 star_css'></div>
                <div className='tw-w-10 tw-h-10 star_css'></div>
              </div>
              <img src={googleIcon} alt="google logo" width={140} />
            </div>
            <div className='col-12 tw-my-8  tw-scale-[1.2] col-md-3 tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-white'>
              <div className='tw-flex tw-items-center tw-flex-col'>
                <div>
                  <img src={isoCertPng} alt="scholar" width={100} />
                </div>
              </div>
            </div>
            <div className='col-12 tw-my-8  tw-scale-[0.8] col-md-3 tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-white'>
              <div className='tw-flex tw-items-center tw-flex-col'>
                <div>
                  <img src={scholarCapIcon} alt="scholar" width={100} />
                </div>
                <div className='tw-text-2xl lg:tw-text-3xl tw-mt-3'>487+ </div>
                <div className='tw-text-2xl lg:tw-text-3xl'>Alumni</div>
              </div>
            </div>
            <div className='col-12 tw-my-8  tw-scale-[0.8] col-md-3 tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-white'>
              <div className='tw-flex tw-items-center tw-flex-col'>
                <div>
                  <img src={onlineCourseIcon} alt="scholar" width={100} />
                </div>
                <div className='tw-text-2xl lg:tw-text-3xl tw-mt-3'>{courseData?.courseLength} </div>
                <div className='tw-text-2xl lg:tw-text-3xl'>Live & Immersive</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PastExperience />
      <WhyShouldYouPersue courseName={courseData.name} />
      <Curicullum curriculum={courseData.curriculum} />
      <CorporateTool courseName={courseData.shortName}/>
      {/* <CorporateProject courseName={courseData.name} corporateLiveProjectContent={courseData.corporateLiveProjectContent} /> */}
      {/* <CaseStudy courseName={courseData.name} /> */}
      <LearnFrom instructorData={courseData.instructors_data} />
      <Brochure courseName={courseData.name} brochureLink={courseData.brochureLink} />
      <Guaranteed />
      {/* <TraineeAboutUs /> */}
      <ProfileAfter courseName={courseData.name} />
      <WorriedAbout actualPrice={courseData.actualPrice} discountedPrice={courseData.discountedPrice} offerPrice={courseData.offerPrice} enrolLink={courseData?.enrolLink} courseName={courseData.name} />
      {/* <HowCanWeHelp courseFaq={courseData.faq} /> */}
      <InTheNews />
    </div>
  )
}


export function TalkToExpert({ setShowRequestCallback }) {
  return <>
    <div className='tw-flex tw-gap-3'>
      <a href='tel:+919938739729' className='tw-btn tw-btn-sm call_btn tw-rounded'>Call Now</a>
      <a href='#requestCallbackModal' className='tw-btn tw-btn-sm tw-btn-ghost call_btn tw-rounded' >Request a Call Back</a>
    </div>
  </>
}
export default RamNewSingleCourse;


function Modal({ setShowModal, setShowRequestCallback }) {
  const { userState, setUserState } = useContext(UserContext);

  function handlecloseModal() {
    setShowModal(false);
    setUserState({ ...userState, isShowSingleCourseModalDisabled: true });
  }

  function handleCallBackClick() {
    setShowRequestCallback(true);
  }
  return (<>
    <div className='tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center tw-fixed tw-z-[1000] tw-backdrop-blur-sm' onClick={() => setShowModal(false)}>
      <div className="tw-card md:tw-card-side tw-bg-base-100 tw-shadow-xl" onClick={(e) => e.stopPropagation()}>
        <button className="tw-btn tw-btn-md tw-btn-ghost tw-btn-circle tw-absolute tw-right-0" onClick={handlecloseModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        <figure className='md:tw-w-1/2 d-none d-md-flex'><img className='tw-rounded-l-2xl' src={leftGirl} alt="Album" /></figure>
        <div className="tw-w-[340px] md:tw-w-1/2 tw-card-body">
          <div className="container-fluid tw-flex tw-items-center tw-justify-center tw-flex-col">
            <div className='tw-my-4'>
              <div className='tw-text-[#5B1B73] tw-flex tw-items-center tw-justify-center'>Talk To Our</div>
              <div className='tw-text-[#C12CB4] tw-flex tw-items-center tw-justify-center'>Top 1% Finance Experts </div>
            </div>
            <div className='tw-w-full tw-my-4'>
              <a href='tel:+919938739729' className='tw-btn tw-btn-md tw-text-black tw-border tw-border-[#EBBA0D] tw-bg-[#EBBA0D] tw-capitalize hover:tw-bg-[#EBBA0D] hover:tw-border-[#EBBA0D] tw-rounded-xl tw-w-full tw-font-medium'>
                Call Now
              </a>
            </div>
            <div>Or</div>
            <div className='tw-w-full tw-my-4'>
              <a href="#requestCallbackModal" className='tw-btn tw-btn-md !tw-text-white tw-border tw-border-[#007BFF] tw-bg-[#007BFF] tw-capitalize hover:tw-bg-[#007bfff9] hover:tw-border-[#007bfff9] tw-rounded-xl tw-w-full tw-font-medium' onClick={handleCallBackClick}>Request a Call Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export function RequestACallback() {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const { userState } = useContext(UserContext);
  const trimmedName = userState.courseTrimmedName;
  // console.log(params.get('utm_source'));

  const [showModal, setShowModal] = useState(false);
  const [serverMessage, setServerMessage] = useState("");

  function clearRadioChecked() {
    const radioNameArray= ['studentOrWorking', 'careerInCourse', 'describeYou', 'areYouInterested', 'academicCourse']
    for(let radioName of radioNameArray) {
      var radioButtons = document.getElementsByName(radioName);
      for (var i = 0; i < radioButtons.length; i++) {
        radioButtons[i].checked = false;
      }
    }
  }

  const registerForm = useFormik({
    validationSchema: requestACallbackSchema,
    initialValues: {
      utm_source: params.get('utm_source') ?? "website",
      name: '',
      email: '',
      whatsAppNumber: '',
      contactNumber: '',
      graduationYear: '',
      previousExperience: '',
      academicCourse: '', //select
      careerInCourse: '',
      studentOrWorking: '',

      stateResideIn: '',
      areYouInterested: '',
      describeYou: ''
    },
    onSubmit: (value, action) => {
      serverAPI.post(`/api/request-callback`, value)
        .then(e => {
          // toast.success(e.data.msg);
          setShowModal(true);
          setServerMessage(e.data.msg)
        })
        .catch(e => {
          toast.error(e.response.data.msg);
        })
        .finally(() => {
          action.resetForm();
          document.getElementById('closeRequestCallbackForm').click();
          clearRadioChecked();
        })
    }
  });
  return <div>
    {
      showModal && <CommonModal setShowModal={setShowModal}>
        <div className='tw-max-w-[300px] tw-h-full tw-flex tw-justify-center tw-items-center tw-font-medium tw-text-lg'>
          {serverMessage}
        </div>
      </CommonModal>
    }
    {/* <a href="#requestCallbackModal" className="tw-capitalize tw-my-1 tw-text-xs tw-btn tw-w-full tw-btn-primary tw-bg-[#E8543E] tw-border-[#E8543E] hover:tw-bg-[#E8543E] hover:tw-text-black hover:tw-border-[#E8543E] hover:tw-opacity-80">Join now</a> */}
    <div className="tw-modal tw-pt-12" style={{ zIndex: 2000 }} id="requestCallbackModal" onClick={() => {
      document.getElementById('closeRequestCallbackForm').click()
    }}>
      <div className="tw-modal-box tw-py-16" onClick={(e) => e.stopPropagation()}>
        <a href="#!" className="tw-btn tw-btn-sm tw-btn-circle tw-btn-ghost tw-absolute tw-right-2 tw-top-2" >✕</a>
        <form onSubmit={registerForm.handleSubmit}>
          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="name" className='tw-label-text'>Full Name <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.name && registerForm.errors.name ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.name}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.name} name="name" required type="text" placeholder="Your Full Name" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.email && registerForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.email}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.email} name="email" required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="whatsAppNumber" className='tw-label-text'>WhatsApp Number <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.whatsAppNumber && registerForm.errors.whatsAppNumber ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.whatsAppNumber}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.whatsAppNumber} name="whatsAppNumber" required type="text" placeholder="Your WhatsApp Number" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="contactNumber" className='tw-label-text'>Contact Number <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.contactNumber && registerForm.errors.contactNumber ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.contactNumber}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.contactNumber} name="contactNumber" required type="text" placeholder="Your Contact Number" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="stateResideIn" className='tw-label-text'>State you reside in? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.stateResideIn && registerForm.errors.stateResideIn ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.stateResideIn}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.stateResideIn} name="stateResideIn" required type="text" placeholder="State Name" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="graduationYear" className='tw-label-text'>Which year did you graduate? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.graduationYear && registerForm.errors.graduationYear ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.graduationYear}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.graduationYear} name="graduationYear" required type="number" placeholder="Graduation Year" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="previousExperience" className='tw-label-text'>Do you have any previous working experience in the field of finance? If yes, please specify the domain?</label>
              {registerForm.touched.previousExperience && registerForm.errors.previousExperience ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.previousExperience}</p> : <></>}
            </span>
            <input onChange={registerForm.handleChange} value={registerForm.values.previousExperience} name="previousExperience" type="text" placeholder="" className="tw-input tw-input-bordered tw-w-full " />
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="academicCourse" className='tw-label-text'>Highest Academic Study Completed/ Pursuing? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.academicCourse && registerForm.errors.academicCourse ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.academicCourse}</p> : <></>}
            </span>
            {/* <input onChange={registerForm.handleChange} value={registerForm.values.academicCourse} name="academicCourse" required type="text" placeholder="Academic Course" className="tw-input tw-input-bordered tw-w-full " /> */}
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Yes" name="academicCourse" id="highestAcad1" type="radio" placeholder="" className="tw-radio " required /> &nbsp;<label className='tw-label-text' for="highestAcad1">BCom/BBA/BA/BSC</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="MBA/PGDM" name="academicCourse" id="highestAcad2" type="radio" placeholder="" className="tw-radio " required /> &nbsp;<label className='tw-label-text' for="highestAcad2">MBA/PGDM</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="CA/CMA/ACCA" name="academicCourse" id="highestAcad3" type="radio" placeholder="" className="tw-radio " required /> &nbsp;<label className='tw-label-text' for="highestAcad3">CA/CMA/ACCA</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="CFA/CFP/FRM" name="academicCourse" id="highestAcad4" type="radio" placeholder="" className="tw-radio " required /> &nbsp;<label className='tw-label-text' for="highestAcad4">CFA/CFP/FRM</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Other" name="academicCourse" id="highestAcad5" type="radio" placeholder="" className="tw-radio " required /> &nbsp;<label className='tw-label-text' for="highestAcad5">Other</label>
            </span>


          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="areYouInterested" className='tw-label-text'>Are you interested to enroll in this {userState.courseFullName} ? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.areYouInterested && registerForm.errors.areYouInterested ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.areYouInterested}</p> : <></>}
            </span>
            {/* <input onChange={registerForm.handleChange} value={registerForm.values.areYouInterested} name="areYouInterested" required type="text" placeholder="Academic Course" className="tw-input tw-input-bordered tw-w-full " /> */}
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Yes" name="areYouInterested" id="radio5" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="radio5">Yes</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="No" name="areYouInterested" id="radio6" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="radio6">No</label>
            </span>
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="describeYou" className='tw-label-text'>What describes you well?<span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.describeYou && registerForm.errors.describeYou ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.describeYou}</p> : <></>}
            </span>
            {/* <input onChange={registerForm.handleChange} value={registerForm.values.describeYou} name="describeYou" required type="text" placeholder="Academic Course" className="tw-input tw-input-bordered tw-w-full " /> */}
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Achiever" name="describeYou" id="describeYou1" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou1">Achiever</label>
            </span>

            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Extra Miler" name="describeYou" id="describeYou2" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou2">Extra Miler</label>
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Slow Learner" name="describeYou" id="describeYou3" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou3">Slow Learner</label>
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Dedicated" name="describeYou" id="describeYou4" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou4">Dedicated</label>
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Goal Oriented" name="describeYou" id="describeYou5" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou5">Goal Oriented</label>
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Average" name="describeYou" id="describeYou6" type="radio" placeholder="" className="tw-radio " required/> &nbsp;<label className='tw-label-text' for="describeYou6">Average</label>
            </span>
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="careerInCourse" className='tw-label-text'>Are you willing to build a career in {trimmedName}? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.careerInCourse && registerForm.errors.careerInCourse ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.careerInCourse}</p> : <></>}
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Yes" name="careerInCourse" required id="radio1" type="radio" placeholder="" className="tw-radio" /> &nbsp;<label className='tw-label-text' for="radio1">Yes</label>
            </span>

            <span className='tw-flex'>
              <input onChange={registerForm.handleChange} value="No" name="careerInCourse" required id="radio2" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="radio2">No</label>
            </span>
          </div>

          <div className='tw-pt-1'>
            <span className="tw-label">
              <label htmlFor="studentOrWorking" className='tw-label-text'>Are you a student or working professional? <span className='tw-text-red-500 tw-font-bold'>*</span></label>
              {registerForm.touched.studentOrWorking && registerForm.errors.studentOrWorking ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.studentOrWorking}</p> : <></>}
            </span>
            <span className='tw-flex tw-my-2'>
              <input onChange={registerForm.handleChange} value="Student" name="studentOrWorking" required id="radio3" type="radio" placeholder="" className="tw-radio" /> &nbsp;<label className="tw-label-text" for="radio3">Student</label>
            </span>

            <span className='tw-flex'>
              <input onChange={registerForm.handleChange} value="WorkingProfessional" name="studentOrWorking" required id="radio4" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className="tw-label-text" for="radio4">Working professional</label>
            </span>
            {/* <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="other" name="studentOrWorking" required id="radio5" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label for="Other">Other</label>
                              </span> */}

          </div>
          <span className='row tw-justify-between tw-mt-6'>
            <span className="col-5 tw-pt-2">
              <button type="submit" className='tw-btn tw-btn-secondary tw-w-[100%] tw-capitalize'>
                <span>Submit</span>
              </button>
            </span>
            <span className="col-5 tw-pt-2">
              <a id="closeRequestCallbackForm" href="#!" className="tw-btn tw-w-[100%] tw-capitalize tw-btn-ghost tw-text-error">Close</a>
            </span>
          </span>
        </form>
        <div className="tw-modal-action">
          {/* <a href="#" className="tw-btn">Close</a> */}
        </div>
      </div>
    </div>
  </div>
}

function CardContainerSlider() {
  return <Swiper
    effect={"coverflow"}
    grabCursor={true}
    centeredSlides={true}
    slidesPerView={3}
    autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    initialSlide={5}
    loop={true}
    coverflowEffect={{
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 2,
      // slideShadows: false,
    }}
    breakpoints={{
      950: {
        slidesPerView: 3,
      },
      750: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
        coverflowEffect: {
          depth: 0
        },
      }
    }}
    speed={1200}
    modules={[EffectCoverflow, Autoplay]}
    className="container tw-mt-16 tw-max-w-[95%] lg:tw-max-w-[80%] tw-z-0 md:tw-mb-0 tw-mb-8"
  >
    {testimonialData?.map(item => {
      return <>
        <SwiperSlide key={item._id} className='tw-bg-black work_at_bottom_slider !tw-flex'>
          <p className='tw-flex tw-items-center tw-px-6'>{item.content}</p>
          <img className='tw-flex tw-items-center tw-px-6' src={item.iconImg} alt="icon" width={100} />
        </SwiperSlide>

      </>
    })}
  </Swiper>

}

function AlumiWorksAtSlider() {
  return <Swiper
  spaceBetween={20}
  grabCursor={true}
  centeredSlides={true}
  // slidesPerView={5}
  autoplay={{
      delay: 2000,
      disableOnInteraction: false,
  }}
  speed={1200}
  pagination={true}
  loop={true}
  modules={[Autoplay]}
  className="container tw-max-h-[70px] works_at_layer"
  breakpoints={{
      0: {
          slidesPerView: 3,
      },
      576: { 
          slidesPerView: 3,
          // spaceBetween: 20
      },
      768: {
          slidesPerView: 4,
          // spaceBetween: -100
      },
      992: {
          slidesPerView: 4,
          // spaceBetween: 20
      },
      1200:{
        slidesPerView: 5,
      },
      1400: {
          slidesPerView: 5,
      }
  }}
>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo1} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo2} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo3} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo4} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo5} alt="newsLogo" /></SwiperSlide>


  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo6} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo7} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo8} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo9} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo10} alt="newsLogo" /></SwiperSlide>
  
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo11} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo12} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo13} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo14} alt="newsLogo" /></SwiperSlide>
  <SwiperSlide className='swiper_slide_class' ><img  className='' src={logo15} alt="newsLogo" /></SwiperSlide>

</Swiper>

}