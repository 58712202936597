
// import serverAPI from '../../utils/baseAPI';
import {LOGIN_USER, LOGOUT_USER, LOGIN_ERROR} from './loginTypes';
import {courseLogOutClear} from '../course/courseAction';
import {blogLogOutClear} from '../blog/blogAction'

// export const makeLoginRequest= (email, password)=>{
//     return (dispatch) =>{
        // serverAPI.post(`/api/login`, {email, password})
//         .then(e=>{
//             dispatch(login());
//         })
//         .catch(e=>{
//             dispatch(loginError(e.message));
//         })
//     }
// }

export const login=()=>{
    return {
        type: LOGIN_USER
    }
}

export const logout=()=>{
    return (dispatch) =>{
        dispatch(courseLogOutClear());
        dispatch(blogLogOutClear());
        dispatch(logOutUser());
    }
}

const logOutUser= ()=>{
    return {
        type: LOGOUT_USER
    }
}

export const loginError=(error)=>{
    return {
        type: LOGIN_ERROR,
        payload: error
    }
}