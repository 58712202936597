import React from 'react'
import styles from './WorriedAbout.module.css'
import NewCourseButton from '../../common/NewCourseButton';
import { getFormattedPrice } from '../../../../../library';
import IconList from '../Curriculum/IconList';
import { TalkToExpert } from '../../NewSingleCourse';
import { Link } from 'react-router-dom';
let logo1 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small1.svg';
let logo2 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big1.svg';
let logo3 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small2.svg';
let logo4 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big2.svg';
let logo5 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small3.svg';
let logo6 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big3.svg';

let logo7 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small4.svg';
let logo8 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big4.svg';
let logo9 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/small5.svg';
let logo10 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/big5.svg';
let logo11 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo1.svg';
let logo12 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo13.svg';

let logo13 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo6.svg';
let logo14 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo12.svg';
let logo15 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/logo11.svg';
let logo16 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Coforge.svg';
let logo17 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/Peaceful-Progress.svg';
let logo18 = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/finocontrol-recruiters/c4d.svg';

function WorriedAbout({ actualPrice, discountedPrice, offerPrice, enrolLink, courseName }) {
    return (
        <div className='-tw-mt-4 tw-pb-8'>
            <div className='container'>
                <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6`}>
                    <span>Worried about Placements / Securing a job?</span>
                </p>
                <div className='row'>
                    <p>
                        At Finocontrol we create future firm ready leaders with 100% placement assistance, Resume/CV building, LinkedIn Profile Optimization for better visibility in the corporate world and  personalised mentorship. We provide placements opportunities from reputed companies. However the opportunities are not guaranteed. Our CFA charterholders along with Investment Bankers will assist you in working on the corporate live project, and help you to get industry-ready for your interviews. You will also get to Practice Probable Assessments, and Mock Interviews under the supervision of Industry Experts.
                    </p>
                </div>
                <div className="tw-flex tw-my-3">
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo1} alt="company1" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo2} alt="company2" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-75' width={120} src={logo3} alt="company3" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-75' width={120} src={logo4} alt="company4" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-75' width={120} src={logo5} alt="company5" /></div>
                </div>
                <div className="tw-w-full tw-flex">
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo6} alt="company1" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo7} alt="company2" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo8} alt="company3" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-75' width={120} src={logo9} alt="company4" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo10} alt="company5" /></div>
                </div>
                <div className="tw-w-full tw-flex">
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid' width={120} src={logo11} alt="company1" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-90' width={120} src={logo12} alt="company2" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-75' width={120} src={logo13} alt="company3" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-50' width={120} src={logo14} alt="company4" /></div>
                    <div className="tw-w-1/5 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-90' width={120} src={logo15} alt="company5" /></div>
                </div>
                <div className="tw-w-full tw-flex tw-justify-center lg:tw-mt-5">
                    <div className="tw-w-2/6 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-90' width={120} src={logo16} alt="company4" /></div>
                    <span className='tw-w-2/6 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center'><p className='tw-text-sm tw-px-4 tw-py-2 tw-bg-gray-200 tw-rounded-md tw-w-fit'>and many more...</p></span>
                    <div className="tw-w-2/6 tw-mx-1 tw-max-h-[100px] tw-flex tw-justify-center tw-items-center"><img className='img-fluid tw-scale-90' width={120} src={logo17} alt="company5" /></div>
                </div>
                <div className={`${styles.fee_detail_div} tw-mt-8`}>
                    <p className={`tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6`}>
                        <span>Program Fee Details</span>
                    </p>
                    <p className={styles.fee_detail_text}>
                        The Average Price of a {courseName === "Certified Investment Banking Professional Programme" ? "IB" : "FMVA"} Certification in the market is above {getFormattedPrice(40000)}/- <br />Learning from us ! will not only save Money but will Bring Better Career Endevaours
                    </p>
                    <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-mb-0 lg:tw-my-8">
                        <div className="tw-grid tw-bg-transparent md:tw-my-6 tw-mx-4 md:tw-mx-10 tw-flex-grow tw-rounded-box ">
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Get Trained <span className={styles.highlight_text}>with Top 1% Finance Leaders</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'><span className={styles.highlight_text}>Industry Standard</span> Curriculum</span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'><span className={styles.highlight_text}>Practical based learning</span> Approach</span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Lucrative <span className={styles.highlight_text}>Career Opportunities</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Guaranteed <span className={styles.highlight_text}>Finance Internship*</span></span>
                            </div>
                            <div className='tw-flex tw-items-center tw-my-2'>
                                <IconList inCircleColor='#5B1B73' outCircleColor='#263238' />
                                <span className='tw-ml-3'>Avail <span className={styles.highlight_text}>Easy EMI</span>  Options</span>
                            </div>

                        </div>
                        <div className="tw-divider md:tw-divider-horizontal"></div>
                        <div className="tw-mb-6 md:tw-my-6 tw-mx-6 tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center">
                            <p className='tw-text-[#5B1B73] tw-font-extrabold tw-text-base lg:tw-text-lg'>Hurry Up ! <span className='tw-font-medium'> Only Few Seats Left</span></p>
                            
                            {/* for birthday month */}
                            {/* <p className='tw-uppercase tw-my-2 tw-font-bold tw-text-base lg:tw-text-lg'>Actual Price<span className='tw-ml-1 tw-font-semibold'>{getFormattedPrice(actualPrice)}/-*</span></p>
                            <p className='tw-capitalize tw-my-2 tw-text-[#CB33B9] tw-font-extrabold tw-text-lg lg:tw-text-xl tw-flex tw-items-center'>Birthday Month Sale</p>
                            <p className='tw-capitalize tw-my-2 tw-font-extrabold tw-text-xl lg:tw-text-2xl tw-flex tw-items-center'>Discount Upto <span className='tw-text-5xl tw-text-[#05CA6B] tw-font-bold tw-ml-1'>50%</span></p> */}

                            {/* uncomment after birthday month over */}
                            <del className='tw-uppercase tw-my-2 tw-font-bold tw-text-base lg:tw-text-lg'>Actual <span className='tw-font-semibold'>{getFormattedPrice(actualPrice)}/-*</span></del>
                            <p className='tw-uppercase tw-my-2 tw-text-[#CB33B9] tw-font-bold tw-text-lg lg:tw-text-xl tw-flex tw-items-center'>Discounted <span className='tw-ml-2 tw-text-[#773D91] tw-text-xl tw-font-bold'>{getFormattedPrice(discountedPrice)}/-*</span></p>
                            <p className='tw-uppercase tw-my-2 tw-text-[#CB33B9] tw-font-bold tw-text-xl lg:tw-text-2xl tw-flex tw-items-center'>Early Bird Price <span className='tw-ml-2 tw-text-[#773D91] tw-text-3xl tw-font-bold'>{getFormattedPrice(offerPrice)}/-*</span></p>
                            <p className='tw-flex tw-justify-center tw-text-sm tw-text-[#773D91] tw-my-2'>*All prices are inclusive GST</p>
                            <a href={enrolLink} target='__blank' className='tw-btn tw-btn-primary tw-btn-wide tw-my-2'>Enroll Now</a>
                        </div>
                    </div>
                    <div className='tw-flex tw-items-center tw-justify-center tw-pb-8 tw-flex-col md:tw-flex-row tw-gap-6'>
                        <span className='tw-mr-3 tw-mb-3 md:tw-my-0'>Still Confused??</span>
                        <div className='tw-flex tw-flex-col tw-max-w-[70%]'>
                            <div className="tw-dropdown tw-dropdown-hover">
                                <NewCourseButton buttonName="Talk To our Finance Experts" />
                                <ul tabIndex={0} className="tw-dropdown-content !tw-z-[101] tw-menu tw-py-2 tw-rounded-box">
                                    <TalkToExpert />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorriedAbout