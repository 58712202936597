export const instructorsData = [
    {
        _id: 'siddharth.jain',
        name: 'Mr. Siddharth Jain, CFA',
        currCompany: 'Advanced valuations expert',
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/siddharthJainSir.png',
        post: 'Advanced valuations expert',
        stars: 4.8,
        trainees: "4800+",
        yoe: "9+",
        about: 'Siddharth is FMVA designated finance professional with a remarkable track record. He holds the prestigious title of CFA Charterholder and has completed an MBA in finance. With over 11 years of experience, Siddharth has honed his expertise in finance valuation, and analysis. He has made significant contributions to well-known organizations such as S&P, Resurgent, Deep Industries, E&Y, and currently holds the position of Senior Valuation Analyst at RKL LLP, a valued client of Staffing Global Network LLP. In his current role, Siddharth specializes in various areas, including target company valuation for M&A deals, private company valuation, equity compensation valuation, and finance due diligence projects. Recently, he authored a book titled "Business Valuation: A Handy Guide for Beginners and Professionals," which has gained widespread recognition and positive feedback from readers both in India and internationally. Siddharth\'s dedication to professional development extends to his active involvement with the CFA Society India, where he volunteers and serves as a member of the esteemed Public Awareness Committee (PAC).',
    },
    {
        _id: 'shivani.chopra',
        name: 'Ms. Shivani Chopra, CFA',
        currCompany: 'Former jr. AVP at Moody\'s Analytics',
        post: "Former Jr. AVP at Moody's Analytics Knowledge Services",
        stars: 4.9,
        trainees: "5200+",
        yoe: "9+",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/Shivani-maam.png',
        about: 'Ms. Shivani Chopra is a seasoned finance professional with more than 12 years of career track in Private Equity, Investment Management, Corporate Finance and Training. She has worked with organisations like Genpact and Copal Partners (part of Moody’s Analytics) where she gained experience in valuation of Leverage Buyout Deals (LBOs) for US-based PE clients and delivering corporate trainings in investment research areas like equity research, credit research, investment banking and private equity. Presently, Shivani is following her passion for teaching as a trainer for various finance-related courses. Her education credentials include CFA(US), CFA(ICFAI), MA(Economics) and CVFM (Wall Street Prep, US).',
    },
    {
        _id: 'mridula.devrani',
        name: 'Ms. Mridula Devrani, NISM',
        currCompany: 'Product Manager & Investment Specialist',
        post: 'Asst. Product Manager, Freecharge',
        stars: 4.6,
        trainees: "522+",
        yoe: "6+",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/mridula-maam.png',
        about: 'Ms Mridula Devrani is an accomplished finance & management executive with long-standing experience in financial planning and analysis, management training, product management, and market movements & price volatility. She has lent her expertise to companies like Amul, National Dairy Development Board, Nestle & Chegg and polished her skills in financial modelling and investment banking. She has also worked with the London-based Corporate Finance and Strategy consulting group focused on accounting, consulting, and financial services. Ms Mridula has diversified exposure in financial analysis, valuation & modelling, econometrics, and macroeconomic relations, and is keen on sharing her knowledge with other trainees.',
    },
    {
        _id: 'nitin.tulyani',
        name: 'CA Nitin Tulyani',
        currCompany: 'Chartered Accountant & C-Suite Finance Leader',
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/nitinTulyaniSir.png',
        post: 'Chartered Accountant & C-Suite Finance Leader',
        stars: 4.8,
        trainees: "4800+",
        yoe: "15+",
        about: 'Nitin Tulyani is a distinguished professional, holding the esteemed qualifications of a Chartered Accountant and an MBA in Finance. He completed his graduation from ARSD College, Delhi University with a degree in B.(Honours). With an impressive career spanning over 17 years, Nitin has accumulated extensive experience working for renowned Fortune 500 companies such as General Electric, Whirlpool, and Samsung. His expertise encompasses various domains within Finance & Accounting, both at the domestic and international levels. Having operated across EMEA, Asia Pacific, the US, and emerging markets like India, Nitin has acquired a holistic understanding of the global business landscape. Throughout his professional journey, Nitin has exhibited exceptional proficiency in driving financial processes, as well as spearheading the development of comprehensive management reporting frameworks, annual business plans, forecasts, and long-term business strategies. Additionally, he has played a pivotal role in leading global initiatives, facilitating digital transformations, and reinforcing internal financial controls.',
    },
    {
        _id: 'chinmaya.amte',
        name: 'Mr. Chinmaya Amte',
        currCompany: 'Big4 Consultant & Financial Modeler',
        post: 'Asst. Product Manager, Freecharge',
        stars: 4.7,
        trainees: "1360+",
        yoe: "6+",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/Chinmaya.png',
        about: 'Ms Mridula Devrani is an accomplished finance & management executive with long-standing experience in financial planning and analysis, management training, product management, and market movements & price volatility. She has lent her expertise to companies like Amul, National Dairy Development Board, Nestle & Chegg and polished her skills in financial modelling and investment banking. She has also worked with the London-based Corporate Finance and Strategy consulting group focused on accounting, consulting, and financial services. Ms Mridula has diversified exposure in financial analysis, valuation & modelling, econometrics, and macroeconomic relations, and is keen on sharing her knowledge with other trainees.',
    },
    
];