import React from 'react'
import NewCourseButton from '../../common/NewCourseButton'

function Brochure({courseName, brochureLink}) {
  function handleBrochureDownload(e) {
    e.stopPropagation();
    window.open(brochureLink, '__self', )
  }
  return (
    <div className='tw-text-white light_dark_bg tw-pt-2 tw-pb-8 tw-min-h-[calc(100vh/3)] tw-flex tw-justify-center tw-items-center '>
    <div className='container'>
        <div className='tw-flex tw-justify-center tw-items-center tw-flex-col'>
            <p className='tw-capitalize tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-text-center tw-mb-5'>Want to know more about the {courseName} Cohort?</p>
            <NewCourseButton buttonName="Download Brochure" clickHandler={handleBrochureDownload} isBtnLike={true} />
        </div>
    </div>
    
</div>
  )
}

export default Brochure