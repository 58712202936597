import React, { useState } from 'react'
import {useFormik} from 'formik';
import {Helmet} from "react-helmet";

import {contactSchema} from '../../formikSchema/schema.js'
import Toast from '../../helpersComponents/Toast.js';

import './Contact.css'
import location_icon from './assets/icons/location_icon.svg';
import cellphone_icon from './assets/icons/cellphone_icon.svg';
import mail_icon from './assets/icons/mail_icon.svg';
import serverAPI from '../../utils/baseAPI';

function Contact() {
  const contactUsForm= useFormik({
    initialValues: {
      name: '',
      email:'',
      message:'',
      phoneNumber: ''
    },
    validationSchema: contactSchema,
    onSubmit:(value, action)=>{
      sendEmail(value)
      action.resetForm();
    }
  })

  const [toast, setToast]= useState({isSuccess:'', message:''});

  function sendEmail({name, email, message, phoneNumber}){
    serverAPI.post(`/api/contact-us/email`, {
      name,
      email,
      message,
      phoneNumber
    }).then((e)=>{
      //console.log(e);
      setToast({isSuccess:true, message: e.data.msg})
    }).catch((e)=>{
      //console.log(e);
      setToast({isSuccess:false, message: e.response.data.msg});
    }).finally(()=>{
      setTimeout(()=>{
        setToast({isSuccess:'', message: ''});
      }, 4000)
    })
  }
  //console.log(contactUsForm.errors, contactUsForm.values);

  return (
    <div className="tw-mt-16 contact_set_bg tw-min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="Have some questions contact us" />
      </Helmet>

      {(()=>{
        if(toast.isSuccess==="") return <></>;
        else if(toast.isSuccess) return <Toast isSuccess={true} message={toast.message}/>
        else return <Toast isSuccess={false} message={toast.message}/>
      })()}
      <div className="container tw-h-full">
        <div className="row tw-py-10 lg:tw-py-28">
          <div className="col-12 col-lg-6">
            <div className="tw-flex tw-flex-col">
              <div>
                <span className='tw-text-3xl tw-font-bold tw-text-white'>
                  <span>Contact Us</span>
                </span>
                <div className='tw-label-text tw-mt-2 tw-max-w-[407px] tw-text-white'>
                  <span className='tw-opacity-60'> Our financial experts are ready to assist you. Contact us now and let's get the conversation started! </span>
                </div>
              </div>

              <div className='tw-mt-[10%] lg:tw-mt-[40%]'>
                <div className="tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium address_color">
                  Our Office
                </div>
                <div className="tw-mt-3 lg:tw-mt-6 ">
                    <div className='tw-label-text tw-max-w-[407px]'>
                      <span className='tw-opacity-60 tw-flex tw-items-center address_color'><img src={location_icon} alt="icon" /> &nbsp; Chandrasekharpur, Bhubaneswar, Odisa, India</span>
                    </div>
                    <div className='tw-label-text tw-max-w-[407px] tw-my-3'>
                      <span className='tw-opacity-60 tw-flex tw-items-center address_color'><img src={cellphone_icon} alt="icon" /> <a href='tel:+918093555001' className='hover_black' target='__blank'> &nbsp; +91 80935 55001</a> </span>
                    </div>
                    <div className='tw-label-text tw-max-w-[407px] tw-my-3'>
                      <span className='tw-opacity-60 tw-flex tw-items-center address_color'><img src={mail_icon} alt="icon" /> <a href='mailto:hello@finocontrol.com' className='hover_black' target='__blank'>&nbsp; hello@finocontrol.com</a> </span>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="tw-my-5 lg:tw-w-[450px] tw-rounded-2xl tw-bg-white tw-relative tw-z-10">
              <div className="tw-py-10 tw-px-10 drop_shadow tw-rounded-lg ">
                <span className='tw-text-3xl tw-font-bold'>
                  <span>Send a message</span>
                </span>
                <div className='tw-label-text tw-mt-5'>
                  <span className='tw-opacity-60'>Let's talk finance, contact us and let's get smart together</span>
                </div>
                <form onSubmit={contactUsForm.handleSubmit} className='tw-flex tw-justify-center tw-flex-col' >
                  <div className='tw-pt-2'>
                    <span className="tw-label">
                      <label htmlFor="name" className='tw-label-text'>Name <span className='tw-text-red-500'>*</span></label>
                      {contactUsForm.touched.name && contactUsForm.errors.name ? <p className='tw-text-xs tw-text-red-500'>{contactUsForm.errors.name}</p> : <></>}
                    </span>
                    <input name="name" value={contactUsForm.values.name} onChange={contactUsForm.handleChange} required type="text" placeholder="Name" className="tw-input tw-input-bordered tw-w-full " />
                  </div>

                  <div className='tw-pt-2'>
                    <span className="tw-label">
                      <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500'>*</span></label>
                      {contactUsForm.touched.email && contactUsForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{contactUsForm.errors.email}</p> : <></>}
                    </span>
                    <input name="email" value={contactUsForm.values.email} onChange={contactUsForm.handleChange} required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                  </div>

                  <div className='tw-pt-2'>
                    <span className="tw-label">
                      <label htmlFor="phoneNumber" className='tw-label-text'>Contact Number <span className='tw-text-red-500'>*</span></label>
                      {contactUsForm.touched.phoneNumber && contactUsForm.errors.phoneNumber ? <p className='tw-text-xs tw-text-red-500'>{contactUsForm.errors.phoneNumber}</p> : <></>}
                    </span>
                    <input name="phoneNumber" value={contactUsForm.values.phoneNumber} onChange={contactUsForm.handleChange} required type="number" placeholder="Phone Number" className="tw-input tw-input-bordered tw-w-full " />
                  </div>
                  
                  <div className='tw-pt-2'>
                    <span className="tw-label">
                      <label htmlFor="message" className='tw-label-text'>Message <span className='tw-text-red-500'>*</span></label>
                      {contactUsForm.touched.message && contactUsForm.errors.message ? <p className='tw-text-xs tw-text-red-500'>{contactUsForm.errors.message}</p> : <></>}
                    </span>
                    <textarea name="message" value={contactUsForm.values.message} onChange={contactUsForm.handleChange} required placeholder='Your message' className='tw-text-base tw-textarea tw-input-bordered tw-w-full tw-max-h-[160px]' rows={6} />
                  </div>
                  

                  <div className="tw-pt-2">
                    <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
                      <span>Send Message</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Contact