import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom';
import {getAllBlog} from '../../redux/blog/blogAction';
import moment from 'moment'
import {Helmet} from "react-helmet";


function Blog() {
  const blogDispatch= useDispatch();
  const blogsData = useSelector(state => state.blog.allBlog.data) || [];
  //console.log(blogsData);
  useEffect(()=>{
    if (blogsData.length === 0)
      blogDispatch(getAllBlog());
  }, []);

  return (
    <div className='tw-mt-28 tw-mb-16 container tw-min-h-[85vh]'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
        <meta name="description" content="Blogs" />
      </Helmet>
      <div className='text-center'>
        <div className='tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium' >
          Blogs
        </div>
        <div className="row tw-flex tw-items-center tw-justify-between">
          <span className='col tw-text-xs lg:tw-text-sm tw-opacity-70'>
            Seamless reading and learning experience for you on your finger tips
          </span>
        </div>
      </div>

      <div className="tw-my-14 container tw-flex tw-justify-center tw-flex-col lg:tw-block  lg:tw-mx-28 ">
        {blogsData.map(singleBlogItem=>{
          return <div key={singleBlogItem?._id} className=''><BlogCard cardData={singleBlogItem}/></div>
        })}
      </div>
    </div>
  )
}

function BlogCard({cardData}){
  return (
    <div key={cardData._id} className=' tw-my-4 '>
      <div className="tw-card lg:tw-card-side tw-bg-base-100 tw-p-0 lg:tw-max-w-[90%]">
        {/* <div style={{ backgroundImage: `url(${cardData.thumbnail})`, height: '280px', backgroundRepeat: 'no-repeat' }} className="tw-bg-contain tw-w-auto lg:tw-w-[400px] tw-rounded-md">  </div> */}
        <img className='img-fluid tw-w-96' src={cardData.thumbnail} alt="Album" loading='lazy'/>
        <div className="tw-card-body tw-px-0 lg:tw-px-12 tw-py-4 tw-flex tw-flex-col tw-justify-evenly">
          <div className=''>
            <span className=" tw-text-sm tw-font-medium tw-uppercase">{cardData.genre}</span>
            <span className='tw-mx-8 tw-text-xs lg:tw-text-sm tw-opacity-70'>{moment(cardData.createdAt).format("MMMM DD, YYYY")}</span>
          </div>
          <div className='row '>
            <div className='tw-text-base tw-font-medium'>{cardData.title}</div>
          </div>
          <div className='tw-flex tw-items-center '>
            <div className="tw-avatar">
              <div className="tw-w-12 tw-rounded-full ring ring-primary ring-offset-base-100 ring-offset-1">
                <img src={cardData.author_thumbnail} alt="instructor" />
              </div>
              </div>
              <span className='tw-ml-3 tw-text-xs lg:tw-text-sm tw-opacity-95'>{cardData.author}</span>
          </div>
          <div className='tw-card-actions'>
          {/* onClick={() => window.open(courseData.enrolLink, '_blank')}  */}
            <Link to={`/blogs/${cardData._id}`}>
              <button className='tw-my-3 tw-btn tw-btn-sm tw-px-6 tw-font-normal tw-btn-primary tw-text-lg tw-capitalize tw-bg-primary' >
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog