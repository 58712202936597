import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css/navigation';
import "swiper/css";

const testimonialData = [
    {
        _id: 1,
        name: "Prachi",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/prachi.png',
        desc: "I am Grateful to Finocontrol for Inculcating within me a knack for Financial Modelling, Financial Statement Analysis & Equity Research. A sincere Appreciation to all the mentors for teaching everything from the Scratch. Hope this helps me in my Investment Banking and Corporate Finance- Career Trajectory!",
        batch: "FMBV 7 Batch"
    },
    {
        _id: 2,
        name: "Madhav",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/madhav.png',
        desc: "I would definitely recommend the Financial Modeling program at Finocontrol because of the amazing deliverables and the focus at quality learning with live interactions. Also live projects under the guidance of CFA charterholders and Investment Bankers have a serious competitive advantage.",
        batch: "FMBV 7 Batch"
    },
    {
        _id: 3,
        name: "Priyanka",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/priyanka.png',
        desc: "It was a great learning journey with the Finocontrol team. I have not only learnt new practical skills but also gained confidence that, after learning FMBV skills, now I am close to my dream to become Financial Analyst. Thanks to all the respective faculties. I have taken many online courses, but experience with Finocontrol was much better then the other education platform",
        batch: "FMBV 7 Batch"
    },

    {
        _id: 4,
        name: "Mayank Kumar Modi",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Mayank.png',
        desc: 'The program is designed in such a way that people from any field can follow right from the beginning. The sessions are very engaging and the speaker is very appreciate of different ideas and views. The simple real-life examples made understanding the otherwise coplicated financial statements, breeze.',
        batch: "NMIMS Bangalore"
    },
    {
        _id: 5,
        name: "Anish Sharma",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Anish.png',
        desc: 'The training provided by Finocontrol is apt for people wanting to sharpen and be confident with their financial modeling and business valuation skills. The team is highly professional in thier approach. Furthermore, they help candidates with relevant openings in finance industry, and groom them to leave lasting impression on recruiters..',
        batch: "Assistant Manager, CLP India Pvt Ltd"
    },
    {
        _id: 6,
        name: "Ruchi Pattanaik",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Ruchi.png',
        desc: "I am so glad I came across Finocontrol's FRA Trainee program. Starting from the experienced trainers to organised sessions with recording facility, support provided by the whole team, placement assistance, all of these have made the experience amazing. The trainers are best at what they do and the placement opportunity has helped me get into the role of an IB analyst.",
        batch: "Christ University, Banglore"
    },
    {
        _id: 7,
        name: "Harsh Gupta",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Harsh.png',
        desc: 'Being a part of finocontrol is a great opportunity for me. Finocontrol always tried to impart financial knowledge to people And for me, not only the education, Finocontrol also provided job opportunities along with program, I feel blessed to be a part of this program and this organization',
        batch: 'Shaheed Sukhdev College of Business Studies'
    },
    {
        _id: 8,
        name: "Dikhsa Surana",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Diksha.png',
        desc: 'So far the course has been very enriching. the approach to the basics has been great. The course structure has been designed neatly with a proper balance of basics and advanced sessions. The method of teaching in the virtual model by the Trainer has been really good! The class discussions are really insightful!',
        batch: "NMIMS Bangalore, MBA in Finance"
    },
    {
        _id: 9,
        name: "Abhilash Khanzode",
        img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/testimonial-images/Abhilash.png',
        desc: 'I am really enjoying my journey into Financial Modeling and Business Valuation with Finocontrol. The contents of the course are consistent and engaging. The presenter is helpful and has clear and accurate communication, uses effective examples and illustrations. I will really recommend these courses to everyone who wanted to change the field and wanted to explore the finance world',
        batch: "Datta Meghe College of Engineering"
    },
]


function TraineeAboutUs() {
    return (
        <div className='light_dark_bg tw-pt-5 tw-pb-5'>
            <p className='tw-text-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-semibold tw-py-6 tw-text-white'>What Our Trainees Say About Us</p>
            <Swiper
                loop={true}
                speed={1200}
                grabCursor={true}
                centeredSlides={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                spaceBetween={30}
                // pagination={true}
                modules={[Autoplay]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 2,
                        // spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        // spaceBetween: -100
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 3,
                    }
                }}
                className="-md:tw-my-3 container"
            >
                {testimonialData?.map(trainee => {
                    return <SwiperSlide key={trainee?._id} style={{ display: 'flex', justifyContent: "center", height:'auto' }} className=''>
                        <TestimonialCard cardData={trainee} />
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    )
}

function TestimonialCard({ cardData }) {
    return (
        <div className='tw-mx-5 md:tw-mx-0 tw-rounded-2xl tw-flex tw-justify-between tw-flex-col tw-py-3 tw-px-2 lg:tw-py-6 lg:tw-px-10 tw-h-full tw-bg-white'>
            <h2 className='tw-card-title ' style={{ color: '#6440FB', textDecorationThickness: '0.25rem' }}>Our Happy Trainees</h2>
            <p className="tw-my-3 tw-flex tw-justify-center tw-items-center tw-p-0 tw-m-0 tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-80 tw-h-fit">
                {cardData.desc}
            </p>
            <div className='tw-divider tw-mt-0 tw-p-0' />
            <div className='tw-my-3 tw-flex -tw-mt-4'>
                <div className="tw-avatar">
                    <div className="tw-w-20 tw-rounded-full ring ring-primary tw-ring-offset-base-100 tw-ring-offset-2">
                        <img src={cardData.img} alt="person" />
                    </div>
                </div>
                <div className='tw-flex tw-flex-col tw-justify-center tw-ml-4'>
                    <span>{cardData.name}</span>
                    <span className='tw-text-xs lg:tw-text-sm tw-opacity-70'>{cardData.batch}</span>
                </div>
            </div>
        </div>
    )
}


export default TraineeAboutUs