import {ACTIVE_SELF_PACED_COURSE, ALL_SELF_PACED_COURSE, CLEAR_ALL_SELF_PACED_COURSE, NOT_ACTIVE_SELF_PACED_COURSE} from './selfPacedTypes';
import { combineReducers } from 'redux';

const activeSelfPacedCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case ACTIVE_SELF_PACED_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL_SELF_PACED_COURSE: return [];
        default: return state;
    }
}

const notActiveSelfPacedCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case NOT_ACTIVE_SELF_PACED_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL_SELF_PACED_COURSE: return [];
        default: return state;
    }
}
const allSelfPacedCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case ALL_SELF_PACED_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL_SELF_PACED_COURSE: return [];
        default: return state;
    }
}

const selfPacedCourseReducer= combineReducers({
    active: activeSelfPacedCourseReducer, 
    notActive: notActiveSelfPacedCourseReducer, 
    all: allSelfPacedCourseReducer
});

export default selfPacedCourseReducer;