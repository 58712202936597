import React from 'react';
import {Helmet} from "react-helmet";
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
    <div className='container tw-mb-16 tw-mt-24 tw-min-h-[85vh]'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>privacy policy</title>
        <meta name="description" content="privacy policy" />
      </Helmet>
      <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>This privacy policy applies to the finocontrol.com</div>
      <p className='tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{ lineHeight: '1.50rem' }}>
        Finocontrol.com recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on http://www.finocontrol.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.
      </p>

      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        Finocontrol.com recognises the importance of maintaining your privacy. We value your privacy and
        appreciate your trust in us. This Policy describes how we treat user information we collect on
        http://www.finocontrol.com and other offline sources. This Privacy Policy applies to current and
        former visitors to our website and to our online customers. By visiting and/or using our website,
        you agree to this Privacy Policy.
        <div className="tw-pt-3 tw-pb-2">
          Finocontrol.com is a property of Finocontrol Consultancy Services LLP, Chandrasekharpur,
          Bhubaneswar, Odisa, India.
        </div>
      </div>

      <div className="tw-text-xl tw-font-semibold tw-pt-3">Information we collect</div>
      <div>
        <span className="tw-font-semibold"> Contact information.</span> We might collect your{' '}
        <span className="tw-font-semibold tw-text-red-600">
          name, email, mobile number, street, city, state, pincode,country and IP address.
        </span>
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> Payment and billing information.</span> We might collect your billing
        name, billing address and payment method when you buy a ticket. We NEVER collect your credit card
        number or credit card expiry date or other details pertaining to your credit card on our website.
        Credit card information will be obtained and processed by our online payment partner CC Avenue.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">Information you post.</span> We collect information you post in a public
        space on our website or on a thirdparty social media site belonging to Finocontrol.com.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> Demographic information.</span> We may collect demographic information
        about you, events you like, events you intend to participate in, tickets you buy, or any other
        information provided by your during the use of our website. We might collect this as a part of a
        survey also.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> Other information.</span> If you use our website, we may collect
        information about your IP address and the browser you're using. We might look at what site you came
        from, duration of time spent on our website, pages accessed or what site you visit when you leave
        us. We might also collect the type of mobile device you are using, or the version of the operating
        system your computer or device is running.
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">We collect information in different ways.</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> We collect information directly from you.</span> We collect information
        directly from you when you register for an event or buy tickets. We also collect information if you
        post a comment on our websites or ask us a question through phone or email
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> We collect information from you passively.</span> We use tracking tools
        like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information
        about your usage of our website.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We get information about you from third parties.</span> For example, if
        you use an integrated social media feature on our websites. The thirdparty social media site will
        give us certain information about you. This could include your name and email address.
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Use of your personal information.</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> We use information to contact you.</span> We might use the information
        you provide to contact you for confirmation of a purchase on our website or for other promotional
        purposes.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We use information to respond to your requests or questions. </span> We
        might use your information to confirm your registration for an event or contest.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> We use information to improve our products and services.</span> We
        might use your information to customize your experience with us. This could include displaying
        content based upon your preferences.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">
          {' '}
          We use information to look at site trends and customer interests.
        </span>{' '}
        We may use your information to make our website and products better. We may combine information we
        get from you with information about you we get from third parties.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We use information for security purposes.</span> We may use information
        to protect our company, our customers, or our websites. third parties.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We use information for marketing purposes.</span> We might send you
        information about special promotions or offers. We might also tell you about new features or
        products. These might be our own offers or products, or thirdparty offers or products we think you
        might find interesting. Or, for example, if you buy tickets from us we'll enroll you in our
        newsletter.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> We use information to send you transactional communications.</span> We
        might send you emails or SMS about your account or a ticket purchase.
      </div>
      <p>We use information as otherwise permitted by law.</p>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Sharing of information with thirdparties</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">
          {' '}
          We will share information with third parties who perform services on our behalf.
        </span>{' '}
        We share information with vendors who help us manage our online registration process or payment
        processors or transactional message processors. Some vendors may be located outside of India.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We will share information with the event organizers.</span> We share
        your information with event organizers and other parties responsible for fulfilling the purchase
        obligation. The event organizers and other parties may use the information we give them as described
        in their privacy policies.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">We will share information with our business partners..</span> This
        includes a third party who provide or sponsor an event, or who operates a venue where we hold
        events. Our partners use the information we give them as described in their privacy policies.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">
          We may share information if we think we have to in order to comply with the law or to protect
          ourselves.
        </span>{' '}
        We will share information to respond to a court order or subpoena. We may also share it if a
        government agency or investigatory body requests. Or, we might also share information when we are
        investigating potential fraud.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">
          {' '}
          We may share information with any successor to all or part of our business.
        </span>{' '}
        For example, if part of our business is sold we may give our customer list as part of that
        transaction.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold">
          {' '}
          We may share your information for reasons not described in this policy.
        </span>{' '}
        We will tell you before we do this.
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Email OptOut</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <span className="tw-font-semibold"> You can opt out of receiving our marketing emails.</span> To stop
        receiving our promotional emails, please email unsubscriber{' '}
        <span className="tw-font-semibold"> hello@finocontrol.com. </span> It may take about ten days to process
        your request. Even if you opt out of getting marketing messages, we will still be sending you
        transactional messages through email and SMS about your purchases.
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Third party sites</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        If you click on one of the links to third party websites, you may be taken to websites we do not
        control. This policy does not apply to the privacy practices of those websites. Read the privacy
        policy of other websites carefully. We are not responsible for these third party sites.
      </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        <div className="tw-pt-3 tw-pb-2">
          In accordance with Information Technology Act 2000 and rules made there under, the name and
          contact details of the Grievance Officer are provided below:
        </div>
        <p>
          Mr. Subham Mohapatra <br />
          Chandrasekharpur, Bhubaneswar,
          <br />
          Odisa,
          <br />
          India.
          <br />
          Email: hello@finocontrol.com
          <br />
          If you have any questions about this Policy or other privacy concerns, you can also email us at
          <br />
          hello@finocontrol.com
        </p>
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Updates to this policy. </div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        This Privacy Policy was last updated on 05.10.2021. From time to time we may change our privacy
        practices. We will notify you of any material changes to this policy as required by law. We will
        also post an updated copy on our website. Please check our site periodically for updates.
      </div>
      <div className="tw-text-xl tw-font-semibold tw-pt-3">Jurisdiction</div>
      <div className="tw-pt-3 tw-pb-2 tw-opacity-70">
        This Privacy Policy was last updated on 05.10.2021. From time to time we may change our privacy
        practices. We will notify you of any material changes to this policy as required by law. We will
        also post an updated copy on our website. Please check our site periodically for updates.
      </div>
    </div>
  )
}

export default PrivacyPolicy