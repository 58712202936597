import React from 'react'
import play from '../assets/icons/play.svg'
import study from '../assets/icons/study.svg'
import star from '../assets/icons/star.svg'

function InstructorCard({instructor}) {
    return (
        <div className="tw-w-80 lg:tw-w-96 tw-card tw-bg-base-100 tw-shadow-md tw-py-2 tw-max-h-[450px]">
            <figure><img src={instructor?.img} alt="instructor_img" className='img-fluid' /></figure>
            <div className="tw-card-body">
                <h2 className="tw-card-title">{instructor?.name}</h2>
                <p>{instructor?.currCompany}</p>
                <span className='tw-flex tw-text-xs lg:tw-text-sm'>
                    <p className='tw-flex'><img src={star} alt="star" />&nbsp; <p className='tw-opacity-60'>{instructor?.stars}</p></p>
                    <p className='tw-flex'><img src={study} alt="study" />&nbsp; <p className='tw-opacity-60'>{instructor?.trainees} trainees</p></p>
                    <p className='tw-flex'><img src={play} alt="play" />&nbsp; <p className='tw-opacity-60'>{instructor?.yoe} year of Exp</p></p>
                </span>
            </div>
        </div>
    )
}

export default InstructorCard