import React from 'react'

function Toast({isSuccess, message=""}){
    let tempMsg = isSuccess?"Success":"Failed";
    if(message) tempMsg=message;
    return (
      <div className="tw-toast tw-toast-start" style={{zIndex:"1000"}}>
        <div className={`tw-alert ${ isSuccess? 'tw-alert-success' : 'tw-alert-error'}`}>
          <div>
            <span className='tw-text-sm'>{tempMsg}</span>
          </div>
        </div>
      </div>
    )
  }
  
  
export default Toast