import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {Helmet} from "react-helmet";

import barIcon from './assets/bar-chart.svg';
import clockIcon from './assets/wall-clock.svg';
import docIcon from './assets/document.svg';
import calIcon from './assets/calender.svg'

import './Events.css'
import { getActiveEvent, getNotActiveEvent } from '../../redux/event/eventAction';
let nothingImg = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/events-assets/no-event.svg'

function Courses() {
  const [course, setCourse] = useState('ongoing');
  const courseDispatch = useDispatch();

  const ongoingEvent = useSelector(state => state.event.active.data) || [];
  const completedEvent = useSelector(state => state.event.notActive.data) || [];

  useEffect(() => {
    courseDispatch(getActiveEvent());
    courseDispatch(getNotActiveEvent());
  }, []);

  return (
    <div className='container tw-mb-16 tw-mt-24 tw-min-h-[85vh]'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Events</title>
        <meta name="description" content="We are offering you free live event, come join us and learn." />
      </Helmet>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Events Unleashing Your Inner Financial Genius</div>
          <div className='tw-flex tw-justify-between tw-flex-wrap'>
            <div className='tw-text-xs lg:tw-text-sm tw-opacity-60 '>
              Join our sessions for personalized guidance, support, and free discussions with experienced specialists
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 tw-tabs lg:tw-justify-end tw-py-2">
          <span className={`tw-tab tw-tab-lifted ${(course === "ongoing") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse("ongoing")}>Upcoming</span>
          <span className={`tw-tab tw-tab-lifted ${(course === "completed") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse('completed')}>Completed</span>
        </div>
      </div>

      {/* ongoing content */}
      <div className='tw-py-5 row tw-flex tw-justify-center tw-scale-[0.95]'>
        {course === "ongoing" && ongoingEvent.length > 0 && ongoingEvent.map(item => {
          return <CourseCard key={item?._id} courseData={item} />
        })}
        {
          course==="ongoing" && ongoingEvent.length === 0 && (
            <div className='lg:tw-h-[70vh]'>
              <img src={nothingImg} className='tw-h-full img-fluid tw-flex tw-justify-center tw-w-full tw-items-center' alt="empty illustration" />
              <p className='tw-flex tw-justify-center tw-items-center tw-text-base tw-font-medium tw-opacity-70'>Hang on tight - Our upcoming events are just around the corner!</p>
            </div>
          )
        }
        {
          course==="completed" && completedEvent.length === 0 && (
            <div className='lg:tw-h-[70vh]'>
              <img src={nothingImg} className='tw-h-full img-fluid tw-flex tw-justify-center tw-w-full tw-items-center' alt="empty illustration" />
              <p className='tw-flex tw-justify-center tw-items-center tw-text-base tw-font-medium tw-opacity-70'>Hold your belt for a while, rollercoaster ride is just to begin</p>
            </div>
          )
        }

        {course === "completed" && completedEvent.length > 0 && completedEvent.map(item => {
          return <CourseCard key={item?._id} courseData={item} />
        })}
      </div>

    </div>
  )
}

function CourseCard({courseData}) {
  // console.log(courseData);
  return <div key={courseData._id} className='tw-min-w-[400px] col-12 col-md-6 col-lg-4 tw-my-3'>
    {/* tw-flex tw-justify-center tw-items-center tw-w-[100%] lg:tw-w-[50%] tw-my-3 */}
    <div className='tw-rounded-xl tw-bg-base-100 tw-shadow-md tw-p-1'>
      <div className="tw-p-1 tw-card "> 
      {/* lg:tw-card-side */}
        {/* <figure><div className="tw-rounded-xl" style={{ backgroundImage: `url(${courseData.img})`, backgroundSize: 'cover', width: '300px', height: '200px' }} >  </div></figure> */}
        <figure><img className='img-fluid tw-w-96' src={courseData.img} alt="course thumbnail" /></figure>
        <div className="tw-py-8 tw-px-3 lg:tw-px-4 lg:tw-py-3 tw-gap-2 tw-flex tw-flex-col">
          <h2 className=" tw-text-lg tw-mt-3 tw-font-medium ">{courseData.shortName}</h2>

          <div className='tw-flex tw-justify-between tw-gap-5'>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={docIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>{courseData?.courseLength} lesson</p></span>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={clockIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>{courseData?.duration}</p></span>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={barIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>Beginner</p></span>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={calIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-60'>{courseData?.eventDate}</p></span>
          </div>

          {/* <div className='tw-divider tw-m-0 tw-mr-[50%]' /> */}

          <div className="tw-card-actions tw-justify-between tw-mt-5 tw-items-center ">
            <div>
              <del className='tw-opacity-40'>&#x20B9; {courseData.actualPrice}/-</del>
              &nbsp;<span>{courseData.offerPrice===0?"Free":<>&#x20B9; {courseData.offerPrice}</>}</span>
            </div>
            <div>
              {
                courseData.active && (
                  <Link to={`/event/${courseData.eventLinkName || courseData._id}`}><button className='tw-btn tw-btn-xl tw-btn-primary tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-10'>Join</button></Link>
                )
              }
              {
                !courseData.active && (
                  <Link to={`/event/${courseData.eventLinkName || courseData._id}`}><button className='tw-btn tw-btn-xl tw-btn-primary tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-10'>Explore</button></Link>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className='tw-mx-1 tw-my-3 tw-px-3'>
        <div className='tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium'>
          Key Highlights
        </div>
        <div className='tw-mx-4 tw-opacity-80'>
          <ul style={{ listStyleType: "disc" }}>
            {
              courseData?.highlights.map((item)=>{
                return <li className='tw-my-2'>{item}</li>
              })
            }
            
          </ul>
        </div>
      </div>
      
    </div>
  </div>
}

export default Courses