import React from 'react'
import SpecializationCard from './SpecializationCard/SpecializationCard'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css/navigation';
import "swiper/css";
import {motion} from 'framer-motion'
import {downToUpVariant} from '../../animationVariants/variants'


const specializedInCardData = [
    { id: 1, title: 'Financial Modeling', desc: '570+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/equityicon.png' },
    { id: 2, title: 'Business Valuations', desc: '12+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/fmicon.png' },
    { id: 3, title: 'Investment Banking', desc: '6+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/ibicon.png' },
    { id: 4, title: 'Equity Research', desc: '90+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/valuationicon.png' },
    { id: 5, title: 'Wealth Management', desc: '3+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/wealthicon.png' },

    { id: 6, title: 'Financial Modeling', desc: '570+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-a.png' },
    { id: 7, title: 'Business Valuations', desc: '12+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-b.png' },
    { id: 8, title: 'Investment Banking', desc: '6+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-c.png' },
    { id: 9, title: 'Equity Research', desc: '90+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-d.png' },
    { id: 10, title: 'Wealth Management', desc: '3+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-e.png' },
    { id: 11, title: 'Stock Market', desc: '6+ course', img: 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/icon-f.png' },
  ]


function Specialization() {
  return (
    <div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible">

        <div className='tw-flex tw-justify-center tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-medium '>We Specialize In</div>
        <div className='text-center tw-flex tw-justify-center tw-text-xs lg:tw-text-sm tw-opacity-60'>Helping Startups and Companies Structure their Financial Plans</div>

        <motion.div variants={downToUpVariant} viewport={{once:true}} initial="hidden" whileInView="visible" className='tw-my-8 '>
          <Swiper
            breakpoints={{
              1500: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 5,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 30
              },
              750: {
                slidesPerView: 3,
                spaceBetween: 120
              },
              
              650: {
                slidesPerView: 3,
                spaceBetween: 240
              },
              
              550: {
                slidesPerView: 3,
                spaceBetween: 80
              },
              500:{
                slidesPerView:2,
                spaceBetween:20
              },
              450: {
                slidesPerView: 2,
                spaceBetween: 80
              },
              350: {
                slidesPerView: 2,
                spaceBetween:100
              },
              0: {
                slidesPerView: 2,
                spaceBetween:100
              }
            }}
            speed={300}
          >
            {specializedInCardData.map((value, index)=>{
              return <SwiperSlide key={index}><SpecializationCard props={value}/></SwiperSlide>
            })}
            <div className='tw-flex tw-justify-center tw-mt-3'>
              <SwiperButtonPrev>
                <span className='tw-pr-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &larr; </span>
              </SwiperButtonPrev>
              <SwiperButtonNext>
                <span className='tw-pl-4 tw-font-medium hover:tw-font-black tw-flex tw-justify-center tw-transition-all tw-text-2xl'> &rarr;</span>
              </SwiperButtonNext>
            </div>
          </Swiper>
        </motion.div>
    </div>
  )
}

export default Specialization



const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()}>{children}</button>;
  };
  const SwiperButtonPrev = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  };
  