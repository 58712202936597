import React from 'react';
import correctSignIcon from './assets/icons/true_mark.svg'
import grayCircleIcon from './assets/icons/grayCircle.svg'

function Overview({ eventData }) {
  return (
    <div>
      <div className='tw-mt-12 tw-mb-8 md:tw-mt-5 md:tw-mb-2 tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-flex tw-flex-col'>
        Description
      </div>
      
      <p className='overview_css tw-text-xs lg:tw-text-sm tw-opacity-95 tw-mb-8'>
        {eventData.introduction}
      </p>

      <div className='tw-mt-12 tw-mb-8 md:tw-mt-5 md:tw-mb-2 tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-flex tw-flex-col'>
        What you'll learn
      </div>
      <div className='row '>
        {eventData.curriculum.map((oneDetail, index) => {
          return (<div className='col-12 col-md-12' key={index}>
            <div className='tw-flex tw-items-center tw-my-3'>
              <div className="tw-avatar">
                <div className="tw-w-12 lg:tw-w-9 tw-rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src={oneDetail._id.img} alt="instructor" />
                </div>
              </div>
        
              <span className='tw-text-xs lg:tw-text-sm tw-opacity-95 tw-text-[#C12CB4]'>{oneDetail._id.name}</span>
              <span className='tw-pl-4 lg:tw-ml-0 tw-text-xs lg:tw-text-sm tw-opacity-95'>({oneDetail?.date}) IST</span>
              
            </div>
            {
              oneDetail.teach.map((teach, index)=>{
                return <span key={index} className={`tw-w-full col-12 col-lg-6 tw-flex tw-items-center tw-my-2 ${index === (teach.length - 1) ? 'tw-mb-5 ' : ''}`}>
                    <img src={correctSignIcon} alt="correct" className='tw-scale-110 tw-max-w-[22px]' />
                    <p className='tw-w-full tw-ml-3 overview_css tw-text-xs lg:tw-text-sm tw-opacity-95'>{teach}</p>
                  </span>
              })

            }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Overview