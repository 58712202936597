import { createContext, useState, useMemo, useEffect } from "react";
import serverAPI from "../utils/baseAPI";

export const UserContext = createContext();

function UserState({children}) {
  const [activeCourseData, setActiveCourseData] = useState([]);
  
  // const responseData= useMemo(async ()=>{
  //   return await serverAPI.get('/api/course/status/active');
  // }, [])
  // responseData.then(res=>setActiveCourseData(res.data.data[0]))

  const [userState, setUserState] = useState({
    offerHeader: false, //disable or enable the offer header
    offerHeaderOnlyFor: ['/course'], //   /-> means everywhere
    isShowSingleCourseModalDisabled: false, //when the user click on close button it will set to true mean not show again

    //change below one if new course lauching for early bird offer
    courseTrimmedName: 'finance', // used in request a callback component
    courseFullName: 'course', //used in offer navbar and request a callback now form
    //Certified Financial Modeling & Valuations Analyst
    // Certified Investment Banking Professional Cohort
    enrollNowLink: 'https://pages.razorpay.com/fmbv', //used in offer navbar
    earlyOfferEndDate: "2024-12-12" ///used in offer navbar
  })

  // useEffect(()=>{
  //   setUserState(
  //     {
  //       offerHeader: true,
  //       offerHeaderOnlyFor: ['/course'], //   /-> means everywhere
  //       isShowSingleCourseModalDisabled: false, //when the user click on close button it will set to true mean not show again
  //       courseTrimmedName: activeCourseData.trimmedName, // used in requeat a callback component
  //       courseFullName: activeCourseData.name, //used in offer navbar
  //       enrollNowLink: activeCourseData.enrolLink, //used in offer navbar
  //       earlyOfferEndDate: "2024-12-12" ///used in offer navbar
  //     }
  //   )
  // }, [activeCourseData._id, activeCourseData.earlyOfferEndDate, activeCourseData.enrolLink, activeCourseData.name, activeCourseData.trimmedName])

  // console.log(userState);

  return (
    <UserContext.Provider value={{userState, setUserState}}>
      {children}
    </UserContext.Provider>
  )
}

export default UserState