import React from 'react';
import persueCardIcon1 from '../assets/persueCardIcon1.svg';
import persueCardIcon2 from '../assets/persueCardIcon2.svg';
import persueCardIcon3 from '../assets/persueCardIcon3.svg';
import persueCardIcon4 from '../assets/persueCardIcon4.svg';
import persueCardIcon5 from '../assets/persueCardIcon5.svg';
import persueCardIcon6 from '../assets/persueCardIcon6.svg';
import persueCardIcon7 from '../assets/persueCardIcon7.svg';
import persueCardIcon8 from '../assets/persueCardIcon8.svg';
import persueCardIcon9 from '../assets/persueCardIcon9.svg';

const data = [
    {
        _id: 1,
        icon: persueCardIcon1,
        content: 'Corporate standard training & futuristic industry learning approach'
    },
    {
        _id: 2,
        icon: persueCardIcon2,
        content: 'Lifetime access to all the recorded sessions & doubt clearing'
    },
    {
        _id: 3,
        icon: persueCardIcon3,
        content: 'Comprehensive content support material & peer learning'
    },
    {
        _id: 4,
        icon: persueCardIcon4,
        content: 'Build your real-time industry experience'
    },
    {
        _id: 5,
        icon: persueCardIcon5,
        content: 'Live training & interaction with industry experts for doubt sessions'
    },
    {
        _id: 6,
        icon: persueCardIcon6,
        content: 'Personalized Career Guidance'
    },
    {
        _id: 7,
        icon: persueCardIcon7,
        content: 'Quizzes & case studies'
    },
    {
        _id: 8,
        icon: persueCardIcon8,
        content: 'Overall personality development'
    },
    {
        _id: 9,
        icon: persueCardIcon9,
        content: 'Affordable Pricing & EMI Options'
    },
]

function PersueCard({color}) {
    if(!color) {
        color="black";
    }
    return (
        <div className='row'>
            {data && data?.map(item => {
                return <div className='col-12 col-sm-6 col-lg-4 tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-5'>
                    <div className='tw-w-[250px] tw-flex tw-flex-col tw-items-center'>
                        <img src={item.icon} alt="persue icon" width={100} className='tw-flex tw-justify-center tw-items-center tw-my-6' />
                        <p className={`tw-text-center tw-text-${color}`}>{item.content}</p>
                    </div>
                </div>
            })}
        </div>
    )
}

export default PersueCard