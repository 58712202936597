import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {Helmet} from "react-helmet";
import {getFormattedPrice} from '../../library/index.js'

import barIcon from './assets/bar-chart.svg';
import clockIcon from './assets/wall-clock.svg';
import docIcon from './assets/document.svg';
import './Courses.css'
import { getActiveCourse, getNotActiveCourse } from '../../redux/course/courseAction';
import {UserContext} from '../../userContext/UserState'

function Courses() {
  const [course, setCourse] = useState('ongoing');
  const courseDispatch = useDispatch();

  const {hash} = useLocation();
  const hashValue= hash.split("#").join("");

  const {userState}= useContext(UserContext)

  const ongoingCourse = useSelector(state => state.course.active.data) || [];
  const upcomingCourse = useSelector(state => state.course.notActive.data) || [];

  useEffect(() => {
    courseDispatch(getActiveCourse());
    courseDispatch(getNotActiveCourse());
  }, []);

  useEffect(()=>{
    if(hashValue && (hashValue === 'ongoing' || hashValue === 'upcoming')) {
      setCourse(hashValue);
    }
  }, [hashValue])
  
  return (<>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Corporate Training</title>
        <meta name="description" content="Exclusive specialized, Futuristic & Industry ready Programmes designed for Students & Working professionals" />
      </Helmet>

    <div className={`container ${userState.offerHeader===true? 'tw-mt-48 md:tw-mt-32' : 'tw-mb-16 tw-mt-24' } tw-min-h-[85vh]`}>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Explore our Flagship Corporate Express pathway</div>
          <div className='tw-flex tw-justify-between tw-flex-wrap'>
            <div className='tw-text-xs lg:tw-text-sm tw-opacity-60 '>Exclusive specialized, Futuristic & Industry ready Programmes designed for Students & Working professionals</div>
          </div>
        </div>
        <div className="col-12 col-lg-4 tw-tabs lg:tw-justify-end tw-py-2">
          <a href='#ongoing' className={`tw-tab tw-tab-lifted ${(course === "ongoing") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse("ongoing")}>Ongoing</a>
          <a href='#upcoming' className={`tw-tab tw-tab-lifted ${(course === "upcoming") ? "tw-tab-active active_tab" : ""}`} onClick={() => setCourse('upcoming')}>Upcoming</a>
        </div>
      </div>

      {/* ongoing content */}
      <div className='tw-py-5 row tw-flex tw-justify-center'>
        {course === "ongoing" && ongoingCourse.length > 0 && ongoingCourse.map(item => {
          return <CourseCard key={item?._id} courseData={item} />
        })}

        {course === "upcoming" && upcomingCourse.length > 0 && upcomingCourse.map(item => {
          return <CourseCard key={item?._id} courseData={item} />
        })}
      </div>

    </div>
  </>)
}

function CourseCard({courseData}) {
  return <div key={courseData._id} className='tw-min-w-[400px] col-12 col-md-6 col-lg-4 tw-my-3 tw-scale-[0.95]'> 
  {/* tw-flex tw-justify-center tw-items-center tw-w-[100%] lg:tw-w-[50%] */}
    <div className='tw-rounded-xl tw-bg-base-100 tw-shadow-md tw-p-1'>
      <div className="tw-p-1 tw-card "> 
      {/* lg:tw-card-side */}
        {/* <figure><div className="tw-rounded-xl" style={{ backgroundImage: `url(${courseData.img})`, backgroundSize: 'cover', width: '300px', height: '200px' }} >  </div></figure> */}
        <figure><img className='img-fluid tw-w-96' src={courseData.img} alt="course thumbnail" /></figure>
        <div className="tw-pt-4 tw-pb-2 md:tw-pt-8 md:tw-pb-4 tw-px-3 lg:tw-px-4 lg:tw-py-3 tw-gap-2 tw-flex tw-flex-col">
          {/* <div className="tw-flex tw-rating tw-rating-xs tw-items-center">
            <div className='tw-text-xs lg:tw-text-sm '> {courseData.rating} &nbsp;</div>
            <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
            <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
            <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
            <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" disabled />
            <input type="radio" name="rating-5" className="tw-mask tw-mask-star-2 tw-bg-orange-400" defaultChecked />
          </div> */}
          <h2 className=" tw-text-lg tw-mt-3 tw-font-medium ">{courseData.shortName}</h2>

          <div className='tw-flex tw-justify-between'>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={docIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>{courseData?.lessonCount || "12+"} Lessons</p></span>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={clockIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>{courseData?.duration}</p></span>
            <span className='tw-flex tw-items-center'> <img loading="eager" src={barIcon} alt="l" /> <p className='tw-pl-1 tw-text-xs lg:tw-text-sm tw-opacity-60'>Beginner to Advanced</p></span>
          </div>

          {/* <div className='tw-divider tw-m-0 tw-mr-[50%]' /> */}

          <div className="tw-card-actions tw-justify-between tw-mt-5 tw-items-center ">
            <div>
              {/* birthday change */}
              {/* <del className='tw-text-lg tw-font-bold'>{getFormattedPrice(courseData.actualPrice)}/-</del> */}
              {/* <p className='text_xxs tw-opacity-60'>(Claim Discount at the Checkout page)</p> */}
              <del className='tw-opacity-40'>&#x20B9; {courseData.actualPrice}/-</del>
              &nbsp;<span className='tw-font-bold'>{getFormattedPrice(courseData.offerPrice)}/-*</span>
            </div>
            <div>
              {
                courseData.active && courseData?.hasData &&(
                  <Link to={`/course/${courseData.courseLinkName? courseData.courseLinkName: courseData._id}`}><button className='tw-btn tw-btn-xl tw-btn-primary tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-10'>Join</button></Link>
                )
              }
              {
                !courseData.active && courseData?.hasData &&(
                  <Link to={`/course/${courseData.courseLinkName? courseData.courseLinkName: courseData._id}`}><button className='tw-btn tw-btn-xl tw-btn-primary tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-px-10'>Explore</button></Link>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div  className='tw-divider tw-m-0 tw-my-0'/> */}
      <div className='tw-mx-1 tw-my-3 tw-px-3'>
        <div className='tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium'>
          Key Highlights
        </div>
        <div className='tw-mx-4 tw-opacity-80'>
          <ul style={{ listStyleType: "disc" }}>
            <li className='tw-my-2 tw-text-[14px]'>Work on Case studies and real-world examples</li>
            <li className='tw-my-2 tw-text-[14px]'>Hands-on experience in using financial tools and software</li>
            <li className='tw-my-2 tw-text-[14px]'>Guaranteed Finance Internship</li>
            <li className='tw-my-2 tw-text-[14px]'>100% Job/Placement Assistance</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
}

export default Courses