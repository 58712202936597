import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';

export default function BirthDayMonthCeleb({ setBirthdayShowModal, setShowRequestCallback }) {
    useEffect(()=>{
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/@tsparticles/confetti@3.0.3/tsparticles.confetti.bundle.min.js';
      script.async = true;
  
      script.onload = () => {
        if (window.confetti) {
          const confetti= window.confetti;
  
          // peronalize design
          function fireAtAngle(angle, origin) {
            const count = 200;
            function fire(particleRatio, opts) {
              confetti(
                Object.assign({}, {}, opts, {
                  particleCount: Math.floor(count * particleRatio),
                  angle: angle,
                  origin: origin
                })
              );
            }
  
            fire(0.30, {
              spread: 30,
              startVelocity: 55,
            });
  
            fire(0.25, {
              spread: 65,
            });
  
            fire(0.4, {
              spread: 105,
              decay: 0.91,
              scalar: 0.8,
            });
  
            fire(0.15, {
              spread: 125,
              startVelocity: 25,
              decay: 0.92,
              scalar: 1.2,
            });
  
            fire(0.15, {
              spread: 125,
              startVelocity: 45,
            });
          }
  
          fireAtAngle(45, {x:0, y:0.5});
          fireAtAngle(135, {x:1, y:0.5});
  
  
  
  
          //sourav sir 
        //   const end = Date.now() + 7 * 1000;
  
        //   // go Buckeyes!
        //   const colors = ["#bb0000", "#ffffff"];
  
        //   (function frame() {
        //     confetti({
        //       particleCount: 9,
        //       angle: 60,
        //       spread: 55,
        //       origin: { x: 0 },
        //       colors: colors,
        //     });
  
        //     confetti({
        //       particleCount: 9,
        //       angle: 120,
        //       spread: 55,
        //       origin: { x: 1 },
        //       colors: colors,
        //     });
  
        //     if (Date.now() < end) {
        //       requestAnimationFrame(frame);
        //     }
        //   })();
  
        }
      };
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, [])
  
    function handlecloseModal() {
      setBirthdayShowModal(false);
      // setUserState({ ...userState, isShowBirthDayCelebDisabled: true });
    }
  
    return (<>
      <div className='tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center tw-fixed tw-z-[9999] tw-backdrop-blur-sm' onClick={() => setBirthdayShowModal(false)}>
        <div className="tw-card md:tw-card-side tw-bg-base-100 tw-shadow-xl" style={{backgroundColor:'#ECE5FF'}} onClick={(e) => e.stopPropagation()}>
          <button className="tw-btn tw-btn-md tw-btn-ghost tw-btn-circle tw-absolute tw-right-0" onClick={handlecloseModal}>
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="1.0563" y1="1.09603" x2="13.096" y2="15.5437" stroke="#570DF8" stroke-width="1.5" stroke-linecap="round"/>
              <line x1="0.75" y1="-0.75" x2="19.5567" y2="-0.75" transform="matrix(-0.640184 0.768221 0.768221 0.640184 14 1)" stroke="#570DF8" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </button>
          {/* <figure className='md:tw-w-1/2 d-none d-md-flex'><img className='tw-rounded-l-2xl'  alt="Album" /></figure> */}
          <div className="tw-w-[340px] md:tw-w-[500px] tw-card-body" style={{padding: '2rem 1rem !important'}}>
            <div className="container-fluid tw-flex tw-items-center tw-justify-center tw-flex-col">
              <div className='tw-my-1'>
                <div className='tw-flex tw-items-center tw-justify-center tw-text-xl md:tw-text-2xl tw-font-black'>Birthday Month Sale</div>
                <div className='tw-mt-4 tw-flex tw-items-center tw-justify-center tw-text-sm tw-font-bold'>Discount upto</div>
                <div className='tw-text-6xl md:tw-text-7xl tw-flex tw-items-center tw-justify-center tw-text-[#05CA6B] tw-font-bold'>50%</div>
                <div className='tw-mt-4 tw-text-xs md:tw-text-sm tw-flex tw-items-center tw-justify-center tw-font-bold'>Get access to this limited time offer</div>
                <div className='tw-hidden md:tw-flex tw-mt-4 tw-text-xs tw-items-center tw-justify-center'>You’re running out of time, not really! Our offer is super limited.</div>

                <div className='tw-flex md:tw-hidden tw-mt-4 tw-text-xs  tw-items-center tw-justify-center'>You’re running out of time, not really!</div>
                <div  className='tw-flex md:tw-hidden tw-text-xs tw-items-center tw-justify-center'>Our offer is super limited.</div>

                <div className='tw-mt-4 tw-flex tw-items-center tw-justify-center '><Link to={'/course'}><button onClick={()=>setBirthdayShowModal(false)} className='tw-px-6 tw-btn tw-btn-primary' style={{ textTransform: 'capitalize' }}>Claim your Discount</button></Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }
  