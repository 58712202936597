import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {useFormik} from 'formik';
import {registerUser} from '../../formikSchema/schema';
import {Helmet} from "react-helmet";

import './SingleEvent.css';
import serverAPI from '../../utils/baseAPI';
import Spinner from '../../helpersComponents/Spinner';

import clockIcon from './assets/icons/clock.svg'
import playButtonIcon from './assets/icons/playButton.svg'
import videoFileIcon from './assets/icons/video-file.svg'
import puzzleIcon from './assets/icons/puzzle.svg'
import barChartIcon from './assets/icons/bar-chart.svg'
import translateIcon from './assets/icons/translate.svg'
import badgeIcon from './assets/icons/badge.svg'
import infinityIcon from './assets/icons/infinity.svg'
import CaseStudyIcon from './assets/icons/CaseStudy.svg'
import CorporateLiveProjectIcon from './assets/icons/CorporateLiveProject.svg'
import InternshipIcon from './assets/icons/Internship.svg'
import learningIcon from './assets/icons/learning.svg'


import Overview from './Overview';
import Instructors from './Instructors';
import ErrorPage from '../ErrorPage/ErrorPage';

let tw = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/tw.svg'
let linkedin = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/in.svg'
let ig = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/ig.svg'
let fb = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/fb.svg'
function SingleEvent() {

  const [eventData, setEventData] = useState([]);
  const location = useLocation();
  const pathSplit = location.pathname.split('/');
  const eventId = pathSplit[pathSplit.length - 1];
  const [serverRes, setServerRes]= useState('');
  const [loading, setLoading]= useState(true);

  useEffect(() => {
    serverAPI.get(`/api/event/${eventId}`).then(e => {
      // console.log(e);
      let tempEventData= e.data.data[0];
      tempEventData.curriculum = tempEventData.curriculum.sort((firstIns, secondIns)=> secondIns._id.date - firstIns._id.date);

      setEventData(tempEventData);
    }).catch(e => {
      console.log(e);
    }).finally(()=>{
      setLoading(false);
    })
  }, [eventId]);
  // console.log(eventData);
  function clearRadioChecked (){
    document.getElementById('radio1').checked=false
    document.getElementById('radio2').checked=false
    document.getElementById('radio3').checked=false
    document.getElementById('radio4').checked=false
    document.getElementById('radio5').checked=false
    document.getElementById('radio6').checked=false
    document.getElementById('radio7').checked=false
    document.getElementById('radio8').checked=false
    document.getElementById('radio9').checked=false
  }

  const registerForm = useFormik({
    validationSchema: registerUser,
    initialValues: {
      name: '',
      email: '',
      whatsAppNumber: '',
      contactNumber: '',

      studentOrWorking: '',
      academicCourse: '', //select
      graduationYear: '',
      careerInCourse: '----',
      previousExperience: '----'
    },
    onSubmit: (value, action) => {
      // console.log(value);
      serverAPI.post(`api/event/${eventId}/register`, value)
      .then(e=>{
        // console.log(e);
        setServerRes(e.data.msg);
        action.resetForm();
        document.getElementById('closeForm').click();
        document.getElementById('confirm_request').click();
        clearRadioChecked();
      })
      .catch(e=>{
        // setToast({isSuccess:false, message: e?.response?.data?.msg});
      })
    }
  });
  // console.log(eventData);
  const [tab, setTab] = useState('Overview');

  if(!loading && eventData.length>0) return <>
    <ErrorPage/>
  </>

  return loading ? <Spinner /> : (<>
     <Helmet>
         <title>{eventData?.name}</title>
        <meta name="description" content={eventData?.shortName} />
      </Helmet>
 
    <span className='single_course' style={{zIndex:3000}}>

      <div className="tw-modal tw-min-w-fit" id="confirm_model">
        <div className="tw-modal-box tw-w-11/12 tw-max-w-5xl">
          <h3 className="tw-font-bold tw-text-lg">🎉Congratulations on enrolling.</h3>
          <p className="tw-pt-4 tw-pb-2">
            Congratulations on Enrolling! Join Our WhatsApp Group for Updates <a className='hover_black tw-underline tw-font-bold' href={`${serverRes}`} >Click Here</a>.
            <br/>
            We have also sent you the email for the same.
            <br/>
            Thanks for choosing Finocontrol.
          </p>
          <div className="tw-modal-action">
          <a href="#" className="tw-btn" onClick={()=>setServerRes("")}>Close</a>
          </div>
        </div>
      </div>
      <a href="#confirm_model" id='confirm_request' className="tw-btn tw-hidden"></a>

        {/* The button to open modal */}
      <div className='tw-mt-24 lg:tw-mt-32 tw-rounded-lg container lg:tw-bg-[#6440FB] lg:tw-h-[400px] tw-mb-8 '>
        <div className='lg:tw-mx-24 tw-h-full'>
          <div className='row tw-h-full'>
            <div className='col-12 col-lg-8 tw-px-4 tw-bg-[#6440FB] tw-rounded-lg tw-py-16 tw-text-white tw-h-full tw-flex tw-justify-center tw-flex-col'>
              <div className='tw-flex'>
                <ToolTip bgColor='#00FF84' contentColor='black' content='Free Crash course' />
                <ToolTip bgColor='#E8543E' contentColor='white' content='new' addClass='mx-2' />
                <ToolTip bgColor='#4417FF' contentColor='white' content='trending' />
              </div>
              <div className='tw-my-3 tw-text-base md:tw-text-xl lg:tw-text-2xl tw-font-medium '>
                {eventData.name}
              </div>
              <div className='tw-mt-3 tw-mb-1 tw-text-sm md:tw-text-base lg:tw-text-xl tw-font-medium '>
                Free Crash course
              </div>
              <div className='lg:tw-flex lg:tw-flex-row tw-flex-col tw-my-3'>
                <span className='tw-flex tw-items-center tw-mr-4 tw-my-4 lg:tw-my-0 tw-mx-0'> <img loading="eager" src={learningIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-95'> {eventData?.enrolledStudents}+ enrolled in this course</p></span>
                <span className='tw-flex tw-items-center'> <img loading="eager" src={clockIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm tw-opacity-95'> {eventData.duration} </p></span>
              </div>
              
              <div className='row '>
                {
                  eventData.curriculum.map((oneDetail, index)=>{
                    return (
                      <div key={oneDetail._id._id} className={`col-3 tw-min-w-fit tw-flex tw-items-center tw-my-3`}>
                        <div className="tw-avatar">
                          <div className="tw-w-12 tw-rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                            <img src={oneDetail._id.img} alt="instructor" />
                          </div>
                        </div>
                        <div className="tw-flex tw-flex-col tw-ml-2 tw-justify-evenly">
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-95'>{oneDetail._id.name}</span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-95'>( {oneDetail.date} ) IST</span>
                        </div>
                      </div> 
                    )
                  })
                }
              </div>
            </div>
            
            <div className='col-12 col-lg-4'>
              <div className='card_shadow tw-shadow-md tw-p-1 tw-bg-base-100 tw-flex tw-justify-center tw-w-full tw-mt-16 lg:tw-mt-28 tw-rounded-md'>

                <div className="tw-card tw-w-full">
                  <a a href="#my_modal_8" className={`tw-rounded-md tw-flex tw-justify-center tw-items-center card_main_video_hover`} style={{ backgroundSize: 'cover', width: '100%', height: '200px', backgroundImage: `url(${eventData.img})` }} >
                    <img src={playButtonIcon} alt="play button icon" />
                  </a>

                  <div className='container tw-my-3'>
                    <span className='tw-my-2 tw-flex tw-justify-around tw-items-center'>
                      <span className='tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-capitalize'> FREE</span>
                      <del className='tw-opacity-40 tw-text-sm'>&#x20B9; {eventData.actualPrice}/-</del>
                    </span>

                    {/* The button to open modal */}
                    {
                    eventData.active && <div>
                      <a href="#my_modal_8" className="tw-capitalize tw-my-1 tw-text-xs tw-btn tw-w-full tw-btn-primary tw-bg-[#E8543E] tw-border-[#E8543E] hover:tw-bg-[#E8543E] hover:tw-text-black hover:tw-border-[#E8543E] hover:tw-opacity-80">Join now</a>
                      <div className="tw-modal tw-pt-12" style={{zIndex: 2000}} id="my_modal_8">
                        <div className="tw-modal-box tw-py-16">
                        <a href="#" className="tw-btn tw-btn-sm tw-btn-circle tw-btn-ghost tw-absolute tw-right-2 tw-top-2" >✕</a>
                          <form onSubmit={registerForm.handleSubmit}>
                            <h3 className="tw-font-bold tw-text-lg">{eventData?.formHeader}</h3>
                            <p className="tw-py-4">
                              {
                                <div
                                  dangerouslySetInnerHTML={{__html: eventData?.formDesc}}
                                />
                              }
                            </p>
                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="name" className='tw-label-text'>Full Name <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.name && registerForm.errors.name ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.name}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.name} name="name" required type="text" placeholder="Your Full Name" className="tw-input tw-input-bordered tw-w-full " />
                            </div>

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.email && registerForm.errors.email ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.email}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.email} name="email" required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                            </div>

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="whatsAppNumber" className='tw-label-text'>WhatsApp Number <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.whatsAppNumber && registerForm.errors.whatsAppNumber ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.whatsAppNumber}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.whatsAppNumber} name="whatsAppNumber" required type="text" placeholder="Your WhatsApp Number" className="tw-input tw-input-bordered tw-w-full " />
                            </div>

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="contactNumber" className='tw-label-text'>Contact Number <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.contactNumber && registerForm.errors.contactNumber ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.contactNumber}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.contactNumber} name="contactNumber" required type="text" placeholder="Your Contact Number" className="tw-input tw-input-bordered tw-w-full " />
                            </div>

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="graduationYear" className='tw-label-text'>Year of Graduation <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.graduationYear && registerForm.errors.graduationYear ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.graduationYear}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.graduationYear} name="graduationYear" required type="number" placeholder="Graduation Year" className="tw-input tw-input-bordered tw-w-full " />
                            </div>

                            {/* <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="previousExperience" className='tw-label-text'>Do you have any previous working experience in the field of finance? If yes, please specify the domain?</label>
                                {registerForm.touched.previousExperience && registerForm.errors.previousExperience ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.previousExperience}</p> : <></>}
                              </span>
                              <input onChange={registerForm.handleChange} value={registerForm.values.previousExperience} name="previousExperience" type="text" placeholder="" className="tw-input tw-input-bordered tw-w-full " />
                            </div> */}

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="academicCourse" className='tw-label-text'>Highest Academic Study Completed/ Pursuing? <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.academicCourse && registerForm.errors.academicCourse ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.academicCourse}</p> : <></>}
                              </span>
                              {/* <input onChange={registerForm.handleChange} value={registerForm.values.academicCourse} name="academicCourse" required type="text" placeholder="Academic Course" className="tw-input tw-input-bordered tw-w-full " /> */}
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="BCom/BBA/BA/BSC" name="academicCourse" id="radio5" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="BCom/BBA/BA/BSC">BCom/BBA/BA/BSC</label>
                              </span>
                              
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="MBA/PGDM" name="academicCourse" id="radio6" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="MBA/PGDM">MBA/PGDM</label>
                              </span>
                              
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="CA/CMA/ACCA" name="academicCourse" id="radio7" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="CA/CMA/ACCA">CA/CMA/ACCA</label>
                              </span>
                              
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="CFA/CFP/FRM" name="academicCourse" id="radio8" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="CFA/CFP/FRM">CFA/CFP/FRM</label>
                              </span>

                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="Other" name="academicCourse" id="radio8" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="Other">Other</label>
                              </span>
                              

                            </div>

                            {/* <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="careerInCourse" className='tw-label-text'>Are you willing to build a career in {eventData?.trimmedName}? <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.careerInCourse && registerForm.errors.careerInCourse ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.careerInCourse}</p> : <></>}
                              </span>
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="Yes" name="careerInCourse" required id="radio1" type="radio" placeholder="" className="tw-radio" /> &nbsp;<label className='tw-label-text' for="Yes">Yes</label>
                              </span>
                              
                              <span className='tw-flex'>
                                <input onChange={registerForm.handleChange} value="No" name="careerInCourse" required id="radio2" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className='tw-label-text' for="No">No</label>
                              </span>
                            </div> */}

                            <div className='tw-pt-1'>
                              <span className="tw-label">
                                <label htmlFor="studentOrWorking" className='tw-label-text'>Are you a student or working professional? <span className='tw-text-red-500 tw-font-semibold'>*</span></label>
                                {registerForm.touched.studentOrWorking && registerForm.errors.studentOrWorking ? <p className='tw-text-xs tw-text-red-500'>{registerForm.errors.studentOrWorking}</p> : <></>}
                              </span>
                              <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="Student" name="studentOrWorking" required id="radio3" type="radio" placeholder="" className="tw-radio" /> &nbsp;<label className="tw-label-text" for="Student">Student</label>
                              </span>
                              
                              <span className='tw-flex'>
                                <input onChange={registerForm.handleChange} value="WorkingProfessional" name="studentOrWorking" required id="radio4" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label className="tw-label-text" for="WorkingProfessional">Working professional</label>
                              </span>
                              {/* <span className='tw-flex tw-my-2'>
                                <input onChange={registerForm.handleChange} value="other" name="studentOrWorking" required id="radio5" type="radio" placeholder="" className="tw-radio " /> &nbsp;<label for="Other">Other</label>
                              </span> */}

                            </div>
                            <span className='row tw-justify-between tw-mt-6'>
                              <span className="col-5 tw-pt-2">
                                <button type="submit" className='tw-btn tw-btn-secondary tw-w-[100%] tw-capitalize'>
                                  <span>Submit</span>
                                </button>
                              </span>
                              <span className="col-5 tw-pt-2">
                                <a id="closeForm" href="#" className="tw-btn tw-w-[100%] tw-capitalize tw-btn-ghost tw-text-error">Close</a>                            
                              </span>
                            </span>
                          </form>
                          <div className="tw-modal-action">
                          {/* <a href="#" className="tw-btn">Close</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    {
                      eventData.active && <button className='tw-capitalize tw-my-1 tw-text-xs tw-btn tw-w-full tw-btn-primary  tw-bg-[#4417FF] tw-border-[#4417FF]  hover:tw-bg-[#4417FF] hover:tw-border-[#4417FF] hover:tw-opacity-80' onClick={() => window.open(eventData.enrollLinkWithCert, '_blank')}  >
                        Join now ( Certificate )
                      </button>
                    }
                    {
                      eventData.active && <button className='tw-capitalize tw-my-1 tw-text-xs tw-btn tw-w-full tw-btn-primary tw-bg-[#00FF84] tw-border-[#00FF84] hover:tw-bg-[#00FF84] tw-text-black hover:tw-border-[#00FF84] hover:tw-opacity-80' onClick={() => window.open(eventData.enrollLinkWithCertAndRec, '_blank')}  >
                        Join now ( Certificate & recording )
                      </button>
                    }
                    <div className='tw-my-6'>
                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={videoFileIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Lesson </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>{eventData?.courseLength}</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={puzzleIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Quizzes </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>NA</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={clockIcon} className='tw-invert' alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Duration </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>{eventData.duration}</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={barChartIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Skill level </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>Beginner</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={translateIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Language </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>English</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={badgeIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Certificate </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>Restricted</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={infinityIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Full lifetime access </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>Restricted</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={CorporateLiveProjectIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Corporate Live Project </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>No</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>
                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={CaseStudyIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Case Study </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>Yes</span>
                        </div>
                        <div className="tw-divider tw-my-1"></div>
                      </div>

                      <div>
                        <div className='tw-flex tw-justify-between'>
                          <span className='tw-flex tw-items-center'> <img loading="eager" style={{width:16, height: 16}} src={InternshipIcon} alt="l" /> <p className='tw-pl-2 tw-text-xs lg:tw-text-sm'> Internship </p></span>
                          <span className='tw-text-xs lg:tw-text-sm tw-opacity-80'>No</span>
                        </div>
                        {/* <div className="tw-divider tw-mt-1"></div> */}
                      </div>
                    </div>
                    <span className="tw-flex tw-justify-center tw-h-fit tw-invert">
                      <a href="https://www.facebook.com/finocontrol" rel="noreferrer noopener" target='__blank' className="tw-mx-1 tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"><img src={fb} alt="" /></a>
                      <a href="https://twitter.com/finocontrol" rel="noreferrer noopener" target='__blank' className="tw-mx-1 tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"><img src={tw} alt="" /></a>
                      <a href="https://www.instagram.com/finocontrol/" rel="noreferrer noopener" target='__blank' className="tw-mx-1 tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"><img src={ig} alt="" /></a>
                      <a href="https://www.linkedin.com/company/finocontrol" rel="noreferrer noopener" target='__blank' className="tw-mx-1 tw-btn tw-btn-ghost tw-btn-circle tw-transition-colors hover:tw-bg-[#FFFFFF26]"><img src={linkedin} alt="" /></a>
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container tw-my-10'>
        <div className='row col-lg-8 lg:tw-px-10'>
          <div className="tw-pr-16 lg:tw-pr-0 lg:tw-ml-0 tw-tabs tw-flex tw-flex-nowrap tw-overflow-x-scroll tw-overflow-y-hidden allow_horizontal_scroll">
            <span className={`tw-tab tw-tab-bordered tab_hover tw-min-w-fit ${tab === "Overview" ? 'tw-tab-active' : ''}`} onClick={() => setTab('Overview')}>Overview</span>
          </div>

          <Overview eventData={eventData} />
          <Instructors eventData={eventData} />

        </div>
      </div>
    </span>
  </>)
}

function ToolTip({ bgColor = "black", contentColor = "white", content = "something", addClass = "" }) {
  return (
    <span className={`${addClass} tw-rounded-full tw-py-2 tw-px-3 tw-uppercase tw-text-xs tw-font-medium`} style={{ backgroundColor: bgColor, color: contentColor }}>
      {content}
    </span>
  )
}

export default SingleEvent