import { combineReducers } from 'redux'
import loginReducer from './login/loginReducers';
import courseReducer from './course/courseReducer';
import blogReducer from './blog/blogReducer';
import eventReducer from './event/eventReducer';
import selfPacedCourseReducer from './self-paced/selfPacedReducer';

const rootReducer = combineReducers({
    login: loginReducer,
    course: courseReducer,
    event: eventReducer,
    blog: blogReducer,
    selfPacedCourse: selfPacedCourseReducer
})

export default rootReducer;