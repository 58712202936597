import React from 'react'
import {useCountdown} from './CountDownHook'


function ShowCounter({targetDate}) {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds) || days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
        <OkCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
        />
        );
    }
}

function OkCounter({days, hours, minutes, seconds}){
    return (
        <div>
            <span className='tw-flex tw-my-10'>
                <span className='tw-flex tw-flex-col tw-items-center'>
                    <span className='tw-text-3xl lg:tw-text-[2.5rem] tw-font-semibold'>{days}</span>
                    <span className='tw-text-xs'>Days</span>
                </span>
                <span className='tw-flex tw-flex-col tw-items-center tw-ml-10 lg:tw-ml-10'>
                    <span className='tw-text-3xl lg:tw-text-[2.5rem] tw-font-semibold'>{hours}</span>
                    <span className='tw-text-xs'>Hours</span>
                </span>
                <span className='tw-flex tw-flex-col tw-items-center tw-ml-10 lg:tw-ml-10'>
                    <span className='tw-text-3xl lg:tw-text-[2.5rem] tw-font-semibold'>{minutes}</span>
                    <span className='tw-text-xs'>Minutes</span>
                </span>
                <span className='tw-flex tw-flex-col tw-items-center tw-ml-10 lg:tw-ml-10'>
                    <span className='tw-text-3xl lg:tw-text-[2.5rem] tw-font-semibold'>{seconds}</span>
                    <span className='tw-text-xs'>Seconds</span>
                </span>
            </span>


        </div>
    )
}


function ExpiredNotice() {
    return (
      <div></div>
    )
  }
  

export default ShowCounter