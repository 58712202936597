import React from 'react'
import {Helmet} from "react-helmet";

function RefundPolicy() {
  return (
    <div className='container tw-mb-16 tw-mt-24 tw-min-h-[85vh]'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>refund policy</title>
            <meta name="description" content="refund policy" />
        </Helmet>
        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Refund Policy</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
                When a payment of fee is made to Finocontrol Consultancy Services LLP, the fees paid in advance is retained by Finocontrol in company’s official current account. Finocontrol will earn the fees upon working on a client's matter. During an engagement, Finocontrol earns fee at different rates and different times depending on the completion of various milestones (e.g. Professional Training Programmes, Professional services such as Financial Modeling, Business Valuations & Stock market advisory). Refund cannot be provided for earned fee because resources and man hours spent on delivering the service are non-returnable in nature. Further, we can't refund or credit any money paid to government entities, such as filing fees or taxes, or to other third parties with a role in processing your order. Under any circumstance, Finocontrol shall not be liable to refund the fee paid by the client.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Change of Service</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
                If you want to change the service you ordered for a different one, you must request this change of service within 7 days of purchase. The purchase price of the original service, less any earned fee and money paid to government entities, such as filing fees or taxes, or to other third parties with a role in processing your order, will be credited to your Finocontrol account. You can use the balance credit for any other Finocontrol service.
            </p>
        </div>

        <div className='tw-text-lg md:tw-text-xl lg:tw-text-2xl tw-font-medium '>Cancellation Fee</div>
        <div className='tw-my-4'>
            <p className='first-letter:tw-capitalize tw-text-sm lg:tw-text-base tw-opacity-70 tw-my-2' style={{textTransform:"lowercase"}}>
                Since we're incurring costs and dedicating time, manpower, technology resources and effort to your service or document preparation, our guarantee only covers satisfaction issues caused by Finocontrol - not changes to your situation or your state of mind. In case you require us to hold the processing of a service, we will hold the fee paid on your account until you are ready to commence the service. On cancellation, No refunds will be processed on any grounds after making the payment except on cases where company fails to undertake the programs
            </p>
        </div>

    </div>
  )
}

export default RefundPolicy