import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import serverAPI from '../../utils/baseAPI';


function VerifyEmail() {
  const location = useLocation();
  const navigate= useNavigate();

  const obj = queryString.parse(location.search);
  const qemail = obj.email, qtoken = obj.token;
  const [email, setEmail] = useState(qemail);
  const [newPass, setNewPass] = useState("");
  const [OTP,] = useState(qtoken);

  useEffect(()=>{
    if(!qemail || !qtoken) 
      navigate('/');
  });

  function changePassword(e) {
    e.preventDefault();
    serverAPI.post(`/api/forgot-password/verify-verification-code/change-password/${email}/${OTP}`, {
      newPassword: newPass
    }).then(e => {
      navigate('/login')
    }).catch(e => {
      //console.log('something went wrong in server side');
    })
  }

  return (
    <div className=' tw-mt-16 '>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verify your email</title>
        <meta name="description" content="Verify your email id" />
      </Helmet>
      <div className="row tw-min-h-[93vh]">
        {/* <div className="tw-h-auto col-md-5 bg_color tw-hidden md:tw-flex tw-justify-center tw-items-center">
          <img src={leftImage} alt="left-img" className='img-fluid' />
        </div> */}
        <div className="col tw-flex tw-justify-center tw-items-center">
          <div className="tw-my-5 lg:tw-w-[450px] tw-rounded-2xl tw-bg-white">
            <div className="tw-py-10 tw-px-10 drop_shadow tw-rounded-lg ">
              <span className='tw-text-3xl tw-font-bold'>
                <span>Update Password</span>
              </span>
              <div className='tw-label-text tw-mt-5'>
                <span><span className='tw-opacity-60'>Already have an account.</span> <Link to='/login'><span className='tw-text-[#6440FB] tw-cursor-pointer hover:tw-underline'> Sign in here</span></Link> </span>
              </div>
              <form onSubmit={(e) => changePassword(e)} className='tw-flex tw-justify-center tw-flex-col' >
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="email" className='tw-label-text'>Email Address <span className='tw-text-red-500'>*</span></label>
                  </span>
                  <input disabled value={email} onChange={(e) => setEmail(e.target.value)} required type="email" placeholder="Email" className="tw-input tw-input-bordered tw-w-full " />
                </div>

                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="reset-password" className='tw-label-text'>New Password <span className='tw-text-red-500'>*</span></label>
                  </span>
                  <input value={newPass} onChange={(e) => setNewPass(e.target.value)} required type="password" placeholder="********" className="tw-input tw-input-bordered tw-w-full " />
                </div>
                <div className='tw-pt-1'>
                  <span className="tw-label">
                    <label htmlFor="OTP" className='tw-label-text'>OTP <span className='tw-text-red-500'>*</span></label>
                  </span>
                  <input disabled value={OTP} required type="number" min={100_000} max={999_999} placeholder="123456" className="tw-input tw-input-bordered tw-w-full " />
                </div>

                <div className="tw-pt-4">
                  <button type="submit" className='tw-btn tw-btn-primary tw-w-full tw-capitalize'>
                    <span> Confirm Change Password</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail