import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Countdown from 'react-countdown';
import {UserContext} from '../../userContext/UserState'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <></>;
  } else {
    return <span className="tw-grid tw-grid-flow-col tw-gap-2 tw-text-center tw-auto-cols-max tw-items-center">
      Ends In-
      <div className="tw-flex tw-flex-col">
        <span className="tw-countdown tw-font-mono " style={{ backgroundColor:'#2125295C', padding:'3px', borderRadius:4}}>
          <span style={{ "--value": "00"}}></span>
        </span>
        <span className='tw-font-light tw-text-[10px]'>days</span>
      </div>
      <div className="tw-flex tw-flex-col">
        <span className="tw-countdown tw-font-mono " style={{ backgroundColor:'#2125295C', padding:'3px', borderRadius:4}}>
          <span style={{ "--value": hours }}></span>
        </span>
        <span className='tw-font-light tw-text-[10px]'>hr</span>
      </div>
      <div className="tw-flex tw-flex-col">
        <span className="tw-countdown tw-font-mono " style={{ backgroundColor:'#2125295C', padding:'3px', borderRadius:4}}>
          <span style={{ "--value": minutes }}></span>
        </span>
        <span className='tw-font-light tw-text-[10px]'>min</span>
      </div>
      <div className="tw-flex tw-flex-col">
        <span className="tw-countdown tw-font-mono " style={{ backgroundColor:'#2125295C', padding:'3px', borderRadius:4}}>
          <span style={{ "--value": seconds }}></span>
        </span>
        <span className='tw-font-light tw-text-[10px]'>sec</span>
      </div>
    </span>
    // Render a countdown
    // return <span>{hours}:{minutes}:{seconds}</span>;
  }
};

function OfferHeader() {
  const {userState, setUserState} = useContext(UserContext)
  const [offerDate, setofferDate] = useState(()=>{
    return userState.earlyOfferEndDate;
  });
  
  const handleOfferHeader= useCallback(()=> {
    setUserState({...userState, offerHeader: false});
  }, [setUserState, userState])
  return (
    userState.offerHeader && <div className='tw-bg-[#5B1B73]'>
      <div className='container tw-py-2'>
      <span className='tw-absolute tw-text-4xl tw-text-white tw-font-thin tw-cursor-pointer' onClick={handleOfferHeader}>&times;</span>
        <div className='tw-flex tw-items-center tw-justify-center tw-text-white tw-font-semibold tw-text-[10px] md:tw-text-xs'>
          <div className='tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-mr-2'>
            <span className='tw-text-[#0AFFB5] md:tw-mr-1 tw-uppercase'>Early Bird Offer -</span>
            <span className='tw-text-center md:tw-mr-1 tw-mx-3 tw-capitalize'>{userState.courseFullName}</span>
            <span className='md:tw-mr-3'>
              <Countdown
                date={offerDate}
                renderer={renderer}
              />
            </span>
            <span className='tw-flex tw-items-center'>
              <Link to={userState.enrollNowLink} target='__blank' className='tw-btn tw-btn-primary tw-btn-sm tw-text-white tw-btn-xs'>ENROLL NOW</Link>
            </span>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

export default OfferHeader