import * as yup from 'yup'
import validator from 'validator';

yup.addMethod(yup.Schema, 'isStrongPassword', function isStrongPassword(){
    return this.test('test-name', "Not a strong password", function(value){
        return validator.isStrongPassword(value) || this.createError();
    })
});

yup.addMethod(yup.Schema, 'isMobileNumber', function isMobileNumber(){
    return this.test('test-name', "Mobile number must be of 10 digits", function(value){
        return value.length===10 || this.createError();
    })
});

yup.addMethod(yup.Schema, 'isValidatedEmail', function isValidatedEmail(){
    return this.test('test-name', "Not a valid email address", function(value){
        return validator.isEmail(value) || this.createError();
    })
})

const contactSchema=yup.object({
    name: yup.string().min(3).max(20).required(),
    email: yup.string().isValidatedEmail().email().required(),
    message: yup.string().min(50).max(400).required(),
    phoneNumber: yup.string().isMobileNumber().required("Contact number must be of 10 digits"),
});

const registerUser= yup.object({
    name: yup.string().min(3).max(20).required(),
    email: yup.string().isValidatedEmail().email().required(),
    whatsAppNumber: yup.string().isMobileNumber().required("WhatsApp number must be of 10 digits"),
    contactNumber: yup.string().isMobileNumber().required("Contact number must be of 10 digits"),

    studentOrWorking: yup.string().required(),
    academicCourse: yup.string().required("Please fill this field"), //select
    graduationYear: yup.number().min(1900).max(2099).required("Graduation Year is required"),
    careerInCourse: yup.string().required(), //true or false
    previousExperience: yup.string().notRequired(),
    utm_source: yup.string()
})

const requestACallbackSchema= yup.object({
    name: yup.string().min(3).max(20).required(),
    email: yup.string().isValidatedEmail().email().required(),
    whatsAppNumber: yup.string().isMobileNumber().required("WhatsApp number must be of 10 digits..."),
    contactNumber: yup.string().isMobileNumber().required("Contact number must be of 10 digits..."),
    stateResideIn: yup.string().required("Please fill your state..."),
    areYouInterested: yup.string().required('Please fill this field'),
    describeYou: yup.string().required('plese fill this'),

    studentOrWorking: yup.string().required(),
    academicCourse: yup.string().required("Please fill your academic..."), //select
    graduationYear: yup.number().min(1900).max(2099).required("Graduation Year is required..."),
    careerInCourse: yup.string().required(), //true or false
    previousExperience: yup.string().notRequired(),
    utm_source: yup.string()
})

const signUpSchema=yup.object({
    name: yup.string().min(3).max(20).required(),
    email: yup.string().isValidatedEmail().email().required(),
    password: yup.string().isStrongPassword().min(8).max(20).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password')], "Password not matching"),

    countryResiding: yup.string().required("Country residing is required"),
    stateResiding: yup.string().required("State residing is required"),
    contactNumber: yup.string().isMobileNumber().required("Contact number must be of 10 digits"),
    profession: yup.string().oneOf(["working", "student", "other"]).required(),

    company: yup.string().when('profession', (professionSelected, schema)=>{
        if(professionSelected==="working") {
            return schema.required();
        } else if(professionSelected==="other") {
            return schema.required();
        }
        return schema;
    }),
    college: yup.string().when('profession', (professionSelected, schema)=>{
        if(professionSelected==="student") {
            return schema.required();
        } else if(professionSelected==="other") {
            return schema.required();
        }
        return schema;
    }),
    dob: yup.date().notRequired(),
    course: yup.string().required(),
    
    graduationYear: yup.number().min(1900).max(2099).required("Graduation Year is required")
});

const loginSchema=yup.object({
    email: yup.string().isValidatedEmail().email().required(),
    password: yup.string().min(8).max(20).required(),
});
const forgotPasswordSchema=yup.object({
    email: yup.string().isValidatedEmail().email().required()
});

export { 
    contactSchema,
    signUpSchema,
    loginSchema,
    forgotPasswordSchema,
    registerUser,
    requestACallbackSchema
};