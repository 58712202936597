import {createStore, applyMiddleware } from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer';

const persistConfig= {
    key:"peristed-store",
    storage,
    blacklist: ['course', 'blog', 'event', 'selfPacedCourse'],
    whitelist: ['login']
};

const persistedReducer= persistReducer(persistConfig, rootReducer);

const store= createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor=  persistStore(store);
export {persistor}
export default store;