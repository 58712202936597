import {ACTIVE_COURSE, NOT_ACTIVE_COURSE, ALL_COURSE, CLEAR_ALL} from './courseTypes';
import { combineReducers } from 'redux';

const activeCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case ACTIVE_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}

const notActiveCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case NOT_ACTIVE_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}
const allCourseReducer= (state= [], action)=>{
    switch (action.type) {
        case ALL_COURSE: return {
            data: action.payload
        }
        case CLEAR_ALL: return [];
        default: return state;
    }
}

const courseReducer= combineReducers({
    active: activeCourseReducer, 
    notActive: notActiveCourseReducer, 
    all: allCourseReducer
});

export default courseReducer;