import serverAPI from '../../utils/baseAPI';
import {ACTIVE_COURSE, NOT_ACTIVE_COURSE, ALL_COURSE, CLEAR_ALL} from './courseTypes';

export const getActiveCourse= ()=>{
    //console.log('running get active coures')
    return (dispatch) =>{
        serverAPI.get(`/api/course/status/active`)
        .then(e=>{
            dispatch(setActiveCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setActiveCourse=(data)=>{
    return {
        type: ACTIVE_COURSE,
        payload: data
    }
}
export const getNotActiveCourse= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/course/status/not-active`)
        .then(e=>{
            dispatch(setNotActiveCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setNotActiveCourse=(data)=>{
    return {
        type: NOT_ACTIVE_COURSE,
        payload: data
    }
}

export const getAllCourse= ()=>{
    return (dispatch) =>{
        serverAPI.get(`/api/course`)
        .then(e=>{
            dispatch(setAllCourse(e.data.data));
        })
        .catch(e=>{
            //console.log(e);
        })
    }
}

const setAllCourse=(data)=>{
    return {
        type: ALL_COURSE,
        payload: data
    }
}

export const courseLogOutClear=()=>{
    return {
        type: CLEAR_ALL
    }
}