export const leftToRightVariant = {
    hidden: {
        x: -50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.2,
            type: "tween"

        }
    }
}
export const leftToRightVariantSlow = {
    hidden: {
        x: -50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 2,
            when: "beforeChildren",
            staggerChildren: 0.2,
            type: "tween"

        }
    }
}


export const rightToLeftVariant = {
    hidden: {
        x: 50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.2,
            type: "tween"

        }
    }
}

export const rightToLeftVariantSlow = {
    hidden: {
        x: 50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 2,
            when: "beforeChildren",
            staggerChildren: 0.2,
            delayChildren: 0.5,
            type: "tween"
        }
    }
}


export const downToUpVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 2,
            when: "beforeChildren",
            staggerChildren: 0.1,
            type: "tween"

        }
    }
}
